import '../../../../../../scss/bootstrap.min.scss'
import '../../../../../../scss/app.min.scss'
import './home.scss';
import { renderToString } from "react-dom/server";
import { render } from "react-dom";
import tableAppr from 'app/modules/home/table'
import { Listbox, Transition } from '@headlessui/react'
// import { CheckIcon, SelectorIcon } from '@heroicons/react/solid'
import { PDFViewer } from '@react-pdf/renderer';

const list = [
  { value: 2 },
  { value: 5 },
  { value: 10 },
  { value: 15 },
  { value: 20 },
  { value: 25 },
]
import PinField from "react-pin-field"

import React, { useEffect, useState, Fragment, useRef } from 'react';
import { Link, RouteComponentProps } from 'react-router-dom';

import { connect } from 'react-redux';


import { IRootState } from 'app/shared/reducers';
import ArrowRightIcon from 'app/component/arrow-right-icon';
import SignatureIcon from 'app/component/signature-icon';

import LogoutIcon from 'app/component/logout-icon';
import { toast } from 'react-toastify';
import { logout } from 'app/shared/reducers/authentication';
import { useHistory } from "react-router-dom";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Axios from 'axios';
import { translate } from 'react-jhipster';



export interface IDocumentProps extends StateProps, DispatchProps, RouteComponentProps<{ url: string }> {}

export const PinChange = (props: IDocumentProps) => {
  // const { account } = props;
  let history = useHistory();
  const [category, setCategory] = useState<any>(0)
  const [page, setPage] = useState<any>(1)
  const [limit, setLimit] = useState<any>(5)
  const [selected, setSelected] = useState(list[0])
  const ref = useRef<HTMLInputElement[]>([]);
  const [oldPin, setOldPin] = useState<any>("")
  const [pin, setPin] = useState<any>("")
  const [confirmpin, setConfirmPin] = useState<any>("")


  
  useEffect(() => {
    // props.getSignature()
  }, []);
  
 
 

  const delayTime = (ms) => {
    return new Promise(
      resolve => setTimeout(resolve, ms)
    )
  }

const resetForm = () => {
    setPin("")
    setConfirmPin("")
}

  

 const savePin = () => {
     if(pin != confirmpin) {
        toast.error('Pin dan konfirmasi Pin berbeda', {
            position: "top-left",
            autoClose: 8000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
         });
     } else {
        const formData = new FormData();
        formData.append("currentPinApproval", oldPin)
        formData.append("newPinApproval", pin)
         Axios.put('/services/uaadocservice/api/personal-identity/change-pin-approval', formData).then(res => {
             console.log(res)
             toast.success('Berhasil Set PIN', {
                position: "top-left",
                autoClose: 8000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
             });
             history.push('/profile/me')
         }).catch(err => {
             console.log(err)
             toast.error(translate(`${err.response.data.message}`), {
                position: "top-left",
                autoClose: 8000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
             });
         })
     }
 }

  return (


    <div className="container text-center" style={{height: '80vh'}}>
        <div className='container d-flex  h-100' style={{alignItems: 'center', justifyContent: 'center'}}>
            <div>
                <div className='d-flex'>
                    <button className="btn px-0 mr-4" onClick={() => history.replace('/profile/me')}>
                         <FontAwesomeIcon size="2x" icon="arrow-left" color="#495057" />{' '}
                    </button>
                    <h1 className='mb-0'>
                        Change PIN
                    </h1>
                </div>

                <div className='my-5 text-left'>
                    <div>
                        <h5 className='px-3'>
                             Old Pin
                        </h5>
                    </div>
                    <div className='my-4'>
                        <PinField  
                        ref={ref} 
                        className="mx-3 bg-transparent pin-field"
                        type="password"
                        style={{}}
                        validate={/^[0-9]$/}
                        onChange={(e) => setOldPin(e)}
                        length={6}
                        />
                    </div>
                </div>

                <div className='my-5 text-left'>
                    <div>
                        <h5 className='px-3'>
                             New PIN
                        </h5>
                    </div>
                    <div className='my-4'>
                        <PinField  
                        ref={ref} 
                        className="mx-3 bg-transparent pin-field"
                        type="password"
                        style={{}}
                        validate={/^[0-9]$/}
                        onChange={(e) => setPin(e)}
                        length={6}
                        />
                    </div>
                </div>

                <div className='my-5 text-left'>
                    <div>
                        <h5 className='px-3'>
                            Confirm New Pin
                        </h5>
                    </div>
                     <div className='my-4'>
                            <PinField  
                            ref={ref} 
                            className="mx-3 bg-transparent pin-field"
                            type="password"
                            style={{}}
                            validate={/^[0-9]$/}
                            onChange={(e) => setConfirmPin(e)}
                            length={6}
                            />
                    </div>
                </div>

                <div>
                    <button className='btn btn-primary w-100 py-3 mt-4' disabled={!pin || pin.length < 6 || !confirmpin || confirmpin.length < 6} onClick={() => savePin()}>
                         Save New PIN
                    </button>
                </div>
            </div>
        </div>
    </div >
    
  );
};

const mapStateToProps = (storeState: IRootState) => ({
    account: storeState.authentication.account
});


const mapDispatchToProps = {
  // getSignature,
  logout
};
type StateProps = ReturnType<typeof mapStateToProps>
type DispatchProps = typeof mapDispatchToProps;;

export default connect(mapStateToProps, mapDispatchToProps)(PinChange);
