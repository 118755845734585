import '../../../../../../scss/bootstrap.min.scss'
import '../../../../../../scss/app.min.scss'
import './notification.scss';
import { renderToString } from "react-dom/server";
import { render } from "react-dom";
import tableAppr from 'app/modules/home/table'
import { Listbox, Transition } from '@headlessui/react'
// import { CheckIcon, SelectorIcon } from '@heroicons/react/solid'
import { PDFViewer } from '@react-pdf/renderer';
import Pagination from '@mui/material/Pagination';
const list = [
  { value: 2 },
  { value: 5 },
  { value: 10 },
  { value: 15 },
  { value: 20 },
  { value: 25 },
]
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import {Select as SelectMui } from '@mui/material';

import React, { useEffect, useState, Fragment } from 'react';
import { Link, RouteComponentProps } from 'react-router-dom';
import { Translate } from 'react-jhipster';
import { connect } from 'react-redux';
import { CardGroup, Row, Col, Alert, Tooltip, Modal, ModalHeader, ModalBody, FormGroup, Input, ModalFooter, Button, Spinner } from 'reactstrap';

import { IRootState } from 'app/shared/reducers';


import SideBar from 'app/shared/layout/sidebar/sidebar';
import Approval from 'app/shared/layout/card/approval';
import HandshakeIcon from 'app/component/handshake-icon';
import EyeIcon from 'app/component/eye-icon';
import EnvelopeIcon from 'app/component/envelope-icon';
import PaperPlaneIcon from 'app/component/paperplane-icon';
import InfoIcon from 'app/component/InfoIcon';

import { getSearchEntities, getEntities, getEntitiesByEmpId, getDocumentApproval, getDocumentAcknowledge, getDocumentSuratKeluar, getDocumentSuratMasuk } from '../document/document.reducer';
import Axios from 'axios';
import Page from 'app/component/pagination/Page';
import { useHistory } from "react-router-dom";
import ArrowRightIcon from 'app/component/arrow-right-icon';
import moment from 'moment-timezone';
import axios from 'axios'
import { initial, size } from 'lodash';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import BottomSort from 'app/component/bottom-sort';
import Illustration1 from 'app/component/illustration-1';
import EditBlue from 'app/component/edit-blue';
import DeleteRed from 'app/component/delete-red';

import UploadDocumentListIcon from 'app/component/upload-document-karyawan';
import UserAddKaryawanIcon from 'app/component/user-add-karyawan';

import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import Select from 'react-select';
import DatePicker from "react-datepicker";
import { toast } from 'react-toastify';

export interface IHeaderProps {
  isAuthenticated?: boolean;
  isAdmin?: boolean;
  ribbonEnv?: string;
  isInProduction?: boolean;
  isSwaggerEnabled?: boolean;
  currentLocale?: string;
  onLocaleChange?: Function;
  account?: any,
  logout?: any,
  location?: any
}


export const Karyawan = (props: IHeaderProps) => {
  let history = useHistory();
  const [search, setSearch] = useState('')
  const [loading, setLoading] = useState(false)
  const [keyword, setKeyword] = useState('')
  const [listSearch, setListSearch] = useState([])
  const [loadingList, setLoadingList] = useState(false)
  const [showList, setShowList] = useState(false)
  const [page, setPage] = useState(1)
  const [totalPage, setTotalPage] = useState(1)
  const [size, setSize] = useState<any>(10)
  const [sortType, setSortType] = useState('typeName.keyword,asc')
  const [sortPriority, setSortPriority] = useState('priority.keyword,asc')
  const [sortLastModified, setSortLastModified] = useState('lastApprovedByName.keyword,asc')
  const [sortDate, setSortDate] = useState('date,asc')
  const [listNotification, setListNotification] = useState<any>([])
  const [listKaryawan, setListKaryawan] = useState<any>([])
  const [showModal, setShowModal] = useState<any>(false)
  const [fullname, setFullName] = useState<any>("")
  const [iddelete, setIdDelete] = useState<any>(null)
  const [loadingDelete, setLoadingDelete] = useState<any>(false)
  const [listDepartment, setListDepartment] = useState<any>([])
  const [modalEdit, setModalEdit] = useState<any>(false)
  const [initialValue, setInitialValue]= useState<any>(null)
  const [deptName, setDeptName] = useState<any>('')
  const [DOB, setDOB] = useState<any>('')
  const [sort, setSort] = useState<any>("employeeNo")
  const [ascending, setAscending] = useState<any>(true)
  

  const customStyles = {
    control: base => ({
      ...base,
      height: 54,
      minHeight: 54
    })
  };

  const getDepartmentList = () => {
    let groupPayload = {
      page: 0,
      limit: 1000,
      keyword: ""
  }
    axios.post('services/uaadocservice/api/group/list', groupPayload).then(res => {
      console.log('dept', res.data)
      setListDepartment(res.data.data.groups)
    }).catch(err => {
      console.log(err)
    })
  }

  const LoginSchema = Yup.object().shape({
    nik: Yup.string()
    .required("NIK is required"),
    position: Yup.string()
    .required("Position is required"),
    // group: Yup.string()
    // .required("Group is required"),
    phonenumber: Yup.string()
    .required("Phone number is required"),
    date: Yup.string()
    .required("Date is required"),
    fullname: Yup.string()
      .min(5, "Fullname must be 5 characters at minimum")
      .required("Fullname is required"),
      
  });

  const getDetailEmployee = (id: any) => {
    axios.get(`/services/uaadocservice/api/personal-identity/karyawan/${id}`).then(res => {
        console.log(res.data.data.employeeNo)
        setInitialValue(res.data.data)
        setDOB(res.data.data.joinDate ? moment(res.data.data.joinDate).toDate() : "")
         listDepartment.map((item, index) => {
           if(item.id === res.data.data.departmentId) {
            setDeptName(item.deptName)
           }
         })

        setModalEdit(true)
    }).catch(err => {
        console.log(err.response)
        setModalEdit(false)
    })
  }
  

  useEffect(() => {
    // alert('data search', props.location.state.search)
    // alert(props.location.state.search)
    
    getListDataKaryawan()
   
  }, [page, size, totalPage]);

  const handleClose = () => {
      setShowModal(false)
  }

  
    // alert('data search', props.location.state.search)
    // alert(props.location.state.search)
    
  


  const handleChange = (event) => {
    setPage(1)
    setSize(parseInt(event.target.value));
  };


  useEffect(() => {
    console.log('data search', props.location.state?.search)
    // alert(props.location.state.search)
    getListDataKaryawan()
    getDepartmentList()
    
  }, []);

  // useEffect(() => {
   
  //   getListDataKaryawan()
  
    
  // }, [sort, ascending]);

  const setDefault = () => {
    setSortType('typeName.keyword,asc')
    setSortPriority('priority.keyword,asc')
    setSortLastModified('lastApprovedByName.keyword,asc')
    setSortDate('date,asc')
  }

  const getListDataKaryawan = () => {
    let pageReal = page
    setLoadingList(true)
    let payload = {
      sort: sort,
      is_ascending: ascending,
      page: pageReal - 1,
      limit: size,
      keyword: keyword
    }
    axios.post(`/services/uaadocservice/api/personal-identity/karyawan/list`, payload).then((res) => {
        console.log('notif', res)
        setListKaryawan(res.data.data.data)
        setTotalPage(res.data.data.totalPage)
    }).catch((err) => {
      setLoadingList(false)

    })
  }

  const getListNotification = () => {
    setLoadingList(true)
    axios.get(`services/documentservice/api/notification/get-list?page=${page}&size=${size}&sort=createdDate,desc`).then((res) => {
        console.log('notif', res.data.data.content)
        setListNotification(res.data.data.content)
          setTotalPage(res.data.data.totalPages)
          setLoadingList(false)
    }).catch((err) => {
      setLoadingList(false)

    })
  }


  const updateKaryawan = (data) => {
    let payload = {
      employeeNo: data.nik,
      fullName: data.fullname,
      phoneNumber: data.phonenumber,
      email: data.email,
      joinDate: moment(data.date).format('YYYY-MM-DD'),
      // departmentId: data.group.value ? parseInt(data.group.value) : initialValue.departmentId
      position: data.position
    }
    axios.put(`/services/uaadocservice/api/personal-identity/karyawan/${initialValue.employeeNo}/update`, payload).then((res) => {
        console.log('update', res)
        toast.success('Success Edit Employee', {
          position: "top-left",
          autoClose: 8000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          });
          setInitialValue(null)
          setModalEdit(false)
          getListDataKaryawan()
    }).catch((err) => {
      toast.error('Failed Edit Employee', {
        position: "top-left",
        autoClose: 8000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        });

    })
  }




  const delayTime = (ms) => {
    return new Promise(
      resolve => setTimeout(resolve, ms)
    )
  }

  
  const getSearch = async (e = '') => {
    if(e.length > 1 ) {
      setLoadingList(true)
      axios.get(`services/uaadocservice/api/custom-search/documents?keyword=${e}&page=${page}&size=${size}&sort=${sortType}&sort=${sortPriority}&sort=${sortLastModified}&sort=${sortDate}`).then( async (result) => {
        console.log(result.data)
        setListSearch(result.data.content)
        // setTotalPage(result.data?.totalPages)
        await delayTime(2000)
        setLoadingList(false)
      }).catch(err => {
        setListSearch([])
        setLoadingList(false)
      })
    }
  }


  


  const goToPage = () => {
    setSearch('')
    history.push('/karyawan/upload')
  }

  const goToPageManual = () => {
    setSearch('')
    history.push('/karyawan/add')
  }


  const handleChangePage = (event: React.ChangeEvent<unknown>, value: number) => {
    setPage(value);
  };


  const deleteKaryawan = () => {
    setLoadingDelete(true)
    axios.delete(`services/uaadocservice/api/personal-identity/karyawan/${iddelete}/delete`).then(res => {
      console.log(res)
      setShowModal(false)
      setIdDelete(null)
      setLoadingDelete(false)
      getListDataKaryawan()
      toast.success('Success Delete Employee', {
        position: "top-left",
        autoClose: 8000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        });
        setInitialValue(null)
        setModalEdit(false)
        getListDataKaryawan()
    }).catch(err => {
      console.log(err.response)
      setLoadingDelete(false)
      toast.error('Failed Delete Employee', {
        position: "top-left",
        autoClose: 8000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        });
        setInitialValue(null)
        setModalEdit(false)
        getListDataKaryawan()
    })
  }
 


 
  return (
    <div className="px-5 pt-4" style={{height: '100%'}}>

    <div className="row">
      <div className="col-12">
        <div className="page-title-box d-flex align-items-center justify-content-between">
          <h1 className="mb-0 font-size-28">Data Karyawan</h1>
        </div>
        <div className="page-title-box d-flex align-items-center justify-content-between">
          {/* <h1 className="mb-0 font-size-14">Lorem ipsum dolor sit amet, consectetur adipiscing elit</h1> */}
        </div>
      </div>
    </div>


    <div className='row my-3'>
    <div className="col-12 col-md-2 col-lg-2 px-2">
            <div className='btn btn-primary w-100 py-2' style={{background: '#002F48', borderColor: '#002F48'}} onClick={(data) => goToPageManual()}>
              <UserAddKaryawanIcon /> Tambah Data Karyawan
            </div>
        </div>
        <div className="col-12 col-md-2 col-lg-2 px-2">
            <div className='btn btn-primary w-100 py-2' onClick={(data) => goToPage()}>
             <UploadDocumentListIcon/> Upload Data Karyawan
            </div>
        </div>
        <div className="col-12 col-md-8 col-lg-8 px-0">
        <div className="d-flex px-3" style={{ flexGrow: 10 }}>
            <div className="app-search w-100 py-0 bg-white">
              <div className="position-relative">
                <input type="text" className="form-control bg-white py-3" placeholder="Cari Karyawan" onChange={(e) => {
                  if(e.target.value === '') {
                    setPage(1)
                    setKeyword(e.target.value)
                    getListDataKaryawan()
                  } else {
                    setKeyword(e.target.value)
                  }
                }} onKeyPress={(e) => {
                  console.log(e.key)
                  if(e.key == 'Enter') {
                    setPage(1)
                    getListDataKaryawan()
                  }
                }}/>
                <span className="bx bx-search-alt my-0"></span>
              </div>
            </div>
          </div>
        </div>
    </div>

    <div className="table-responsive mt-4">
                  <table className="table table-striped table-centered  mb-0">
                    <thead className="thead-light">
                      <tr>    
                        <th className='py-3' style={{cursor: 'pointer'}} onClick={() => {
                            //  setSort('employeeNo')
                            //  setAscending(!ascending)
                        }}> 
                          <div style={{display: 'flex', alignItems: 'center'}}>
                            <span className='mr-1'>Employee Number</span>
                            {/* <span className='mt-1'>
                                {(sort == "employeeNo" && ascending == true) &&  <i className="bx bx-sort-up" style={{fontSize: '16px'}}></i>  }
                                {(sort == "employeeNo" && ascending == false) &&  <i className="bx bx-sort-down" style={{fontSize: '16px'}}></i>  }
                                {(sort != "employeeNo") &&  <i className="bx bx-list-ol" style={{fontSize: '16px'}}></i>  }
                                </span> */}
                          </div>
                        </th>

                        <th className='py-3' style={{cursor: 'pointer'}} onClick={() => {
                            //  setSort('fullName')
                            //  setAscending(!ascending)
                        }}> 
                          <div style={{display: 'flex', alignItems: 'center'}}>
                            <span className='mr-1'>Full Name</span>
                            {/* <span className='mt-1'>
                                {(sort == "fullName" && ascending == true) &&  <i className="bx bx-sort-up" style={{fontSize: '16px'}}></i>  }
                                {(sort == "fullName" && ascending == false) &&  <i className="bx bx-sort-down" style={{fontSize: '16px'}}></i>  }
                                {(sort != "fullName") &&  <i className="bx bx-list-ol" style={{fontSize: '16px'}}></i>  }
                                </span> */}
                          </div>
                        </th>
                    
                        <th className='py-3' style={{cursor: 'pointer'}} onClick={() => {
                            //  setSort('phoneNumber')
                            //  setAscending(!ascending)
                        }}> 
                          <div style={{display: 'flex', alignItems: 'center'}}>
                            <span className='mr-1'>Phone Number</span>
                            {/* <span className='mt-1'>
                                {(sort == "phoneNumber" && ascending == true) &&  <i className="bx bx-sort-up" style={{fontSize: '16px'}}></i>  }
                                {(sort == "phoneNumber" && ascending == false) &&  <i className="bx bx-sort-down" style={{fontSize: '16px'}}></i>  }
                                {(sort != "phoneNumber") &&  <i className="bx bx-list-ol" style={{fontSize: '16px'}}></i>  }
                                </span> */}
                          </div>
                        </th>
                        <th className='py-3' style={{cursor: 'pointer'}} onClick={() => {
                            //  setSort('email')
                            //  setAscending(!ascending)
                        }}> 
                          <div style={{display: 'flex', alignItems: 'center'}}>
                            <span className='mr-1'>Email</span>
                            {/* <span className='mt-1'>
                                {(sort == "email" && ascending == true) &&  <i className="bx bx-sort-up" style={{fontSize: '16px'}}></i>  }
                                {(sort == "email" && ascending == false) &&  <i className="bx bx-sort-down" style={{fontSize: '16px'}}></i>  }
                                {(sort != "email") &&  <i className="bx bx-list-ol" style={{fontSize: '16px'}}></i>  }
                                </span> */}
                          </div>
                        </th>
                        <th className='py-3' style={{cursor: 'pointer'}} onClick={() => {
                            //  setSort('position')
                            //  setAscending(!ascending)
                        }}> 
                          <div style={{display: 'flex', alignItems: 'center'}}>
                            <span className='mr-1'>Position</span>
                            {/* <span className='mt-1'>
                                {(sort == "position" && ascending == true) &&  <i className="bx bx-sort-up" style={{fontSize: '16px'}}></i>  }
                                {(sort == "position" && ascending == false) &&  <i className="bx bx-sort-down" style={{fontSize: '16px'}}></i>  }
                                {(sort != "position") &&  <i className="bx bx-list-ol" style={{fontSize: '16px'}}></i>  }
                                </span> */}
                          </div>
                        </th>
                        <th className='py-3' style={{cursor: 'pointer'}} onClick={() => {
                            //  setSort('joinDate')
                            //  setAscending(!ascending)
                        }}> 
                          <div style={{display: 'flex', alignItems: 'center'}}>
                            <span className='mr-1'>Join Date</span>
                            {/* <span className='mt-1'>
                                {(sort == "joinDate" && ascending == true) &&  <i className="bx bx-sort-up" style={{fontSize: '16px'}}></i>  }
                                {(sort == "joinDate" && ascending == false) &&  <i className="bx bx-sort-down" style={{fontSize: '16px'}}></i>  }
                                {(sort != "joinDate") &&  <i className="bx bx-list-ol" style={{fontSize: '16px'}}></i>  }
                                </span> */}
                          </div>
                        </th>
                        <th className='text-center'>Action</th>
                      </tr>
                    </thead>
                    <tbody>
                    

                      {
                        listKaryawan ? listKaryawan.map((item, index) => {
                          return (
                            <tr>
                            <td className='py-2' style={{wordBreak: 'break-all', minWidth: '200px'}}>{item.employeeNo ?? "-"}</td>
                            <td style={{wordBreak: 'break-all', minWidth: '300px'}}>{item.fullName ?? "-"}</td>
                            <td style={{wordBreak: 'break-all', minWidth: '200px', maxWidth: '200px'}}>{item.phoneNumber ?? "-"}</td>
                            <td style={{wordBreak: 'break-all', minWidth: '300px'}}>{item.email ?? "-"}</td>
                            <td style={{wordBreak: 'break-all', minWidth: '200px'}}>{item.position}</td>
                            <td style={{wordBreak: 'break-all', minWidth: '200px'}}>{moment(item.joinDate).format("YYYY-MM-DD")}</td>
                            <td style={{wordBreak: 'break-all'}}>
                              <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'space-evenly'}}>
                                <div style={{cursor: 'pointer'}} className="mx-2" onClick={() =>  getDetailEmployee(item.employeeNo)}>
                                    <EditBlue/>
                                </div>
                                <div style={{cursor: 'pointer'}} className="mx-2">
                                    <DeleteRed onClick={() => {
                                      setShowModal(true)
                                      setFullName(item.fullName)
                                      setIdDelete(item.employeeNo)
                                    }}/>
                                </div>
                              </div>
                            </td>
                          </tr>         
                          ) 
                        }) : <></>
                      }       
                    </tbody>
                  </table>
                </div>

  
      <div className="row mt-4">
      <div className="col-lg-7 col-sm-12 col-xs-12 my-2">
            <div style={{display: 'flex'}}>
              <span className="mt-3 mx-2">
                Show
              </span>
              <span>
              <FormControl variant="standard" sx={{ m: 1, minWidth: 50}}>
            
            <SelectMui
              labelId="demo-simple-select-standard-label"
              id="demo-simple-select-standard"
              value={size}
              onChange={handleChange}
              label="Limit"
            >
              <MenuItem value={5}>5</MenuItem>
              <MenuItem value={10}>10</MenuItem>
              <MenuItem value={20}>20</MenuItem>
              <MenuItem value={30}>30</MenuItem>
            </SelectMui>
          </FormControl>
              </span>
              <span className="mt-3 mx-2">
                Entries
              </span>
            </div>
        </div>
        
         <div className="col-lg-5 col-sm-12 col-xs-12 my-2" style={{display: 'flex', justifyContent: 'end'}}>
              <Pagination count={totalPage} color="primary" page={page} showFirstButton showLastButton onChange={handleChangePage}/>
        </div>
      </div>

      <Modal isOpen={modalEdit} toggle={handleClose} size="lg" style={{marginTop: '15%'}}>
        <div className="my-4">
        <div className="px-5 pt-4" style={{height: '100%'}}>
          <div className="row">
          <div className="col-12">
            <div className="page-title-box d-flex align-items-center justify-content-between pb-0">
              <h1 className="mb-0 font-size-28">Edit Data Karyawan</h1>
            </div>
            <div className="page-title-box d-flex align-items-center justify-content-between">
              {/* <h1 className="mb-0 font-size-14">Lorem ipsum dolor sit amet, consectetur adipiscing elit</h1> */}
            </div>
          </div>
          </div>

<div className="row">
 
  <Formik
        initialValues={{position: initialValue?.position, employeeNo: initialValue?.employeeNo, date: DOB, phonenumber: initialValue?.phoneNumber, nik: initialValue?.employeeNo, fullname: initialValue?.fullName, email:initialValue?.email , group: { label: "HR", value: initialValue?.departmentId}}}
        validationSchema={LoginSchema}
        onSubmit={(values) => {
          console.log(values);
          // alert("Form is validated! Submitting the form...");
          // submitDataKaryawan(values)
          updateKaryawan(values)
        }}
      >
        {({ touched, errors, isSubmitting, values, setFieldValue}) =>
          (
            <div>
              <Form>
               <div className="">
               <div className="form-group">
                  <h5 className="text-xl">Nomor Identitas Karyawan (NIK)</h5>
                  <Field
                    type="text"
                    name="nik"
                    disabled={true}
                    placeholder=""
                    autocomplete="off"
                    className={`mt-2 form-control py-4
                    ${touched.nik && errors.nik ? "is-invalid" : ""}`}
                  />

                  <ErrorMessage
                    component="div"
                    name="nik"
                    className="invalid-feedback"
                  />
                </div>

                <div className="form-group">
                  <h5 className="text-xl">Full Name</h5>
                  <Field
                    type="text"
                    name="fullname"
                    placeholder=""
                    autocomplete="off"
                    className={`mt-2 form-control py-4
                    ${touched.fullname && errors.fullname ? "is-invalid" : ""}`}
                  />

                  <ErrorMessage
                    component="div"
                    name="fullname"
                    className="invalid-feedback"
                  />
                </div>

                <div className="form-group">
                  <h5 className="text-xl">Email </h5>
                  <Field
                    type="email"
                    name="email"
                    placeholder=""
                    autocomplete="off"
                    className={`mt-2 form-control py-4
                    ${touched.email && errors.email ? "is-invalid" : ""}`}
                  />

                  <ErrorMessage
                    component="div"
                    name="email"
                    className="invalid-feedback"
                  />
                </div>


                <div className="form-group">
                        <h5 className="text-xl">Phone Number</h5>
                        <Field
                          type="text"
                          name="phonenumber"
                          placeholder=""
                          autocomplete="off"
                          className={`mt-2 form-control py-4
                          ${touched.phonenumber && errors.phonenumber? "is-invalid" : ""}`}
                        />
  
                        <ErrorMessage
                          component="div"
                          name="phonenumber"
                          className="invalid-feedback"
                        />
                      </div>
                

                      <div className="form-group">
                        <h5 className="text-xl">Position</h5>
                        <Field
                          type="text"
                          name="position"
                          placeholder=""
                          autocomplete="off"
                          className={`mt-2 form-control py-4
                          ${touched.position && errors.position? "is-invalid" : ""}`}
                        />
  
                        <ErrorMessage
                          component="div"
                          name="position"
                          className="invalid-feedback"
                        />
                      </div>
                

                <div className="form-group mb-3 px-0">
                  <h5 className="text-xl">Join Date</h5>
                   <DatePicker
                   name="date"
                  //  format="yyyy-MM-dd"
                   selected={values.date} 
                   onChange={(date:Date) => setFieldValue('date', date)} 
                   className={`mt-2 form-control py-4 px-0 mx-0
                   ${touched.date && errors.date ? "is-invalid" : ""}`}
                   />
                  <div className="text-danger mt-3" style={{fontSize: '10px'}}>
                     {touched.date && errors.date ? errors.date : ""}
                  </div>
                 
                </div>
                
{/* 
                <div className="form-group mt-5s">
                  <h5 className="text-xl">Group</h5>
                  <Select
                    defaultValue={{ label: deptName, value: initialValue?.departmentId }}
                    name="group"
                    className={`
                    ${touched.nik && errors.nik ? "is-invalid" : ""}`}
                    styles={customStyles}
                    placeholder="Find Name Or Division"
                    options={listDepartment.map(e => ({ label: e.groupName, value: e.id}))}
                    onChange={newValue => setFieldValue('group', newValue)}
                  />
                 <div className="text-danger " style={{fontSize: '10px'}}>
                     {touched.group && errors.group ? errors.group : ""}
                  </div>
                </div> */}


              {/* {moment(initialValue?.dob).format('dd/MM/yyyy')} */}
              
                </div>
             
                
                  <Row className='mb-4 mt-4'>
                        <Col className='text-right' xs="12" sm="12" md="6" lg="6">
                        </Col>
                        <Col className='text-right' xs="12" sm="12" md="6" lg="6">
                        <div className='btn btn-primary px-5 mr-2' style={{background: '#002F48', borderColor: '#002F48'}} onClick={() => setModalEdit(false)}>
                            {loading ? <Spinner color='#fff' size="sm" className='mr-1' /> : <></>}
                             Cancel </div>
                          <button className='btn btn-primary px-5' type="submit">
                            {loading ? <Spinner color='#fff' size="sm" className='mr-1' /> : <></>}
                            Save </button>
                        </Col>
                  </Row>
              </Form>
           

            </div>
          )
        }
      </Formik>
  
</div>







</div>
        </div>
        
    </Modal>

    <Modal isOpen={showModal} toggle={handleClose} size="md" style={{marginTop: '15%'}}>
      <ModalHeader toggle={handleClose}>
            <div className="w-100">
              <h2 className="">Delete Confirmation</h2>
              <h3 style={{fontSize: '12px'}}>{`Apakah anda yakin menghapus user ${fullname}`}</h3>
            </div>
      </ModalHeader>
        <div className="my-4">
          <Row className='w-100'>
              <Col lg={12} className="text-right">
                <Button color="secondary" onClick={handleClose} className="mr-2">
                  <Translate contentKey="entity.action.cancel">Cancel</Translate>
                </Button>
                <Button  color="success px-4" onClick={() => deleteKaryawan()} disabled={loadingDelete}>
                {loadingDelete ? <Spinner color='#fff' size="sm" className='mr-1' /> : <></>} Yes
                </Button>
              </Col>
            </Row>             
        </div>
        
    </Modal>
    </div> 
  );
};

const mapStateToProps = ({ document }: IRootState) => ({
 
});


const mapDispatchToProps = {
 
};
type StateProps = ReturnType<typeof mapStateToProps>
type DispatchProps = typeof mapDispatchToProps;;

export default connect(mapStateToProps, mapDispatchToProps)(Karyawan);
