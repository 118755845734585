import '../../../../../../scss/bootstrap.min.scss'
import '../../../../../../scss/app.min.scss'
import './notification.scss';
import { renderToString } from "react-dom/server";
import { render } from "react-dom";
import tableAppr from 'app/modules/home/table'
import { Listbox, Transition } from '@headlessui/react'
// import { CheckIcon, SelectorIcon } from '@heroicons/react/solid'
import { PDFViewer } from '@react-pdf/renderer';
import Pagination from '@mui/material/Pagination';
const list = [
  { value: 2 },
  { value: 5 },
  { value: 10 },
  { value: 15 },
  { value: 20 },
  { value: 25 },
]
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import {Select as SelectMui } from '@mui/material';

import React, { useEffect, useState, Fragment } from 'react';
import { Link, RouteComponentProps } from 'react-router-dom';
import { Translate } from 'react-jhipster';
import { connect } from 'react-redux';
import { CardGroup, Row, Col, Alert, Tooltip, Modal, ModalHeader, ModalBody, FormGroup, Input, ModalFooter, Button, Spinner } from 'reactstrap';

import { IRootState } from 'app/shared/reducers';


import SideBar from 'app/shared/layout/sidebar/sidebar';
import Approval from 'app/shared/layout/card/approval';
import HandshakeIcon from 'app/component/handshake-icon';
import EyeIcon from 'app/component/eye-icon';
import EnvelopeIcon from 'app/component/envelope-icon';
import PaperPlaneIcon from 'app/component/paperplane-icon';
import InfoIcon from 'app/component/InfoIcon';

import { getSearchEntities, getEntities, getEntitiesByEmpId, getDocumentApproval, getDocumentAcknowledge, getDocumentSuratKeluar, getDocumentSuratMasuk } from '../document/document.reducer';
import Axios from 'axios';
import Page from 'app/component/pagination/Page';
import { useHistory } from "react-router-dom";
import ArrowRightIcon from 'app/component/arrow-right-icon';
import moment from 'moment-timezone';
import axios from 'axios'
import { size } from 'lodash';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import BottomSort from 'app/component/bottom-sort';
import Illustration1 from 'app/component/illustration-1';
import EditBlue from 'app/component/edit-blue';
import DeleteRed from 'app/component/delete-red';

import UploadDocumentListIcon from 'app/component/upload-document-karyawan';
import UserAddKaryawanIcon from 'app/component/user-add-karyawan';

import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import Select from 'react-select';
import DatePicker from "react-datepicker";
import { toast } from 'react-toastify';

export interface IHeaderProps {
  isAuthenticated?: boolean;
  isAdmin?: boolean;
  ribbonEnv?: string;
  isInProduction?: boolean;
  isSwaggerEnabled?: boolean;
  currentLocale?: string;
  onLocaleChange?: Function;
  account?: any,
  logout?: any,
  location?: any
}


export const Group = (props: IHeaderProps) => {
  let history = useHistory();
  const [search, setSearch] = useState('')
  const [loading, setLoading] = useState(false)
  const [keyword, setKeyword] = useState('')
  const [listSearch, setListSearch] = useState([])
  const [loadingList, setLoadingList] = useState(false)
  const [showList, setShowList] = useState(false)
  const [page, setPage] = useState(1)
  const [totalPage, setTotalPage] = useState(1)
  const [size, setSize] = useState<any>(10)
  const [sortType, setSortType] = useState('typeName.keyword,asc')
  const [sortPriority, setSortPriority] = useState('priority.keyword,asc')
  const [sortLastModified, setSortLastModified] = useState('lastApprovedByName.keyword,asc')
  const [sortDate, setSortDate] = useState('date,asc')
  const [listNotification, setListNotification] = useState<any>([])
  const [listKaryawan, setListKaryawan] = useState<any>([])
  const [showModal, setShowModal] = useState<any>(false)
  const [fullname, setFullName] = useState<any>("")
  const [iddelete, setIdDelete] = useState<any>(null)
  const [loadingDelete, setLoadingDelete] = useState<any>(false)
  const [listDepartment, setListDepartment] = useState<any>([])
  const [modalEdit, setModalEdit] = useState<any>(false)
  const [modalAdd, setModalAdd] = useState<any>(false)
  const [listHC, setListHC] = useState<any>([])
  const [initialValue, setInitialValue] = useState<any>()
  

  const customStyles = {
    control: base => ({
      ...base,
      height: 54,
      minHeight: 54
    })
  };

  const getListKaryawanHC = () => {

    let payload = {
      keyword: "",
      limit: 10000,
      page: 0
    }
    axios.post('services/uaadocservice/api/group/9', payload).then(res => {
      console.log('hc karyawan list', res.data.data.data.members)
      // setListDepartment(res.data)
      setListHC(res.data.data.data.members)
    }).catch(err => {
      console.log(err)
      setListHC([])
    })
  }

  const LoginSchema = Yup.object().shape({
    group_name: Yup.string()
    .required("Group Name is required"),
    adminHC: Yup.string()
    .required("Admin HC is required"),
    document_code: Yup.string().max(16, 'Must be exactly 16 digits or less')
  });

  const addFormGroup = () => {
    setModalEdit(true)
  }
  
  const editFormGroup = (id) => {
    let payload = {
      keyword: "",
      limit: 10000,
      page: 0
    }

    axios.post(`/services/uaadocservice/api/group/${id}`, payload).then((res) => {
      console.log('group detail', res.data.data.data)
      setInitialValue(res.data.data.data)
      setModalEdit(true)
      
      }).catch((err) => {
        setModalEdit(false)

      })
  }

  useEffect(() => {
    // alert('data search', props.location.state.search)
    // alert(props.location.state.search)
    
    getListDataKaryawan()
   
  }, [page, size, totalPage]);

  const handleClose = () => {
      setShowModal(false)
  }

  
    // alert('data search', props.location.state.search)
    // alert(props.location.state.search)
    
  


  const handleChange = (event) => {
    setPage(1)
    setSize(parseInt(event.target.value));
  };


  useEffect(() => {
    console.log('data search', props.location.state?.search)
    // alert(props.location.state.search)
    getListDataKaryawan()
    // getDepartmentList()
    getListKaryawanHC()
  }, []);

  const setDefault = () => {
    setSortType('typeName.keyword,asc')
    setSortPriority('priority.keyword,asc')
    setSortLastModified('lastApprovedByName.keyword,asc')
    setSortDate('date,asc')
  }

  const getListDataKaryawan = () => {
    let pageReal = page
    setLoadingList(true)
    let payload = {
      sort: "",
      is_ascending: true,
      page: pageReal - 1,
      limit: size,
      keyword: keyword
    }
    axios.post(`/services/uaadocservice/api/group/list`, payload).then((res) => {
        console.log('group', res)
        setListKaryawan(res.data.data.groups)
        setTotalPage(res.data.data.totalPage)
    }).catch((err) => {
      setLoadingList(false)

    })
  }

  const getListNotification = () => {
    setLoadingList(true)
    axios.get(`services/documentservice/api/notification/get-list?page=${page}&size=${size}&sort=createdDate,desc`).then((res) => {
        console.log('notif', res.data.data.content)
        setListNotification(res.data.data.content)
          setTotalPage(res.data.data.totalPages)
          setLoadingList(false)
    }).catch((err) => {
      setLoadingList(false)

    })
  }

  const submitDataGroup = (data) => {
    console.log('submit', data)
    // setLoading(true)
    let payload = {
        groupName: data.group_name,
        adminHC: data.adminHC.value,
        documentCode: data.document_code
    }
    axios.post('services/uaadocservice/api/group/add', payload).then(res => {
      // console.log(res.data.status)
      toast.success('Success Add Group', {
        position: "top-left",
        autoClose: 8000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        });
        setModalAdd(false)
        getListDataKaryawan()
        // history.push('/karyawan')
    }).catch(err => {
      toast.error('Failed Add Group', {
        position: "top-left",
        autoClose: 8000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        });
    })
  }

  const submitUpdateGroup = (data:any, id: any) => {
    console.log('submit', data)
    // setLoading(true)
    let payload = {
        groupName: data.group_name,
        adminHC: data.adminHC.value,
        documentCode: data.document_code
    }
    axios.put(`/services/uaadocservice/api/group/${id}/update`, payload).then(res => {
      // console.log(res.data.status)
      toast.success('Success Update Group', {
        position: "top-left",
        autoClose: 8000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        });
        setModalEdit(false)
        getListDataKaryawan()
    }).catch(err => {
      toast.error('Failed Update Group', {
        position: "top-left",
        autoClose: 8000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        });
    })
  }



  const delayTime = (ms) => {
    return new Promise(
      resolve => setTimeout(resolve, ms)
    )
  }

  
  const getSearch = async (e = '') => {
    if(e.length > 1 ) {
      setLoadingList(true)
      axios.get(`services/uaadocservice/api/custom-search/documents?keyword=${e}&page=${page}&size=${size}&sort=${sortType}&sort=${sortPriority}&sort=${sortLastModified}&sort=${sortDate}`).then( async (result) => {
        console.log(result.data)
        setListSearch(result.data.content)
        // setTotalPage(result.data?.totalPages)
        await delayTime(2000)
        setLoadingList(false)
      }).catch(err => {
        setListSearch([])
        setLoadingList(false)
      })
    }
  }


  


  const goToPage = () => {
    setSearch('')
    history.push('/karyawan/upload')
  }

  const goToPageManual = () => {
    setSearch('')
    history.push('/karyawan/add')
  }
  const gotoDetailGroup = (id) => {
    setSearch('')
    history.push(`/group/detail/${id}`)
  }
  
  


  const handleChangePage = (event: React.ChangeEvent<unknown>, value: number) => {
    setPage(value);
  };


  const deleteKaryawan = () => {
    setLoadingDelete(true)
    axios.delete(`services/uaadocservice/api/department/delete/${iddelete}`).then(res => {
      console.log(res)
      setShowModal(false)
      setIdDelete(null)
      setLoadingDelete(false)
      getListDataKaryawan()
      toast.success('Success Delete Employee', {
        position: "top-left",
        autoClose: 8000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        });
    }).catch(err => {
      console.log(err.response)
      setLoadingDelete(false)
      toast.error('Failed Delete Employee', {
        position: "top-left",
        autoClose: 8000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        });
    })
  }

  const deleteGroup = () => {
    setLoadingDelete(true)
    axios.delete(`services/uaadocservice/api/group/${iddelete}/delete`).then(res => {
      console.log(res)
      setShowModal(false)
      setIdDelete(null)
      setLoadingDelete(false)
      getListDataKaryawan()
      toast.success('Success Delete Group', {
        position: "top-left",
        autoClose: 8000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        });
    }).catch(err => {
      console.log(err.response)
      setLoadingDelete(false)
      toast.error('Failed Delete Group', {
        position: "top-left",
        autoClose: 8000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        });
    })
  }
 


 
  return (
    <div className="px-5 pt-4" style={{height: '100%'}}>

    <div className="row">
      <div className="col-12">
        <div className="page-title-box d-flex align-items-center justify-content-between">
          <h1 className="mb-0 font-size-28">Group Karyawan</h1>
        </div>
        <div className="page-title-box d-flex align-items-center justify-content-between">
          {/* <h1 className="mb-0 font-size-14">Lorem ipsum dolor sit amet, consectetur adipiscing elit</h1> */}
        </div>
      </div>
    </div>


    <div className='row my-3'>
  
        <div className="col-12 col-md-2 col-lg-2 px-2">
            <div className='btn btn-primary w-100 py-2' onClick={(data) => setModalAdd(true)}>
             <UploadDocumentListIcon/> Buat Group
            </div>
        </div>
        <div className="col-12 col-md-10 col-lg-10 px-0">
        <div className="d-flex px-3" style={{ flexGrow: 10 }}>
            <div className="app-search w-100 py-0 bg-white">
              <div className="position-relative">
                <input type="text" className="form-control bg-white py-3" placeholder="Cari Group" onChange={(e) => {
                  if(e.target.value === '') {
                    setKeyword(e.target.value)
                    getListDataKaryawan()
                  } else {
                    setKeyword(e.target.value)
                  }
                }} onKeyPress={(e) => {
                  console.log(e.key)
                  if(e.key == 'Enter') {
                    setPage(1)
                    getListDataKaryawan()
                    
                  }
                }}/>
                <span className="bx bx-search-alt my-0"></span>
              </div>
            </div>
          </div>
        </div>
    </div>

    <div className="table-responsive mt-4">
                  <table className="table table-striped table-centered  mb-0">
                    <thead className="thead-light">
                      <tr>
                      
                        <th className='py-3'>Nama Group</th>
                        <th>Handled By Admin</th>
                        <th>Total Member</th>
                        <th className='text-center'>Action</th>
                      </tr>
                    </thead>
                    <tbody>
                    

                      {
                        listKaryawan ? listKaryawan.map((item, index) => {
                          return (
                            <tr >
                            <td className='py-2' style={{wordBreak: 'break-all', cursor: 'pointer'}} onClick={() => gotoDetailGroup(item.id)}>{item.groupName?? "-"}</td>
                            <td style={{wordBreak: 'break-all'}}>{item.adminHCDetails?.fullName ?? "-"}</td>
                            <td style={{wordBreak: 'break-all'}}>{item.members.length}</td>
                            <td style={{wordBreak: 'break-all', width: '10%'}}>
                              <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'space-evenly'}}>
                                <div style={{cursor: 'pointer'}} className="mx-2" onClick={() =>  editFormGroup(item.id)}>
                                    <EditBlue/>
                                </div>
                                <div style={{cursor: 'pointer'}} className="mx-2">
                                    <DeleteRed onClick={() => {
                                      setShowModal(true)
                                      setFullName(item.groupName)
                                      setIdDelete(item.id)
                                    }}/>
                                </div>
                              </div>
                            </td>
                          </tr>         
                          ) 
                        }) : <></>
                      }       
                    </tbody>
                  </table>
                </div>

  
      <div className="row mt-4">
      <div className="col-lg-7 col-sm-12 col-xs-12 my-2">
            <div style={{display: 'flex'}}>
              <span className="mt-3 mx-2">
                Show
              </span>
              <span>
              <FormControl variant="standard" sx={{ m: 1, minWidth: 50}}>
            
            <SelectMui
              labelId="demo-simple-select-standard-label"
              id="demo-simple-select-standard"
              value={size}
              onChange={handleChange}
              label="Limit"
            >
              <MenuItem value={5}>5</MenuItem>
              <MenuItem value={10}>10</MenuItem>
              <MenuItem value={20}>20</MenuItem>
              <MenuItem value={30}>30</MenuItem>
            </SelectMui>
          </FormControl>
              </span>
              <span className="mt-3 mx-2">
                Entries
              </span>
            </div>
        </div>
        
         <div className="col-lg-5 col-sm-12 col-xs-12 my-2" style={{display: 'flex', justifyContent: 'end'}}>
              <Pagination count={totalPage} color="primary" page={page} showFirstButton showLastButton onChange={handleChangePage}/>
        </div>
      </div>
      <Modal isOpen={modalAdd} toggle={handleClose} size="lg" style={{marginTop: '15%'}}>
        <div className="my-4">
        <div className="px-5 pt-4" style={{height: '100%'}}>
          <div className="row">
          <div className="col-12">
            <div className="page-title-box d-flex align-items-center justify-content-between pb-0">
              <h1 className="mb-0 font-size-28">Add Data Group</h1>
            </div>
            <div className="page-title-box d-flex align-items-center justify-content-between">
              {/* <h1 className="mb-0 font-size-14">Lorem ipsum dolor sit amet, consectetur adipiscing elit</h1> */}
            </div>
          </div>
          </div>

<div className="row">
 
  <Formik
        initialValues={{ group_name: "", adminHC: "", document_code: ""}}
        validationSchema={LoginSchema}
        onSubmit={(values) => {
          console.log(values);
          // alert("Form is validated! Submitting the form...");
          // submitDataKaryawan(values)
          submitDataGroup(values)
        }}
      >
        {({ touched, errors, isSubmitting, values, setFieldValue}) =>
          (
            <div>
              <Form>
               <div className="">
               <div className="form-group">
                  <h5 className="text-xl">Nama Group</h5>
                  <Field
                    type="text"
                    name="group_name"
                    placeholder="ex : Information Technology"
                    autocomplete="off"
                    className={`mt-2 form-control py-4
                    ${touched.group_name && errors.group_name ? "is-invalid" : ""}`}
                  />

                  <ErrorMessage
                    component="div"
                    name="group_name"
                    className="invalid-feedback"
                  />
                </div>

                <div className="form-group">
                  <h5 className="text-xl">Document Code</h5>
                  <Field
                    type="text"
                    name="document_code"
                    placeholder="ex : IT"
                    autocomplete="off"
                    className={`mt-2 form-control py-4
                    ${touched.document_code && errors.document_code ? "is-invalid" : ""}`}
                  />
                  <span className="" style={{fontSize: 10, color: '#bdbdbd'}}>ex : IT for Division Information Technology</span>

                  <ErrorMessage
                    component="div"
                    name="group_name"
                    className="invalid-feedback"
                  />
                </div>

                
                

                <div className="form-group">
                  <h5 className="text-xl">Admin HC</h5>
                  <Select
                    name="group"
                    className={`
                    ${touched.adminHC && errors.adminHC ? "is-invalid" : ""}`}
                    styles={customStyles}
                    placeholder="Find Name Or Division"
                    options={listHC.map(e => ({ label: e.employee.fullName, value: e.employee.employeeNo}))}
                    onChange={newValue => setFieldValue('adminHC', newValue)}
                  />
                 <div className="text-danger " style={{fontSize: '10px'}}>
                     {touched.adminHC && errors.adminHC ? errors.adminHC : ""}
                  </div>
                </div>
                </div>
             
                
                  <Row className='mb-4 mt-4'>
                        <Col className='text-right' xs="12" sm="12" md="6" lg="6">
                        </Col>
                        <Col className='text-right' xs="12" sm="12" md="6" lg="6">
                        <div className='btn btn-primary px-5 mr-2' style={{background: '#002F48', borderColor: '#002F48'}} onClick={() => setModalAdd(false)}>
                            {loading ? <Spinner color='#fff' size="sm" className='mr-1' /> : <></>}
                           Cancel </div>
                          <button className='btn btn-primary px-5' type="submit">
                            {loading ? <Spinner color='#fff' size="sm" className='mr-1' /> : <></>}
                            Save</button>
                        </Col>
                  </Row>
              </Form>
           

            </div>
          )
        }
      </Formik>
  
</div>







</div>
        </div>
        
    </Modal>
      <Modal isOpen={modalEdit} toggle={handleClose} size="lg" style={{marginTop: '15%'}}>
        <div className="my-4">
        <div className="px-5 pt-4" style={{height: '100%'}}>
          <div className="row">
          <div className="col-12">
            <div className="page-title-box d-flex align-items-center justify-content-between pb-0">
              <h1 className="mb-0 font-size-28">Edit Data Group</h1>
            </div>
            <div className="page-title-box d-flex align-items-center justify-content-between">
              {/* <h1 className="mb-0 font-size-14">Lorem ipsum dolor sit amet, consectetur adipiscing elit</h1> */}
            </div>
          </div>
          </div>

<div className="row">
 
  <Formik
        initialValues={{ document_code: initialValue?.documentCode, group_name: initialValue?.groupName , adminHC: {label: initialValue?.adminHCDetails?.fullName, value: initialValue?.adminHCDetails?.employeeNo}}}
        validationSchema={LoginSchema}
        onSubmit={(values) => {
          console.log(values);
          // alert("Form is validated! Submitting the form...");
          // submitDataKaryawan(values)
          submitUpdateGroup(values, initialValue.id)
        }}
      >
        {({ touched, errors, isSubmitting, values, setFieldValue}) =>
          (
            <div>
              <Form>
               <div className="">
               <div className="form-group">
                  <h5 className="text-xl">Nama Group</h5>
                  <Field
                    type="text"
                    name="group_name"
                    placeholder="ex : Information Technology"
                    autocomplete="off"
                    className={`mt-2 form-control py-4
                    ${touched.group_name && errors.group_name ? "is-invalid" : ""}`}
                  />

                  <ErrorMessage
                    component="div"
                    name="group_name"
                    className="invalid-feedback"
                  />
                </div>

                <div className="form-group">
                  <h5 className="text-xl">Document Code</h5>
                  <Field
                    type="text"
                    name="document_code"
                    placeholder="ex : IT"
                    autocomplete="off"
                    className={`mt-2 form-control py-4
                    ${touched.document_code && errors.document_code ? "is-invalid" : ""}`}
                  />
                  <span className="" style={{fontSize: 10, color: '#bdbdbd'}}>ex : IT for Division Information Technology</span>
                  <ErrorMessage
                    component="div"
                    name="document_code"
                    className="invalid-feedback"
                  />
                </div>
             

                
                

                <div className="form-group">
                  <h5 className="text-xl">Admin HC</h5>
                  <Select
                    name="adminHC"
                    className={`
                    ${touched.adminHC && errors.adminHC ? "is-invalid" : ""}`}
                    styles={customStyles}
                    defaultValue={{label: initialValue?.adminHCDetails?.fullName, value: initialValue?.adminHCDetails?.employeeNo}}
                    placeholder="Find Name Or Division"
                    options={listHC.map(e => ({ label: e.employee.fullName, value: e.employee.employeeNo}))}
                    onChange={newValue => setFieldValue('adminHC', newValue)}
                  />
                 <div className="text-danger " style={{fontSize: '10px'}}>
                     {touched.adminHC && errors.adminHC ? errors.adminHC : ""}
                  </div>
                </div>
                </div>
             
                
                  <Row className='mb-4 mt-4'>
                        <Col className='text-right' xs="12" sm="12" md="6" lg="6">
                        </Col>
                        <Col className='text-right' xs="12" sm="12" md="6" lg="6">
                        <div className='btn btn-primary px-5 mr-2' style={{background: '#002F48', borderColor: '#002F48'}} onClick={() => setModalEdit(false)}>
                            {loading ? <Spinner color='#fff' size="sm" className='mr-1' /> : <></>}
                           Cancel </div>
                          <button className='btn btn-primary px-5' type="submit">
                            {loading ? <Spinner color='#fff' size="sm" className='mr-1' /> : <></>}
                            Save</button>
                        </Col>
                  </Row>
              </Form>
           

            </div>
          )
        }
      </Formik>
  
</div>







</div>
        </div>
        
    </Modal>

    <Modal isOpen={showModal} toggle={handleClose} size="md" style={{marginTop: '15%'}}>
      <ModalHeader toggle={handleClose}>
            <div className="w-100">
              <h2 className="">Delete Confirmation</h2>
              <h3 style={{fontSize: '12px'}}>{`Apakah anda yakin menghapus group ${fullname}`}</h3>
            </div>
      </ModalHeader>
        <div className="my-4">
          <Row className='w-100'>
              <Col lg={12} className="text-right">
                <Button color="secondary" onClick={handleClose} className="mr-2">
                  <Translate contentKey="entity.action.cancel">Cancel</Translate>
                </Button>
                <Button  color="success px-4" onClick={() => deleteGroup()} disabled={loadingDelete}>
                {loadingDelete ? <Spinner color='#fff' size="sm" className='mr-1' /> : <></>} Yes
                </Button>
              </Col>
            </Row>             
        </div>
        
    </Modal>
    </div> 
  );
};

const mapStateToProps = ({ document }: IRootState) => ({
 
});


const mapDispatchToProps = {
 
};
type StateProps = ReturnType<typeof mapStateToProps>
type DispatchProps = typeof mapDispatchToProps;;

export default connect(mapStateToProps, mapDispatchToProps)(Group);
