export const priorityDocument = [
    {
        label: "High",
        value: "1"
    },
    {
        label: "Medium",
        value: "2"
    },
    {
        label: "Low",
        value: "3"
    },
]


export const  typeDocument = [{
    label: "Internal Memo",
    value: "IMO"
  }]
  
export const documentSecurity = [
      {
        label: "Strictly Confidential",
        value: "1"
      },
      {
        label: "Confidential",
        value: "2"
      },
      {
        label: "General",
        value: "3"
      },
  ]