import '../../../../../../scss/bootstrap.min.scss';
import '../../../../../../scss/app.min.scss';
import '../audit/audit.scss';
import { renderToString } from 'react-dom/server';
import { render } from 'react-dom';
import tableAppr from 'app/modules/home/table';
import { Listbox, Transition } from '@headlessui/react';
// import { CheckIcon, SelectorIcon } from '@heroicons/react/solid'
import { PDFViewer } from '@react-pdf/renderer';
import Pagination from '@mui/material/Pagination';
const list = [
  { value: 2 },
  { value: 5 },
  { value: 10 },
  { value: 15 },
  { value: 20 },
  { value: 25 },
];
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from '@mui/material/Select';

import React, { useEffect, useState, Fragment } from 'react';
import { Link, RouteComponentProps } from 'react-router-dom';
import { Translate } from 'react-jhipster';
import { connect } from 'react-redux';
import {
  CardGroup,
  Row,
  Col,
  Alert,
  Tooltip,
  Modal,
  ModalHeader,
  ModalBody,
  FormGroup,
  Input,
  ModalFooter,
  Button,
  Spinner,
} from 'reactstrap';

import { IRootState } from 'app/shared/reducers';

import SideBar from 'app/shared/layout/sidebar/sidebar';
import Approval from 'app/shared/layout/card/approval';
import HandshakeIcon from 'app/component/handshake-icon';
import EyeIcon from 'app/component/eye-icon';
import EnvelopeIcon from 'app/component/envelope-icon';
import PaperPlaneIcon from 'app/component/paperplane-icon';
import InfoIcon from 'app/component/InfoIcon';

import {
  getSearchEntities,
  getEntities,
  getEntitiesByEmpId,
  getDocumentApproval,
  getDocumentAcknowledge,
  getDocumentSuratKeluar,
  getDocumentSuratMasuk,
} from '../document/document.reducer';
import Axios from 'axios';
import Page from 'app/component/pagination/Page';
import { useHistory } from 'react-router-dom';
import ArrowRightIcon from 'app/component/arrow-right-icon';
import moment from 'moment-timezone';
import axios from 'axios';
import { size } from 'lodash';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import BottomSort from 'app/component/bottom-sort';
import Illustration1 from 'app/component/illustration-1';
import AccountAnnounceIcon from 'app/component/announ-icon';
import EyeSmallIcon from 'app/component/eye-small-icon';
import PencilIcon from 'app/component/pencil-icon';
import { toast } from 'react-toastify';

import DatePicker from 'react-datepicker';
import { Dropdown, DropdownButton } from 'react-bootstrap';
import formatDate from 'app/utils/utils';
import { BASE_URL } from 'app/utils/constant';

export interface IHeaderProps {
  isAuthenticated?: boolean;
  isAdmin?: boolean;
  ribbonEnv?: string;
  isInProduction?: boolean;
  isSwaggerEnabled?: boolean;
  currentLocale?: string;
  onLocaleChange?: Function;
  account?: any;
  logout?: any;
  location?: any;
}

const initialFormData = {
  id: '0',
  type: '',
  priority: '',
  documentSecurity: '',
  startDate: new Date(),
  lastDate: new Date(),
  regarding: '',
  fileContent: '',
  to: [],
  cc: [],
  acknowledge: [],
  approval: [],
};

export interface AuditProps
  extends StateProps,
    DispatchProps,
    RouteComponentProps<{ url: string }> {}

export const Audit = (props: AuditProps) => {
  let history = useHistory();
  const [search, setSearch] = useState('');
  const [listSearch, setListSearch] = useState([]);
  const [loadingList, setLoadingList] = useState(false);
  const [showList, setShowList] = useState(false);
  const [page, setPage] = useState(1);
  const [totalPage, setTotalPage] = useState(1);
  const [size, setSize] = useState<any>(10);
  const [sortType, setSortType] = useState('typeName.keyword,asc');
  const [sortPriority, setSortPriority] = useState('priority.keyword,asc');
  const [sortLastModified, setSortLastModified] = useState(
    'lastApprovedByName.keyword,asc'
  );
  const [sortDate, setSortDate] = useState('date,asc');
  const [limit, setLimit] = useState<any>(5);
  const [listAnnouncement, setListAnnouncement] = useState<any>([]);
  const [showModal, setShowModal] = useState<any>(false);
  const [selectedItem, setItemSelected] = useState<any>(null);
  const [loadingDelete, setLoadingDelete] = useState<any>(false);
  const [isAdmin, setIsAdmin] = useState<Boolean>(false);
  const [isAnnounce, setIsAnnounce] = useState<Boolean>(false);

  const [profiledrop, setProfileDrop] = useState(false);

  const [formData, setFormData] = useState(initialFormData);
  const [searchByDropDown, setSearchByDropDown] = useState<any>('Search by');
  const [filterAccountType, setfilterAccountType] = useState<any>('Modules');
  const [listAudit, setListAudit] = useState<any>([]);

  const [filterOpeningBranch, setfilterOpeningBranch] = useState<any>(
    'Opening Branch'
  );
  const [searchValue, setSearchValue] = useState('');
  const [searchBy, setSearchBy] = useState('');
  const [filterModule, setFilterModule] = useState('');
  const [sort, setSort] = useState<any>('REG-DATE');
  const [ascending, setAscending] = useState<any>(false);

  const [filterBankRdn, setfilterBankRdn] = useState<any>('');
  const [filterStatus, setfilterStatus] = useState<any>('');

  const [totalItems, setTotalItems] = useState<any>(0);

  useEffect(() => {
    getIsAdmin();
  }, []);

  useEffect(() => {
    getListAudit(page, limit);
  }, [sort, page, limit, ascending, formData, filterModule]);

  const setDropdown = () => {
    setProfileDrop(!profiledrop);
  };

  const setDefault = () => {
    setSortType('typeName.keyword,asc');
    setSortPriority('priority.keyword,asc');
    setSortLastModified('lastApprovedByName.keyword,asc');
    setSortDate('date,asc');
  };

  const getListAudit = (page, limit) => {
    var data = JSON.stringify({
      searchBy: searchBy,
      searchValue: searchValue,
      module: filterModule,
      page: page,
      size: limit,
      sortBy: sort,
      sortValue: ascending ? 'asc' : 'desc',
      fromDate: moment(formData.startDate).format('DD/MM/YYYY'),
      toDate: moment(formData.lastDate).format('DD/MM/YYYY'),
    });

    var config = {
      headers: {
        'Content-Type': 'application/json',
      },
    };

    axios
      .post(`${BASE_URL.HOTSREGIS}api/auditTrail/get-page`, data, config)
      .then(function (res) {
        console.log(JSON.stringify(res.data));
        setLoadingList(true);
        setListAudit(res.data.data?.content);
        setTotalPage(res.data.data?.totalPages);
        setTotalItems(res.data.data?.totalItems);
        setLoadingList(false);
      })
      .catch(function (error) {
        console.log('LOG ERROR AXIOS ', error);
      });
  };

  const goToDetail = (data) => {
    let languageValue = 'ID';
    axios
      .get(`${BASE_URL.HOTSREGIS}api/auditTrail/get-detail/${data?.id}`)
      .then((res) => {
        if (res.data.code == 'SUCCESS') {
          console.log('res');
          history.push(`/audit/view/${data.id}`);
        } else {
          toast.error('Error Reject:' + res.data.message, {
            position: 'top-left',
            autoClose: 8000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        }
      })
      .catch((err) => {
        toast.error('Error Reject:' + err, {
          position: 'top-left',
          autoClose: 8000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
        history.push(`/audit/list`);
      });
  };

  const delayTime = (ms) => {
    return new Promise((resolve) => setTimeout(resolve, ms));
  };

  const getIsAdmin = () => {
    axios
      .get('/services/uaadocservice/api/is-admin-dms')
      .then((res) => {
        console.log('is Admin', res.data);
        setIsAdmin(res.data);
      })
      .catch((err) => {
        setIsAdmin(false);
      });
  };

  const goToPage = (id) => {
    setSearch('');
    history.push(`/preview/only/pdf/${id}`);
  };

  const changePage = (event: React.ChangeEvent<unknown>, value: number) => {
    console.log(value);
    setPage(value);
    getListAudit(value, limit);
  };

  const changeShow = (e) => {
    // alert(e.target.value)
    let show = e.target.value;
    console.log(limit);
    setPage(1);
    setLimit(show);
    getListAudit(1, show);
  };

  const handleClose = () => {
    setShowModal(false);
  };

  const openModalDelete = (item) => {
    setShowModal(true);
    setItemSelected(item);
  };

  const handleSearchBy = (e) => {
    var searchByString = '';
    console.log('SELECTED SEARCH BY', e);
    switch (e) {
      case 'ID DATA':
        searchByString = 'ID-DATA';
        break;
      case 'ACTOR':
        searchByString = 'ACTOR';
        break;
      default:
        searchByString = '';
        break;
    }
    console.log('Search by string', searchByString);
    setSearchByDropDown(e);
    setSearchBy(searchByString);
  };

  const handleFilterModule = (e) => {
    console.log('SELECTED', e);
    var filterModuleString = '';
    console.log('SELECTED SEARCH BY', e);
    switch (e) {
      case 'Customer Regis':
        filterModuleString = 'CUSTOMER-REGIS';
        break;
      case 'Master Data':
        filterModuleString = 'MASTER-DATA';
        break;
      case 'Login':
        filterModuleString = 'LOGIN';
        break;
      default:
        filterModuleString = '';
        break;
    }
    console.log('Search by string', filterModuleString);
    setFilterModule(filterModuleString);
  };

  const handleFilterBankRdn = (e) => {
    console.log('SELECTED BANK', e);
    setfilterBankRdn(e);
    // getListAudit(page, limit, '', e.toUpperCase());
  };

  const handleDownloadBulk = () => {
    var data = JSON.stringify({
      searchBy: searchBy,
      searchValue: searchValue,
      module: filterModule,
      page: page,
      size: limit,
      sortBy: sort,
      sortValue: ascending ? 'asc' : 'desc',
      fromDate: moment(formData.startDate).format('DD/MM/YYYY'),
      toDate: moment(formData.lastDate).format('DD/MM/YYYY'),
    });

    var config = {
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/pdf',
      },
      responseType: 'arraybuffer',
    };

    axios
      .post(`${BASE_URL.HOTSREGIS}api/auditTrail/get-report-xlsx`, data, config)
      .then(function (res) {
        let headerLine = res?.headers['content-disposition'];
        let startFileNameIndex = headerLine?.indexOf('"') + 1;
        let endFileNameIndex = headerLine?.lastIndexOf('"');
        let filename = headerLine?.substring(
          startFileNameIndex,
          endFileNameIndex
        );

        console.log('response', res?.headers);

        console.log(JSON.stringify(res.data));
        const url = window.URL.createObjectURL(new Blob([res.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', filename); //or any other extension
        document.body.appendChild(link);
        link.click();
      })
      .catch(function (error) {
        console.log('LOG ERROR AXIOS ', error);
        toast.error('Failed Export: ' + error, {
          position: 'top-left',
          autoClose: 8000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      });
  };

  return (
    <div className="px-5 pt-4" style={{ height: '100vh', background: '#fff' }}>
      <div className="row">
        <div className="col-12">
          <div className="page-title-box d-flex align-items-center justify-content-between">
            <h1 className="mb-0 font-size-28">Audit Trails</h1>
          </div>
          <div className="page-title-box d-flex align-items-center justify-content-between">
            <h1 className="mb-0 font-size-14">New CMS Dashboard</h1>
          </div>
        </div>
      </div>

      <div className="row mb-4">
        <div className="col-1 col-lg-2 col-md-2 col-xxl-2 col-xl-2">
          <DropdownButton
            id="dropdown-basic-button"
            className=""
            title={searchByDropDown == '' ? 'Search by' : searchByDropDown}
            onSelect={handleSearchBy}
          >
            <Dropdown.ItemText>Search by</Dropdown.ItemText>
            <Dropdown.Divider className="mx-4"></Dropdown.Divider>
            <Dropdown.Item eventKey="ID DATA">ID DATA</Dropdown.Item>
            <Dropdown.Item eventKey="ACTOR">Actor</Dropdown.Item>
          </DropdownButton>
        </div>
        <div className="app-search col-lg-7 col-md-7 col-xxl-9 col-xl-8">
          <div className="position-relative">
            <input
              type="text"
              className="form-control"
              placeholder="Enter value here"
              onChange={(e) => {
                if (e.target.value === '') {
                  setPage(1);
                  setSearchValue(e.target.value);
                  getListAudit(page, limit);
                } else {
                  setSearchValue(e.target.value);
                }
              }}
              onKeyPress={(e) => {
                console.log(e.key);
                if (e.key == 'Enter') {
                  setPage(1);
                  getListAudit(page, limit);
                }
              }}
            />
            <span className="bx bx-search-alt"></span>
          </div>
        </div>
        <div className="col-lg-3 col-md-3 col-xxl-2 col-xl-2">
          <a
            className="btn btn-primary w-100 text-white"
            onClick={(e) => {
              console.log('Nilai E', e);
              getListAudit(page, limit);
            }}
          >
            Search Customer
          </a>
        </div>
      </div>

      <div className="row">
        <div className="mr-5 col-lg-2">
          <h5>Module</h5>
        </div>
        <div className="col-5">
          <h5>Date</h5>
        </div>
      </div>
      <div className="row">
        <div className="mr-5 col-lg-2">
          <div className="page-title-box d-flex align-items-center">
            <DropdownButton
              id="dropdown-basic-button"
              className=""
              title={
                filterModule == ''
                  ? 'Select Modules'
                  : filterModule == 'All'
                  ? 'Select Modules'
                  : filterModule
              }
              onSelect={handleFilterModule}
            >
              <Dropdown.ItemText>Select Module</Dropdown.ItemText>
              <Dropdown.Divider className="mx-4"></Dropdown.Divider>
              <Dropdown.Item eventKey="All">All Module</Dropdown.Item>
              <Dropdown.Item eventKey="Customer Regis">
                Customer Regis
              </Dropdown.Item>
              <Dropdown.Item eventKey="Master Data">Master Data</Dropdown.Item>
              <Dropdown.Item eventKey="Login">Login</Dropdown.Item>
            </DropdownButton>
          </div>
        </div>
        <div className="date-filter">
          <div className="row">
            <div className="col-6">
              <DatePicker
                id="dropdown-basic-button"
                className="btn"
                // dateFormat="MMMM d, yyyy h:mm aa"
                // dateFormat="d MMMM yyyy - h:mm aa"
                dateFormat="d/MM/yyyy"
                // minDate={new Date()}
                selected={formData.startDate}
                onChange={(e) => {
                  console.log('form data last', formData.lastDate);
                  console.log('form data start', e);
                  setFormData({
                    ...formData,
                    startDate: e,
                  });
                }}
                selectsStart
                startDate={formData.startDate}
                endDate={formData.lastDate}
                maxDate={formData.lastDate}
              />
            </div>
            <div className="col-6">
              <DatePicker
                id="dropdown-basic-button"
                className="btn"
                // dateFormat="MMMM d, yyyy h:mm aa"
                // dateFormat="d MMMM yyyy - h:mm aa"
                dateFormat="d/MM/yyyy"
                // minDate={new Date()}
                selected={formData.lastDate}
                onChange={(e) => {
                  setFormData({
                    ...formData,
                    lastDate: e,
                  });
                }}
                selectsEnd
                startDate={formData.startDate}
                endDate={formData.lastDate}
                minDate={formData.startDate}
                maxDate={moment().toDate()}
              />
            </div>
          </div>
        </div>
        <div className="col-4">
          <a
            className="btn btn-primary mx-2"
            style={{ color: 'white' }}
            onClick={() => {
              handleDownloadBulk();
            }}
          >
            {' '}
            Export
          </a>
        </div>
      </div>

      <div className="my-3 bg-light">
        <table className="table table-centered table-nowrap mb-0 table-responsive">
          <thead
            className=""
            style={{
              background: '#F5F5F5',
              borderTopLeftRadius: 30,
              borderTopRightRadius: 30,
            }}
          >
            <tr>
              <th
                className="py-4 px-4"
                style={{ cursor: 'pointer' }}
                onClick={() => {
                  setSort('CREATED-DATE');
                  setAscending(!ascending);
                }}
              >
                <div
                  style={{ display: 'flex', alignItems: 'center' }}
                  className="gap-4"
                >
                  <span className="mr-1">Create Date</span>
                  <span className="mt-1">
                    {sort == 'CREATED-DATE' && ascending == true && (
                      <i
                        className="bx bxs-up-arrow"
                        style={{ fontSize: '10px' }}
                      ></i>
                    )}
                    {sort == 'CREATED-DATE' && ascending == false && (
                      <i
                        className="bx bxs-down-arrow  text-right"
                        style={{ fontSize: '10px' }}
                      ></i>
                    )}
                    {sort != 'CREATED-DATE' && (
                      <i
                        className="bx bxs-sort-alt text-right"
                        style={{ fontSize: '14px' }}
                      ></i>
                    )}
                  </span>
                </div>
              </th>
              <th className="py-4 px-4">Modules</th>
              <th className="px-4">Actor</th>
              <th className="px-4">ID Data</th>
              <th className="px-4">Activity</th>
              <th className="px-4 text-center" style={{ width: '4%' }}>
                Action
              </th>
            </tr>
          </thead>
          <tbody className="" style={{ background: '#fff' }}>
            {!loadingList ? (
              totalItems != 0 ? (
                listAudit.map((item, index) => {
                  return (
                    <tr
                      className=""
                      style={{ background: '#FFF', cursor: 'pointer' }}
                    >
                      <td
                        className=""
                        style={{
                          wordBreak: 'break-all',
                          minWidth: '100px',
                          maxWidth: '150px',
                          whiteSpace: 'pre-wrap',
                        }}
                        onClick={() => goToDetail(item)}
                      >
                        {formatDate(item?.activityDate, 'DD/MM/YY HH:mm:ss')}
                      </td>
                      <td
                        className=""
                        style={{
                          wordBreak: 'break-all',
                          minWidth: '200px',
                          maxWidth: '2500px',
                          whiteSpace: 'pre-wrap',
                        }}
                        onClick={() => goToDetail(item)}
                      >
                        {item?.module}
                      </td>
                      <td className="" onClick={() => goToDetail(item)}>
                        {item?.actor}
                      </td>
                      <td
                        className=""
                        style={{
                          wordBreak: 'break-all',
                          minWidth: '100px',
                          maxWidth: '250px',
                          whiteSpace: 'pre-wrap',
                        }}
                        onClick={() => goToDetail(item)}
                      >
                        {item?.idData}
                      </td>
                      <td className="" onClick={() => goToDetail(item)}>
                        {item?.activity}
                      </td>

                      <td className="">
                        <a
                          className="btn btn-light mx-2"
                          style={{ background: '#43936C', color: '#fff' }}
                          onClick={() => goToDetail(item)}
                        >
                          {' '}
                          View Detail
                        </a>
                      </td>
                    </tr>
                  );
                })
              ) : (
                <tr>
                  <td colSpan={8} className="text-center">
                    Data Not Found
                  </td>
                </tr>
              )
            ) : (
              <>
                <tr>
                  <td colSpan={8}>
                    <div className="row" style={{ height: '70px' }}>
                      <div className="shimmerBG"></div>
                    </div>
                  </td>
                </tr>
                <tr>
                  <td colSpan={8}>
                    <div className="row" style={{ height: '70px' }}>
                      <div className="shimmerBG"></div>
                    </div>
                  </td>
                </tr>
              </>
            )}
          </tbody>
        </table>
      </div>

      <div className="row">
        <div className="col-12 col-md-6 col-lg-6">
          <div className="form-group row">
            <label htmlFor="priority" className="col-sm-1 col-form-label">
              Show
            </label>

            <div className="" style={{ width: '80px' }}>
              <select
                name="show"
                value={limit}
                className="custom-select"
                onChange={(e) => changeShow(e)}
              >
                <option value="5">5</option>
                <option value="10" selected>
                  10
                </option>
                <option value="15">15</option>
                <option value="20">20</option>
              </select>
            </div>
            <label htmlFor="priority" className="col-sm-2 col-form-label">
              of{' '}
              <span className="text-decoration-underline fw-bold mx-1">
                {totalItems}
              </span>{' '}
              Entries
            </label>
          </div>
        </div>
        <div
          className="col-12 col-md-6 col-lg-6"
          style={{ justifyContent: 'end', display: 'flex' }}
        >
          {/* <Page currentPage={page} totalPage={listDocumentHistori.totalPages} last={listDocumentHistori.last} first={listDocumentHistori.first} changePage={(page) => changePage(page)}/> */}
          <Pagination
            count={totalPage}
            color="primary"
            page={page}
            showFirstButton
            showLastButton
            onChange={changePage}
          />
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = ({ document }: IRootState) => ({
  documentList: document.documentDashboardList,
  documentListAcknowledge: document.documentListAcknowledge,
  documentListSuratMasuk: document.documentListSuratMasuk,
  documentListSuratKeluar: document.documentListSuratKeluar,
  loading: document.loading,
  totalApproval: document.totalApproval,
  totalSuratMasuk: document.totalSuratMasuk,
  totalAcknowledge: document.totalAcknowledge,
  totalSuratKeluar: document.totalSuratKeluar,
  totalPageApproval: document.totalPageApproval,
  totalPageAcknowledge: document.totalPageAcknowledge,
  totalPageKotakMasuk: document.totalPageKotakMasuk,
  totalPageKotakKeluar: document.totalPageKotakKeluar,
});

const mapDispatchToProps = {
  getSearchEntities,
  getEntities,
  getEntitiesByEmpId,
  getDocumentApproval,
  getDocumentAcknowledge,
  getDocumentSuratKeluar,
  getDocumentSuratMasuk,
};
type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(mapStateToProps, mapDispatchToProps)(Audit);
