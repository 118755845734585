import React, { useState, useEffect, useRef } from 'react';
import { connect } from 'react-redux';
import { Link, Redirect, RouteComponentProps } from 'react-router-dom';
import { Button, InputGroup, Col, Row, Table, Modal, ModalHeader, ModalBody, ModalFooter, Input, FormGroup, Label } from 'reactstrap';
import { AvForm, AvGroup, AvInput } from 'availity-reactstrap-validation';
import { Translate, translate, ICrudSearchAction, ICrudGetAllAction, TextFormat } from 'react-jhipster';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IRootState } from 'app/shared/reducers';
import { getSearchEntities, getEntities, getEntitiesByEmpId,  getDocumentDetail, postDocument, signDocument, approveDocument, viewPDF, rejectDocument} from './../document/document.reducer';
import { IDocument } from 'app/shared/model/document.model';
import { APP_DATE_FORMAT, APP_LOCAL_DATE_FORMAT } from 'app/config/constants';
import SignaturePad from 'react-signature-canvas'
import axios from 'axios';
import { BlobProvider, pdf, PDFViewer } from '@react-pdf/renderer';
import PreviewPDFComponent from 'app/modules/pdf/preview';
import XCircleIcon from 'app/component/x-circle-icon';
import CheckCircleIcon from 'app/component/check-circle-icon';
import dataURLtoBlob from 'blueimp-canvas-to-blob'
import { Document, pdfjs } from 'react-pdf';
import createBrowserHistory from 'history/createBrowserHistory';
import { toast } from 'react-toastify';

const history = createBrowserHistory({forceRefresh:true});

export interface IDocumentProps extends StateProps, DispatchProps, RouteComponentProps<{ url: string }> { }


export interface Props {
  getDocumentDetail?: any,
  match?: any,
  viewPDF?: any,
  detaildocument?: any,
  signDocument?: any,
  approveDocument?: any,
  blobPreview?: any,
  filePDFSign?: any,
  rejectDocument?: any,
  PreviewPDFComponent?: any,
  history?: any
}

export const PreviewAcknowledge: React.FC<Props> = (props) => {
  const [search, setSearch] = useState('');
  const [fileblob, setFileblob] = useState(null);
  const [showModal, setShowModal] = useState(false)
  const [showModalReject, setShowModalReject] = useState(false)
  const [showModalSign, setShowModalSign] = useState(false)
  const [notesApprove, setNotesApprove] = useState('I approved this document')
  const [notesReject, setNotesReject] = useState('')
  const [previewApprove, setPreviewApprove] = useState(false)
  const [typeTransaction, setTypeTransaction] = useState(1) // 1 -> approval / 2 -> reject
  const [urlBlob, setUrlBlob] = useState<any>(null)
  const [listAttachment, setListAttachment] = useState<any>([])

  pdfjs.GlobalWorkerOptions.workerSrc = 
  `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

  const signCanvas = useRef<any>();

    const clear = () => signCanvas.current.clear();
    const save = () => {
    let basse64 = signCanvas.current.getTrimmedCanvas().toDataURL("image/png")  
    console.log(dataURLtoBlob(basse64))
    }
    // const save = () => console.log(signCanvas.getTrimmedCanvas)

  const handleClose = () => {
      setNotesApprove('I approved this document')
      setShowModal(false)
  };

  const handleOpen = () => {
      setShowModal(true)
  }

  const handleCloseReject = () => {
    setNotesReject('')
    setShowModalReject(false)
};

const handleOpenReject = () => {
    setShowModalReject(true)
}


const handleCloseSign = () => {
  setShowModalSign(false)
};

const handleOpenSign = () => {
  setShowModalSign(true)
}
  useEffect(() => {
    // console.log('isian', props.match.params.id)
    // props.getDocumentDetail(props.match.params.id);
    // props.viewPDF(props.match.params.id)

    getFilePDF()
  }, []);


  
  

  const getListAttachment = () => {
    axios.get(`/services/documentservice/api/dms/get_attachment_from_gcp?fileId=${props.match.params.id}`).then(result => {
      console.log(result.data.data)
      setListAttachment(result.data.data.content)
    }).then(err => {
      
    })
  }

  const bytesToSize = (bytes) => {
    var sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB'];
    if (bytes == 0) return '0 Byte';
    var i = Math.floor(Math.log(bytes) / Math.log(1024));
    return Math.round(bytes / Math.pow(1024, i)) + ' ' + sizes[i];
 }


  const b64toBlob = (b64Data, contentType='', sliceSize=512) => {
    const byteCharacters = atob(b64Data);
    const byteArrays = [];
  
    for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
      const slice = byteCharacters.slice(offset, offset + sliceSize);
  
      const byteNumbers = new Array(slice.length);
      for (let i = 0; i < slice.length; i++) {
        byteNumbers[i] = slice.charCodeAt(i);
      }
  
      const byteArray = new Uint8Array(byteNumbers);
      byteArrays.push(byteArray);
    }
  
    const blob = new Blob(byteArrays, {type: contentType});
    return blob;
  }

  const openFilePreview = (base64, filetype) => {
    const blob = b64toBlob(base64, filetype);
    const blobUrl = URL.createObjectURL(blob);

    window.open(blobUrl)
  }



  const getFilePDF = () => {
    axios.get(`/services/documentservice/api/dms/get_document_from_gcp?fileId=${props.match.params.id}`, {responseType:'blob'}).then(res => {
      // window.open(URL.createObjectURL(res.data));

      setUrlBlob(URL.createObjectURL(res.data))
      getListAttachment()
      // var url = new Blob([res.data], { type: 'application/pdf' });
      // var a = document.createElement('a')
      // a.href = url
      // a.download = fileName
      // a.click()
      // a.remove()
    }).catch(err => {
      console.log(err.response)
      toast.error(translate(`${err.response.data.message}`), {
        toastId: err.response.data.message,
        position: "top-left",
        autoClose: 8000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        });
        if(err.response.status == 401 || err.response.status == 404) {
          history.push('/404')
        } 
    })

   
  }

  const renderViewPDF = (id) => {
    props.viewPDF(id)
  }

  const submit = (datadokumen) => {
  if(datadokumen.isSigned == false){
  pdf(<PreviewPDFComponent 
      from={props.detaildocument?.senderBranchName}
      title={props.detaildocument?.fileNumber}
      regarding={props.detaildocument?.regarding}
      date={props.detaildocument?.date}
      to={props.detaildocument?.to}
      fileContent={props.detaildocument?.fileContent}
      cc={props.detaildocument?.cc}
      dataDocument={props.detaildocument}
      acknowledge={props.detaildocument?.acknowledgeList}
    />).toBlob().then(res => {
      console.log(res)
      let basse64 = signCanvas.current.getTrimmedCanvas().toDataURL("image/png")  
      let signFile = dataURLtoBlob(basse64)
      props.signDocument(res, signFile, props.match.params.id)
      setShowModalSign(false)
      
    })
  } else {
    let basse64 = signCanvas.current.getTrimmedCanvas().toDataURL("image/png")  
    let signFile = dataURLtoBlob(basse64)
    props.signDocument(props.blobPreview, signFile, props.match.params.id)
    setShowModalSign(false)
  }
    // props.postDocument(fileblob)
  }

  const submitApprove = (datadokumen) => {
      setPreviewApprove(true)
      setShowModal(false)
  }

  const sendApprove = () => {
    axios.post(`/services/documentservice/api/dms/send-approve?id=${props.match.params.id}&notes=${notesApprove}`).then(res => {
      props.history.push('/')
    }).catch(err => {

    })
  }

    const submitReject = (datadokumen) => {
      // if(datadokumen.isSigned == false){
      // pdf(<PreviewPDFComponent 
      //     from={props.detaildocument?.senderBranchName}
      //     title={props.detaildocument?.fileNumber}
      //     regarding={props.detaildocument?.regarding}
      //     date={props.detaildocument?.date}
      //     to={props.detaildocument?.to}
      //     fileContent={props.detaildocument?.fileContent}
      //     cc={props.detaildocument?.cc}
      //     dataDocument={props.detaildocument}
      //     acknowledge={props.detaildocument?.acknowledgeList}
      //   />).toBlob().then(res => {
      //     console.log('file reject', res)
      //     props.rejectDocument(props.match.params.id, notesReject, res)
      //     setShowModalReject(false)
      //     history.push('/')
      //   })
      //   // props.postDocument(fileblob)
      // } else {
      //   props.rejectDocument(props.match.params.id, notesReject, props.blobPreview)
      //   setShowModalReject(false)
      //   // history.push('/')
      // }

      axios.post(`services/documentservice/api/dms/confirm-reject?id=${props.match.params.id}&notes=${notesReject}`).then(res => {
        console.log(res)
        setShowModalReject(false)
        props.history.push('/')
      }).catch(err => {
        console.log(err)
        setShowModalReject(false)
      })
    }


  let datadetail = props.detaildocument
  // let datafinal = JSON.parse(datadetail)
//  console.log(datafinal)
 console.log('data props',)

 if(props.detaildocument.isSigned == false ){
  return (
    <div className="container">
          <Row>
          <Col>
          <h2>Acknowledge</h2>
           <p>File Preview Acknowledge</p>
          </Col>
        </Row>
       <PDFViewer style={{width: '100%', height: '1000px'}} >
        <PreviewPDFComponent 
          from={props.detaildocument?.senderBranchName}
          title={props.detaildocument?.fileNumber}
          regarding={props.detaildocument?.regarding}
          date={props.detaildocument?.date}
          to={props.detaildocument?.to}
          fileContent={props.detaildocument?.fileContent}
          cc={props.detaildocument?.cc}
          dataDocument={props.detaildocument}
          acknowledge={props.detaildocument?.acknowledgeList}
          notesApprove={notesApprove}
          notesReject={notesReject}
        />
      </PDFViewer>
         <Row className='mb-4 mt-4'>
         <Col className='text-right' xs="12" sm="12" md="6" lg="6">
         
          </Col>
          <Col className='text-right' xs="12" sm="12" md="6" lg="6">
          {/* <button className='btn btn-secondary mr-2 px-4'>Cancel</button> */}
            <button className='btn btn-danger mr-2 px-3' onClick={() => handleOpenReject()}><XCircleIcon className='mr-1'/>Reject</button>
            <button className='btn btn-warning mr-2 px-4' onClick={() => handleOpenSign()}>Sign</button>
            <button className='btn btn-success px-3' onClick={() => handleOpen()} ><CheckCircleIcon className='mr-1'/> Approve</button>
          </Col>
        </Row>

        <Modal isOpen={showModal} toggle={handleClose} size="lg">
      <ModalHeader toggle={handleClose}>
            <h2>Approval Confirmation</h2>
            <span style={{fontSize: '12px'}}>Dokumen Ini Disetujui Dengan Catatan :</span>
      </ModalHeader>
      <ModalBody id="documenttestApp.document.delete.question">
      <span>Notes</span>
      <FormGroup>
        <Input
          rows={5}
          style={{resize: 'none'}}
          id="exampleText"
          name="text"
          type="textarea"
          maxLength={255}
          value={notesApprove}
          onChange={(e) => setNotesApprove(e.target.value)}
        />
      </FormGroup>
      </ModalBody>
      <ModalFooter>
       <Row className='w-100'>
         <Col lg={6}>
         {/* <FormGroup>
            <Input
              id="exampleText"
              name="text"
              type="password"
             
            />
          </FormGroup> */}
         </Col>
         <Col lg={6} className="text-right">
          <Button color="secondary" onClick={handleClose} className="mr-2">
            <Translate contentKey="entity.action.cancel">Cancel</Translate>
          </Button>
          <Button  color="success" onClick={() => submitApprove(props.detaildocument)}>
              Confirm Approve
          </Button>
         </Col>
       </Row>
      </ModalFooter>
    </Modal>


    <Modal isOpen={showModalReject} toggle={handleCloseReject} size="lg">
      <ModalHeader toggle={handleCloseReject}>
            <h2>Reject Confirmation</h2>
            <span style={{fontSize: '12px'}}>Dokumen Ini Ditolak Dengan Alasan :</span>
      </ModalHeader>
      <ModalBody id="documenttestApp.document.delete.question">
      <FormGroup>
        <Input
          rows={5}
          style={{resize: 'none'}}
          id="exampleText"
          name="text"
          type="textarea"
          maxLength={255}
          value={notesReject}
          onChange={(e) => setNotesReject(e.target.value)}
        />
      </FormGroup>
      </ModalBody>
      <ModalFooter>
       <Row className='w-100'>
         <Col lg={6}>
         {/* <FormGroup>
            <Input
              id="exampleText"
              name="text"
              type="password"
            />
          </FormGroup> */}
         </Col>
         <Col lg={6} className="text-right">
          <Button color="secondary" onClick={handleCloseReject} className="mr-2">
              Cancel
          </Button>
          <Button  color="danger" onClick={() => submitReject(props.detaildocument)} disabled={!notesReject}>
              Confirm Reject
          </Button>
         </Col>
       </Row>
      </ModalFooter>
    </Modal>


    <Modal isOpen={showModalSign} toggle={handleCloseSign} size="sm">
      <ModalHeader toggle={handleCloseSign}>
            <h2>Sign Confirmation</h2>
            <span style={{fontSize: '12px'}}>Lorem ipsum dolor sit amet consectetur, adipisicing elit</span>
      </ModalHeader>
      <ModalBody id="documenttestApp.document.delete.question" style={{backgroundColor: '#fff', justifyContent: 'center', display: 'flex'}}>
        <div style={{backgroundColor: '#bdbdbd', width: 150, height: 100}}>
          <SignaturePad
          ref={signCanvas}
          canvasProps={{ className: "signature__canvas", width: 150, height: 100, backgroundColor: '#bdbdbd' }}
          />
        </div>
      </ModalBody>
      <ModalFooter>
    
       <Row className='w-100'>
         <Col lg={12}>
         {/* <FormGroup>
            <Input
              id="exampleText"
              name="text"
              type="password"
            />
          </FormGroup> */}
         </Col>
         <Col lg={12} className="text-right">
          <Button color="secondary" onClick={handleCloseSign} className="mr-2 w-100 my-2">
            <Translate contentKey="entity.action.cancel">Cancel</Translate>
          </Button>
          <Button color="secondary" onClick={clear} className="mr-2 w-100 my-2">
            Clear
          </Button>
          <Button  color="warning" onClick={() => submit(props.detaildocument)} className="w-100 my-2">
              Confirm Sign
          </Button>
         </Col>
       </Row>
      </ModalFooter>
    </Modal>
        </div>
  )
 } else {
   return (
    <div className="container">
        <Row>
          <Col>
          <h2>Preview Document</h2>
           <p>File Preview Document</p>
          </Col>
        </Row>
        {urlBlob ?   <iframe width="100%" src={urlBlob} style={{width: '100%', height: '1000px'}} /> : <div className="shine photo"></div>}
        <div className="bg-white rounded shadow my-4 px-4 py-4"> 
                <h5>
                  Attachment
                </h5>

        {
                  listAttachment.length > 0 ? listAttachment.map((item, index) => {
                    return ( <div onClick={() => openFilePreview(item.file?.replace(/^[^,]+,/, ''), item.fileContentType)}>
                      <div >
                            <div className="py-2 pl-3 pr-2 rounded shadow my-2 d-inline-block" style={{background: '#F5F5F5', color: '#3267E3', alignContent: 'c'}} key={`file-${index}`}>
                              <div style={{display: 'flex', alignItems: 'center'}}>
                                <span>
                                    {item.fileName} ( {bytesToSize(item.fileSize)} )
                                </span>
                               
                              </div>
                            </div>
                      </div>
                    </div>
                    )
                  }) : <>

                  </>
                }
                </div>
      <Row className='mb-4 mt-4'>
          <Col>
         
          </Col>
          {previewApprove ?  <Col className='text-right'>
            {/* <button className='btn btn-secondary mr-2 px-4'>Cancel</button>
            <button className='btn btn-primary px-3' onClick={() => sendApprove()} > Send</button> */}
          </Col>:  <Col className='text-right'>
            {/* <button className='btn btn-secondary mr-2 px-4'>Cancel</button> */}
            <button className='btn btn-light mr-2 px-3' style={{border: '1px solid #000'}} onClick={() => props.history.goBack()}>Back</button>
            {/* <button className='btn btn-warning mr-2 px-4' onClick={() => handleOpenSign()}>Sign</button> */}
            {/* <button className='btn btn-success px-3' onClick={() => handleOpen()} ><CheckCircleIcon className='mr-1'/> Approve</button> */}
          </Col>}
        </Row>
    
      
    

    <Modal isOpen={showModal} toggle={handleClose} size="lg">
      <ModalHeader toggle={handleClose}>
            <h2>Approval Confirmation</h2>
            <span style={{fontSize: '12px'}}>Dokumen Ini Disetujui Dengan Catatan : </span>
      </ModalHeader>
      <ModalBody id="documenttestApp.document.delete.question">
      <span>Notes</span>
      <FormGroup>
       
        <Input
          rows={5}
          style={{resize: 'none'}}
          id="exampleText"
          name="text"
          type="textarea"
          value={notesApprove}
          maxLength={255}
          onChange={(e) => setNotesApprove(e.target.value)}
        />
      </FormGroup>
      </ModalBody>
      <ModalFooter>
       <Row className='w-100'>
         <Col lg={6}>
         {/* <FormGroup>
            <Input
              id="exampleText"
              name="text"
              type="password"
             
            />
          </FormGroup> */}
         </Col>
         <Col lg={6} className="text-right">
          <Button color="secondary" onClick={handleClose} className="mr-2">
            <Translate contentKey="entity.action.cancel">Cancel</Translate>
          </Button>
          <Button  color="success" onClick={() => submitApprove(props.detaildocument)}>
              Confirm Approve
          </Button>
         </Col>
       </Row>
      </ModalFooter>
    </Modal>


    <Modal isOpen={showModalReject} toggle={handleCloseReject} size="lg">
      <ModalHeader toggle={handleCloseReject}>
            <h2>Reject Confirmation</h2>
            <span style={{fontSize: '12px'}}>Dokumen Ini Ditolak Dengan Alasan :</span>
      </ModalHeader>
      <ModalBody id="documenttestApp.document.delete.question">
      <FormGroup>
        <Input
          rows={5}
          style={{resize: 'none'}}
          id="exampleText"
          name="text"
          type="textarea"
          maxLength={255}
          value={notesReject}
          onChange={(e) => setNotesReject(e.target.value)}
        />
      </FormGroup>
      </ModalBody>
      <ModalFooter>
       <Row className='w-100'>
         <Col lg={6}>
         {/* <FormGroup>
            <Input
              id="exampleText"
              name="text"
              type="password"
            />
          </FormGroup> */}
         </Col>
         <Col lg={6} className="text-right">
          <Button color="secondary" onClick={handleClose} className="mr-2">
            <Translate contentKey="entity.action.cancel">Cancel</Translate>
          </Button>
          <Button  color="danger" onClick={() => submitReject(props.detaildocument)} disabled={!notesReject}>
              Confirm Reject
          </Button>
         </Col>
       </Row>
      </ModalFooter>
    </Modal>


    <Modal isOpen={showModalSign} toggle={handleCloseSign} size="sm">
      <ModalHeader toggle={handleCloseSign}>
            <h2>Sign Confirmation</h2>
            <span style={{fontSize: '12px'}}>Lorem ipsum dolor sit amet consectetur, adipisicing elit</span>
      </ModalHeader>
      <ModalBody id="documenttestApp.document.delete.question" style={{backgroundColor: '#fff', justifyContent: 'center', display: 'flex'}}>
        <div style={{backgroundColor: '#bdbdbd', width: 150, height: 100}}>
          <SignaturePad
          ref={signCanvas}
          canvasProps={{ className: "signature__canvas", width: 150, height: 100, backgroundColor: '#bdbdbd' }}
          />
        </div>
      </ModalBody>
      <ModalFooter>
    
       <Row className='w-100'>
         <Col lg={12}>
         {/* <FormGroup>
            <Input
              id="exampleText"
              name="text"
              type="password"
            />
          </FormGroup> */}
         </Col>
         <Col lg={12} className="text-right">
          <Button color="secondary" onClick={handleCloseSign} className="mr-2 w-100 my-2">
            <Translate contentKey="entity.action.cancel">Cancel</Translate>
          </Button>
          <Button color="secondary" onClick={clear} className="mr-2 w-100 my-2">
            Clear
          </Button>
          <Button  color="warning" onClick={() => submit(props.detaildocument)} className="w-100 my-2">
              Confirm Sign
          </Button>
         </Col>
       </Row>
      </ModalFooter>
    </Modal>
    </div>
  );
   }
};

const mapStateToProps = ({ document }: IRootState) => ({
  documentList: document.entities,
  loading: document.loading,
  detaildocument: document.detaildocument,
  filePDFSign: document.filePDFsign,
  acknowledgelist: document.acknowledgelist,
  fileUrl: document.fileUrlBlob,
  blobPreview: document.fileBlobPreview
});

const mapDispatchToProps = {
  getSearchEntities,
  getEntities,
  getEntitiesByEmpId,
  getDocumentDetail,
  postDocument,
  signDocument,
  approveDocument,
  viewPDF,
  rejectDocument
};

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(mapStateToProps, mapDispatchToProps)(PreviewAcknowledge);
