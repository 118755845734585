import '../../../../../../scss/bootstrap.min.scss'
import '../../../../../../scss/app.min.scss'
import './notification.scss';
import { renderToString } from "react-dom/server";
import { render } from "react-dom";
import tableAppr from 'app/modules/home/table'
import { Listbox, Transition } from '@headlessui/react'
// import { CheckIcon, SelectorIcon } from '@heroicons/react/solid'
import { PDFViewer } from '@react-pdf/renderer';
import Pagination from '@mui/material/Pagination';
const list = [
  { value: 2 },
  { value: 5 },
  { value: 10 },
  { value: 15 },
  { value: 20 },
  { value: 25 },
]

import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";


import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from 'react-select';

import React, { useEffect, useState, Fragment } from 'react';
import { Link, RouteComponentProps } from 'react-router-dom';
import { Translate } from 'react-jhipster';
import { connect } from 'react-redux';
import { CardGroup, Row, Col, Alert, Tooltip, Modal, ModalHeader, ModalBody, FormGroup, Input, ModalFooter, Button, Spinner } from 'reactstrap';

import { IRootState } from 'app/shared/reducers';


import SideBar from 'app/shared/layout/sidebar/sidebar';
import Approval from 'app/shared/layout/card/approval';
import HandshakeIcon from 'app/component/handshake-icon';
import EyeIcon from 'app/component/eye-icon';
import EnvelopeIcon from 'app/component/envelope-icon';
import PaperPlaneIcon from 'app/component/paperplane-icon';
import InfoIcon from 'app/component/InfoIcon';

import { getSearchEntities, getEntities, getEntitiesByEmpId, getDocumentApproval, getDocumentAcknowledge, getDocumentSuratKeluar, getDocumentSuratMasuk } from '../document/document.reducer';
import { useHistory } from "react-router-dom";
import axios from 'axios'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { toast } from 'react-toastify';
import DatePicker from "react-datepicker";

import "react-datepicker/dist/react-datepicker.css";
import { error } from 'console';
import moment from 'moment';

export interface IHeaderProps {
  isAuthenticated?: boolean;
  isAdmin?: boolean;
  ribbonEnv?: string;
  isInProduction?: boolean;
  isSwaggerEnabled?: boolean;
  currentLocale?: string;
  onLocaleChange?: Function;
  account?: any,
  logout?: any,
  location?: any
}




export const KaryawanAddManual = (props: IHeaderProps) => {
  let history = useHistory();
  const [loading, setLoading] = useState(false)
  const [page, setPage] = useState(1)
  const [totalPage, setTotalPage] = useState(1)
  const [size, setSize] = useState<any>(10)
  const [sortType, setSortType] = useState('typeName.keyword,asc')
  const [sortPriority, setSortPriority] = useState('priority.keyword,asc')
  const [sortLastModified, setSortLastModified] = useState('lastApprovedByName.keyword,asc')
  const [sortDate, setSortDate] = useState('date,asc')
  const [files, setFiles] = React.useState(null);
  const [urlImage, setUrlImage] = useState<any>(null)
  const [startDate, setStartDate] = useState(new Date());
  const [listDepartment, setListDepartment] = useState<any>([])
  

  const LoginSchema = Yup.object().shape({
    nik: Yup.string()
    .required("NIK is required"),
    position: Yup.string()
    .required("Position is required"),
    phonenumber: Yup.string()
    .required("Phone number is required")
    .matches(/^\d+$/, 'The field should have number only'),
    // group: Yup.string()
    // .required("Group is required"),
    date: Yup.string()
    .required("Date is required"),
    email: Yup.string().email()
    .required("Email is required"),
    fullname: Yup.string()
      .required("Fullname is required")
      .matches(/^(?!\s+$).*/, "This field cannot contain only blankspaces"),
      
  });


  const onDropHandler = (ev) => {
    ev.preventDefault();


    console.log(ev)
    let file = null;
    if (ev.dataTransfer.items) {
      // Use DataTransferItemList interface to access the file(s)
      file =
        [...ev.dataTransfer.items]
          .find((item: any) => item.kind === "file")
          .getAsFile() ;
    } else {
      // Use DataTransfer interface to access the file(s)
      file = ev.dataTransfer.files[0];
    }

    console.log('files',file)
    setFiles(file);
    setUrlImage(URL.createObjectURL(file))
  };

  const onDragOver = (ev) => ev.preventDefault();

  const renderStatusFileHistories = (status, statusName) => {
    if (status == "CREATED" || status == "REVISE") {
      return <div style={{background: '#FFF4EB', border: '1px solid #F37F26',  borderRadius: '6px'}} className="px-2 py-1 font-weight-bold  mt-3 text-mirae w-50 text-center">{statusName}</div>
    } else if(status == 'APPROVE' || status == 'APPROVE_REVISED_VERSION' || status == 'WAITING_FOR_APPROVAL') {
       return <div style={{color: '#20573D', background: '#F7F7F7', border: '1px solid #B8DBCA',  borderRadius: '6px'}} className="px-2 py-1 font-weight-bold  mt-3 w-50 text-center">{statusName}</div>
    } else {
      return <div style={{color: '#CB3A31', background: '#FFF4F2', border: '1px solid #EEB4B0', borderRadius: '6px'}} className="px-2 py-1  font-weight-bold mt-3 w-50 text-center">{statusName}</div>
    }
}


  useEffect(() => {
    // alert('data search', props.location.state.search)
    // alert(props.location.state.search)
    
    getListNotification()
  
  }, [page, size, totalPage]);



 

  


  useEffect(() => {
   
      getDepartmentList()
  }, []);

 

  const getListNotification = () => {
    
    axios.get(`services/documentservice/api/notification/get-list?page=${page}&size=${size}&sort=createdDate,desc`).then((res) => {
        console.log('notif', res.data.data.content)
       
          setTotalPage(res.data.data.totalPages)
         
    }).catch((err) => {
      

    })
  }



  const delayTime = (ms) => {
    return new Promise(
      resolve => setTimeout(resolve, ms)
    )
  }

  
 


  const getDepartmentList = () => {
    let groupPayload = {
      page: 0,
      limit: 1000,
      keyword: ""
  }
    axios.post('services/uaadocservice/api/group/list', groupPayload).then(res => {
      console.log('dept', res.data)
      setListDepartment(res.data.data.groups)
    }).catch(err => {
      console.log(err)
    })
  }

  const customStyles = {
    control: base => ({
      ...base,
      height: 54,
      minHeight: 54
    })
  };

  
const submitDataKaryawan = (data) => {
  console.log('submit', data)
  // setLoading(true)
  let payload = {
    employeeNo: data.nik,
    fullName: data.fullname,
    phoneNumber: data.phonenumber,
    email: data.email,
    joinDate: moment(data.date).format('YYYY-MM-DD'),
    // departmentId: parseInt(data.group.value),
    position: data.position
  }
  axios.post('services/uaadocservice/api/personal-identity/karyawan/add', payload).then(res => {
    // console.log(res.data.status)
    toast.success('Success Add Employee', {
      position: "top-left",
      autoClose: 8000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      });
      history.push('/karyawan')
  }).catch(err => {
    toast.error('Failed Add Employee', {
      position: "top-left",
      autoClose: 8000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      });
  })
}

  


  const handleChangePage = (event: React.ChangeEvent<unknown>, value: number) => {
    setPage(value);
  };

  const renderUnread = (data) => {
    if(!data.isRead) {
      return <span style={{background: '#FFF4EB', border: '1px solid #F37F26',  borderRadius: '6px'}} className="px-2 py-1 font-weight-bold mt-3 text-mirae text-center float-right">New</span>
    } else {
      return <></>
    }
  }
  
 


 
  return (
    <div className="px-5 pt-4" style={{height: '100%'}}>


      <button className="btn px-0 mb-4" onClick={() => history.goBack()}>
      <FontAwesomeIcon size="2x" icon="arrow-left" color="#0A0A0A" />{' '}
      </button>
    <div className="row">
      <div className="col-12">
        <div className="page-title-box d-flex align-items-center justify-content-between">
          <h1 className="mb-0 font-size-28">Tambah Data Karyawan</h1>
        </div>
        <div className="page-title-box d-flex align-items-center justify-content-between">
          {/* <h1 className="mb-0 font-size-14">Lorem ipsum dolor sit amet, consectetur adipiscing elit</h1> */}
        </div>
      </div>
    </div>

    <div className="row">
       
        <Formik
              initialValues={{ nik: "", fullname: "", date: "", email: "", phonenumber: "", position: ""}}
              validationSchema={LoginSchema}
              onSubmit={(values) => {
                console.log(values);
                // alert("Form is validated! Submitting the form...");
                submitDataKaryawan(values)
              }}
            >
              {({ touched, errors, isSubmitting, values, setFieldValue}) =>
                (
                  <div>
                    <Form>
                     <div className="card bg-white px-5 py-5">
                     <div className="form-group">
                        <h5 className="text-xl">Nomor Identitas Karyawan (NIK)</h5>
                        <Field
                          type="text"
                          name="nik"
                          placeholder=""
                          autocomplete="off"
                          className={`mt-2 form-control py-4
                          ${touched.nik && errors.nik ? "is-invalid" : ""}`}
                        />
  
                        <ErrorMessage
                          component="div"
                          name="nik"
                          className="invalid-feedback"
                        />
                      </div>
  
                      <div className="form-group">
                        <h5 className="text-xl">Full name</h5>
                        <Field
                          type="text"
                          name="fullname"
                          placeholder=""
                          autocomplete="off"
                          className={`mt-2 form-control py-4
                          ${touched.fullname && errors.fullname ? "is-invalid" : ""}`}
                        />
  
                        <ErrorMessage
                          component="div"
                          name="fullname"
                          className="invalid-feedback"
                        />
                      </div>


                      <div className="form-group">
                        <h5 className="text-xl">Email</h5>
                        <Field
                          type="email"
                          name="email"
                          placeholder=""
                          autocomplete="off"
                          className={`mt-2 form-control py-4
                          ${touched.email && errors.email ? "is-invalid" : ""}`}
                        />
  
                        <ErrorMessage
                          component="div"
                          name="email"
                          className="invalid-feedback"
                        />
                      </div>


                      <div className="form-group">
                        <h5 className="text-xl">Phone Number</h5>
                        <Field
                          type="text"
                          name="phonenumber"
                          placeholder=""
                          autocomplete="off"
                          className={`mt-2 form-control py-4
                          ${touched.phonenumber && errors.phonenumber? "is-invalid" : ""}`}
                        />
  
                        <ErrorMessage
                          component="div"
                          name="phonenumber"
                          className="invalid-feedback"
                        />
                      </div>

                      <div className="form-group">
                        <h5 className="text-xl">Position</h5>
                        <Field
                          type="text"
                          name="position"
                          placeholder=""
                          autocomplete="off"
                          className={`mt-2 form-control py-4
                          ${touched.position && errors.position ? "is-invalid" : ""}`}
                        />
  
                        <ErrorMessage
                          component="div"
                          name="position"
                          className="invalid-feedback"
                        />
                      </div>
                      

                      <div className="form-group mb-3 px-0">
                        <h5 className="text-xl">Join Date</h5>
                         <DatePicker 
                         name="date"
                         selected={values.date} 
                         onChange={(date:Date) => setFieldValue('date', date)} 
                         className={`mt-2 form-control py-4 px-0 mx-0
                         ${touched.date && errors.date ? "is-invalid" : ""}`}
                         />
                        <div className="text-danger mt-3" style={{fontSize: '10px'}}>
                           {touched.date && errors.date ? errors.date : ""}
                        </div>
                       
                      </div>
                      

                      {/* <div className="form-group">
                        <h5 className="text-xl">Group</h5>
                        <Select
                          name="group"
                          className={`
                          ${touched.nik && errors.nik ? "is-invalid" : ""}`}
                          styles={customStyles}
                          placeholder="Find Name Or Division"
                          options={listDepartment.map(e => ({ label: e.groupName, value: e.id}))}
                          onChange={newValue => setFieldValue('group', newValue)}
                        />
                       <div className="text-danger " style={{fontSize: '10px'}}>
                           {touched.group && errors.group ? errors.group : ""}
                        </div>
                      </div> */}



                    
                      </div>
                   
                      
                        <Row className='mb-4 mt-4'>
                              <Col className='text-right' xs="12" sm="12" md="6" lg="6">
                              </Col>
                              <Col className='text-right' xs="12" sm="12" md="6" lg="6">
                              <button className='btn btn-primary px-5 mr-2' style={{background: '#002F48', borderColor: '#002F48'}} onClick={() => history.goBack()}>
                                  {loading ? <Spinner color='#fff' size="sm" className='mr-1' /> : <></>}
                                  Back </button>
                                <button className='btn btn-primary px-5' type="submit">
                                  {loading ? <Spinner color='#fff' size="sm" className='mr-1' /> : <></>}
                                  Simpan </button>
                              </Col>
                        </Row>
                    </Form>
                 

                  </div>
                )
              }
            </Formik>
        
    </div>


    

  

    
    </div>
  );
};

const mapStateToProps = ({ document }: IRootState) => ({
 
});


const mapDispatchToProps = {
 
};
type StateProps = ReturnType<typeof mapStateToProps>
type DispatchProps = typeof mapDispatchToProps;;

export default connect(mapStateToProps, mapDispatchToProps)(KaryawanAddManual);
