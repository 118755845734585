


export const getPreviewHTML = (data) => {
    console.log('fungsi', data) 
    let listSignature: any[] = [{}]
    let listSignatureAck: any[] = [{}]
    let acknowledgeList = data?.acknowledgeList;
    let approvalList = data?.approval;


    // Sender Data  
    listSignature[0].employeeName = data?.senderEmployeeName
    listSignature[0].positionName = data?.senderPositionName
    listSignature[0].employeeNo = data?.senderEmployeeNo
    listSignature[0].title = "Prepared By"   

    // Acknowledge Data
    acknowledgeList.map((datadata, index) => {
        datadata['title'] = 'Acknowledged By'
      })

    // Approval Data
    approvalList.map((datadata1, index) => {
        datadata1['title'] = 'Approved By'
    })

    // Acknowledge Data
    let arrayfinal : any[] = listSignature.concat(approvalList)
    // arrayfinal = arrayfinal.concat(acknowledgeList)
    

    console.log('array final', arrayfinal)
    if(arrayfinal?.length == 2) {
        arrayfinal.push({})
      } else if (arrayfinal.length == 1) {
        arrayfinal.push({})
        arrayfinal.push({})
      }

      if(3 - (arrayfinal.length%3) == 2){
        arrayfinal.push({})
        arrayfinal.push({})
      } else if(3 - (arrayfinal.length%3) == 1) {
        arrayfinal.push({})
      }

      if(acknowledgeList?.length == 2) {
        acknowledgeList.push({})
      } else if (acknowledgeList.length == 1) {
        acknowledgeList.push({})
        acknowledgeList.push({})
      }

      if(3 - (acknowledgeList.length%3) == 2){
        acknowledgeList.push({})
        acknowledgeList.push({})
      } else if(3 - (arrayfinal.length%3) == 1) {
        acknowledgeList.push({})
      }

      let arraychunk = chunks(arrayfinal, 3)
      let arraychunkacl = chunks(acknowledgeList, 3)

      console.log('array jadi', arraychunk)
      console.log('array jadi ack', arraychunkacl)

      return htmlTemplate(data, arraychunk, arraychunkacl)
}

const chunks = (array, size) => {
    var results = [];
    while (array.length) {
      results.push(array.splice(0, size));
    }
    return results;
};


const setDataTo = (dataTo) => {
    console.log('datato', dataTo)
    let datastring = ''
    dataTo.map((item, index)=> {
        console.log(datastring.search(item.branchName))
        if(datastring.search(item.branchName) == -1){
            if(dataTo.length == index + 1) {
                 datastring = datastring + item.branchName
            } else {
                 datastring = datastring + item.branchName + ', '
            }
        } else {
          // datastring = datastring + item.branchName + ', '
        }
    })
    console.log(datastring)
    return datastring
}

const signatureHtml = (arraychunk) => {

    let htmltd = ''
    arraychunk.map((item, index) => {
        if(item.length == 3 ) {
          htmltd = htmltd + `
          <tr style="display: flex;
          flex-wrap: wrap;
          justify-content: center;">
           <td style="border: 1px solid #bdbdbd; padding: 10px; font-size: 12px; width: 150px; overflow: hidden;display: inline-block;">
           
           <div style="text-align: center">
           ${item[0].title}
           </div>
            <div style="height: 50px; padding-top: 40px">
           
            </div>
        <span style="color: rgba(240,240,240,0.1); font-size: 1px">@${item[0]?.employeeNo}</span><br><br>
     
        <div style="text-align: center">
        [ ${item[0]?.employeeName} ] <br>
  
        <span style="font-size: 10px">${item[0]?.positionName}</span><br>
        </div>
       
       
        </div>
            <span style="font-size: 10px"></span>
          </td>
            ${ item[1].employeeNo != null ? `<td style="border: 1px solid #bdbdbd; padding: 10px; font-size: 12px; width: 150px; overflow: hidden;display: inline-block;">
           
            <div style="text-align: center">
           ${item[1].title}
            </div>
             <div style="height: 50px; padding-top: 40px">
            
         </div>
         <span style="color: rgba(240,240,240,0.1); font-size: 1px">@${item[1]?.employeeNo}</span><br><br>
      
         <div style="text-align: center">
         [ ${item[1]?.employeeName} ] <br>
   
         <span style="font-size: 10px">${item[1]?.positionName}</span><br>
         </div>
        
         </div>
             <span style="font-size: 10px"></span>
           </td>` :  `<td style="border: 1px solid #fff; height: 160px; padding: 10px; font-size: 12px; width: 150px; overflow: hidden;display: inline-block;">
            
            <div style="height: 130px">
    
            </div>
             
       
             
            </td>` }
           ${ item[2].employeeNo != null ? `<td style="border: 1px solid #bdbdbd; padding: 10px; font-size: 12px; width: 150px; overflow: hidden;display: inline-block; ">
           
           <div style="text-align: center">
           ${item[2].title}
           </div>
            <div style="height: 50px; padding-top: 40px">
           
        </div>
        <span style="color: rgba(240,240,240,0.1); font-size: 1px">@${item[2]?.employeeNo}</span><br><br>
     
        <div style="text-align: center">
        [ ${item[2]?.employeeName} ] <br>
  
        <span style="font-size: 10px">${item[2]?.positionName}</span><br>
        </div>
        
        </div>
            <span style="font-size: 10px"></span>
          </td>` :  `<td style="border: 1px solid #fff; height: 160px; padding: 10px; font-size: 12px; width: 150px; overflow: hidden;display: inline-block;">
           
           <div style="height: 80px">
   
           </div>
            
           
            
           </td>` }
            </tr>
        `
        } else if (item.length == 2) {
          htmltd = htmltd + `
          <tr style="display: flex;
          flex-wrap: wrap;
          justify-content: center;">
            <td style="border: 1px solid #bdbdbd; padding: 10px; font-size: 12px; width: 150px; overflow: hidden;display: inline-block;">
              <div style="text-align: center">
                 ${index == 0 ? 'Prepared By' : item[0].title}
              </div>

              <div style="height: 50px; padding-top: 40px; ">

              </div>

              <span style="color: rgba(240,240,240,0.1); font-size: 1px">@${item[0]?.employeeNo}</span><br><br>
                  <div style="text-align: center">
                    [ ${item[0]?.employeeName} ] <br>
                  
                    <span style="font-size: 10px">${item[0]?.positionName}</span>
                  </div>
                  
            </td>
           <td style="border: 1px solid #bdbdbd; padding: 10px; font-size: 12px; width: 150px; overflow: hidden;display: inline-block;">
                <div style="text-align: center">
                ${item[1].title}
                  </div>
                  <div style="height: 50px; padding-top: 40px">
                
              </div>
              <span style="color: rgba(240,240,240,0.1); font-size: 1px">@${item[1]?.employeeNo}</span><br><br>
              <div style="text-align: center">
              [ ${item[1]?.employeeName} ] <br>
        
              <span style="font-size: 10px">${item[1]?.positionName}</span><br>
              </div>
              
              </div>
                  <span style="font-size: 10px"></span>
            </td>
            
            </tr>
        `
        }  else if (item.length == 1) {
          htmltd = htmltd + `
          <tr style="display: flex;
          flex-wrap: wrap;
          justify-content: center;">
          <td style="border: 1px solid #bdbdbd; padding: 10px; font-size: 12px; width: 150px; overflow: hidden;display: inline-block;">
          <div style="text-align: center">
             ${index == 0 ? 'Prepared By' : item[0].title}
          </div>

          <div style="height: 50px; padding-top: 40px; ">

          </div>

          <span style="color: rgba(240,240,240,0.1); font-size: 1px">@${item[0]?.employeeNo}</span><br><br>
              <div style="text-align: center">
                [ ${item[0]?.employeeName} ] <br>
              
                <span style="font-size: 10px">${item[0]?.positionName}</span>
              </div>
              
        </td>
            
            </tr>
        `
        }
      })
      
      return htmltd
}


const htmlTemplate = (data, signature, signatureack) => {
    return `<!DOCTYPE html>
    <html lang="en">
    <head>
        <meta charset="UTF-8">
        <meta http-equiv="X-UA-Compatible" content="IE=edge">
        <meta name="viewport" content="width=device-width, initial-scale=1.0">
        <title>Document</title>
        <link rel="preconnect" href="https://fonts.googleapis.com">
        <link rel="preconnect" href="https://fonts.gstatic.com" crossorigin>
        <link href="https://fonts.googleapis.com/css2?family=Noto+Sans:ital,wght@0,400;0,700;1,400;1,700&display=swap" rel="stylesheet">
        <style>
            /* http://meyerweb.com/eric/tools/css/reset/ 
       v2.0 | 20110126
       License: none (public domain)
    */
        * {
          font-family: 'Noto Sans', sans-serif;
        }
        
       
        
        .hyphens {
          hyphens: auto;
          }
        </style>
    </head>
        <body>
            <div style="">
              
    
                <div style="display: flex; flex-direction: row; padding-left: 2%; padding-right: 2%;">
                    <div style="flex: 50%; align-self: flex-end;">
                       
                        <span style="font-size: 24px; color: #000; font-weight: bold; ">INTERNAL MEMO</span>
                    </div>
                </div>
    
                <div style="margin-left: 2%; margin-right: 2%; border-top: 1.5px solid black; border-bottom: 1.5px solid black;margin-top: 1%;">
                    <table style="width: 100%;  border-collapse:separate; 
                    border-spacing: 0 0em; ">
                        <tr style="border-bottom: 1px solid gray;"> 
                            <td style="width: 10%;  border-bottom: 1px solid #bdbdbd; font-size: 10pt">No. IMO</td>
                            <td style="width: 2%; border-bottom: 1px solid #bdbdbd;font-size: 10pt">:</td>
                            <td style=" border-bottom: 1px solid #bdbdbd; font-size: 10pt">${data?.fileNumber}</td>
                            <td style=" width: 10%;border-bottom: 1px solid #bdbdbd; font-size: 10pt">Tanggal</td>
                            <td style=" width: 2%;border-bottom: 1px solid #bdbdbd; font-size: 10pt">:</td>
                            <td style=" border-bottom: 1px solid #bdbdbd; font-size: 10pt">${data?.dateFormat}</td>
                        </tr>
    
                        <tr style="border-bottom: 1px solid gray; "> 
                            <td style="width: 12%; border-bottom: 1px solid #bdbdbd; font-size: 10pt">Dari</td>
                            <td style=" width: 3%; border-bottom: 1px solid #bdbdbd; font-size: 10pt">:</td>
                            <td style=" border-bottom: 1px solid #bdbdbd; font-size: 10pt" colspan="4">${data?.senderBranchName}</td>
                            
                        </tr>
                        <tr style="border-bottom: 1px solid gray;"> 
                            <td style="width: 12%; border-bottom: 1px solid #bdbdbd; font-size: 10pt">Kepada</td>
                            <td style=" width: 3%; border-bottom: 1px solid #bdbdbd; font-size: 10pt">:</td>
                            <td style=" border-bottom: 1px solid #bdbdbd; font-size: 10pt" colspan="4">${setDataTo(data?.to)}</td>
                            
                        </tr>
    
                        <tr style="border-bottom: 1px solid gray;"> 
                            <td style=" width: 12%; border-bottom: 1px solid #bdbdbd; font-size: 10pt">Tembusan</td>
                            <td style=" width: 3%; border-bottom: 1px solid #bdbdbd; font-size: 10pt">:</td>
                            <td style=" border-bottom: 1px solid #bdbdbd; font-size: 10pt" colspan="4">${setDataTo(data?.cc)}</td>
                            
                        </tr>

                        <tr style=""> 
                            <td style=" width: 12%; font-size: 10pt">Perihal</td>
                            <td style="width: 3%; font-size: 10pt">:</td>
                            <td style="font-size: 10pt" colspan="4">${data?.regarding}</td>
                            
                        </tr>
                    </table>
                </div>
    
               
         
                <!-- content -->
                <div lang="en" style="margin-left: 2%; margin-right: 2%; margin-top: 1%; padding-top: 1%; hyphens: auto; -webkit-hyphens: auto;
                -moz-hyphens: auto; overflow-wrap: anywhere; word-break: break-all">
                    ${data?.fileContent}
  
                </div>
    
                <div>&nbsp;</div>


                @signature
                
             

              
              
        </body>
        </html>`
}

// let string = ''

// dari db looping

`<tr>
<td>
  {dbuser}
</td>
<td>
  :
</td>
<td>
 {dbnotes}
</td>
</tr> ` + ` <tr>
<td>
  namaUser
</td>
<td>
  :
</td>
<td>
  Notes
</td>
</tr>`
