import React, { useState, useEffect, useRef } from 'react';
import { connect } from 'react-redux';
import { Link, Redirect, RouteComponentProps } from 'react-router-dom';
import { Button, InputGroup, Col, Row, Table, Modal, ModalHeader, ModalBody, ModalFooter, Input, FormGroup, Label, Spinner } from 'reactstrap';
import { AvForm, AvGroup, AvInput } from 'availity-reactstrap-validation';
import { Translate, translate, ICrudSearchAction, ICrudGetAllAction, TextFormat } from 'react-jhipster';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IRootState } from 'app/shared/reducers';
import { getSearchEntities, getEntities, getEntitiesByEmpId, getDocumentDetail, postDocument, signDocument, approveDocument, viewPDF, rejectDocument } from './../document/document.reducer';
import { IDocument } from 'app/shared/model/document.model';
import { APP_DATE_FORMAT, APP_LOCAL_DATE_FORMAT } from 'app/config/constants';
import SignaturePad from 'react-signature-canvas'
import axios from 'axios';
import { BlobProvider, pdf, PDFViewer } from '@react-pdf/renderer';
import PreviewPDFComponent from 'app/modules/pdf/preview';
import XCircleIcon from 'app/component/x-circle-icon';
import CheckCircleIcon from 'app/component/check-circle-icon';
import dataURLtoBlob from 'blueimp-canvas-to-blob'
import { Document, pdfjs } from 'react-pdf';
import createBrowserHistory from 'history/createBrowserHistory';
import { send } from 'process';
import { attempt, result } from 'lodash';
import { toast } from 'react-toastify';



const history = createBrowserHistory({ forceRefresh: true });

export interface IDocumentProps extends StateProps, DispatchProps, RouteComponentProps<{ url: string }> { }


export interface Props {
  getDocumentDetail: any,
  match: any,
  viewPDF: any,
  detaildocument: any,
  signDocument: any,
  approveDocument: any,
  blobPreview: any,
  filePDFSign: any,
  rejectDocument: any,
  PreviewPDFComponent: any,
  history: any,
  location: any
}

export const Preview: React.FC<Props> = (props) => {

  const [isNew, setIsNew] = useState(!props.match.params || !props.match.params.id);
  const [search, setSearch] = useState('');
  const [fileblob, setFileblob] = useState(null);
  const [showModal, setShowModal] = useState(false)
  const [showModalReject, setShowModalReject] = useState(false)
  const [showModalSign, setShowModalSign] = useState(false)
  const [notesApprove, setNotesApprove] = useState('')
  const [notesReject, setNotesReject] = useState('')
  const [loading, setLoading] = useState(false)
  const [typeTransaction, setTypeTransaction] = useState(1) // 1 -> approval / 2 -> reject
  const [dataDocument, setDataDocument] = useState(null)
  const [renderBeforeSend, setRenderBeforeSend] = useState(false)

  // 
  const [submitFirst, setSubmitFirst] = useState(false)
  const [idSecodStep, setIdSecondStep] = useState(null)
  const [fileNumber, setFileNumber] = useState(null)


  pdfjs.GlobalWorkerOptions.workerSrc =
    `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

  const signCanvas = useRef<any>();



  const sendDocument = () => {

    // cek dulu filenumber ada atau nggak
    // kalo sudah ada, hit
    // kalo nggak ada logic existing jalan\
    getDataPreview()


  }

  const clear = () => signCanvas.current.clear();
  const save = () => {
    let basse64 = signCanvas.current.getTrimmedCanvas().toDataURL("image/png")
    console.log(dataURLtoBlob(basse64))
  }
  // const save = () => console.log(signCanvas.getTrimmedCanvas)

  const handleClose = () => {
    setNotesApprove('')
    setShowModal(false)
  };

  const handleOpen = () => {
    setShowModal(true)
  }

  const handleCloseReject = () => {
    setNotesReject('')
    setShowModalReject(false)
  };

  const handleOpenReject = () => {
    setShowModalReject(true)
  }


  const handleCloseSign = () => {
    setShowModalSign(false)
  };

  const handleOpenSign = () => {
    setShowModalSign(true)
  }


  const test = (e) => {
    console.log('cb', e)
    if (e) {
      console.log('sudah render')
    } else {
      console.log('render')
      setRenderBeforeSend(true)
    }
  }


  const getDataPreview = () => {
    setRenderBeforeSend(false)
    let datasend = null
    let datapreviewStorage = localStorage.getItem('datapreview')
    let datapreview = JSON.parse(datapreviewStorage)
    console.log('data yang dikirim', props.location.state.data)
    axios.post('/services/documentservice/api/documents/get-data-for-preview', JSON.parse(props.location.state.data)).then(async (res) => {
      console.log('hasil', res.data.data)
      datasend = res.data.data
      await setLoading(true)
      await delayTime(3000)
      pdf(<PreviewPDFComponent
        from={datasend?.senderBranchName}
        title={datasend?.fileNumber}
        regarding={datasend?.regarding}
        date={datasend?.dateFormat}
        to={datasend?.to}
        fileContent={datasend?.fileContent}
        cc={datasend?.cc}
        dataDocument={datasend}
        acknowledge={datasend.acknowledgeList}
        notesApprove={notesApprove}
        notesReject={notesReject}
        cbRender={(e) => test(e)}
      />).toBlob().then(async (res) => {
        delayTime(3000)
        if (res) {
          console.log('jalan')
          let datapreviewStorage = localStorage.getItem('datapreview')
          const formData = new FormData();
          formData.append("pdfFile", res);
          formData.append("data", props.location.state.data)
          console.log('data yang dikirim', props.location.state.data)
          axios.put(`/services/documentservice/api/documents/send`, formData).then(result => {
            console.log('response', result)
            props.history.push('/')
            setLoading(false)
            toast.success('Berhasil Membuat Dokumen', {
              position: "top-left",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            });
          }).catch(err => {
            console.log('response', err.response)
            toast.error(translate(`${err.response.data.message}`));
            setLoading(false)
          })

        }
      })
    })
    setLoading(false)
  }

  const delayTime = (ms) => {
    return new Promise(
      resolve => setTimeout(resolve, ms)
    )
  }



  const newSubmitDocument = async () => {
    // ngehit api button send first step
    // datanya form
    await setLoading(true)
    setRenderBeforeSend(false)
    let datasend = null
    const formData = new FormData();
    formData.append("data", props.location.state.data);
    formData.append("fileAttachmentLinks", "");
    axios.put('/services/documentservice/api/dms/send-first-step', formData).then(async (res) => {
      console.log('hasil first step', res.data.data)
      setFileNumber(res.data.data?.fileNumber)
      setIdSecondStep(res.data.data?.id)
      setSubmitFirst(true)

      axios.post('/services/documentservice/api/documents/get-data-for-preview', JSON.parse(props.location.state.data)).then(async (data) => {
        console.log('hasil', data.data.data)
        datasend = data.data.data
        await setLoading(true)
        await delayTime(3000)
        pdf(<PreviewPDFComponent
          from={datasend?.senderBranchName}
          title={res.data.data.fileNumber}
          regarding={datasend?.regarding}
          date={datasend?.dateFormat}
          to={datasend?.to}
          fileContent={datasend?.fileContent}
          cc={datasend?.cc}
          dataDocument={datasend}
          acknowledge={datasend.acknowledgeList}
          notesApprove={notesApprove}
          notesReject={notesReject}
          cbRender={(e) => test(e)}
        />).toBlob().then(async (file) => {
          delayTime(3000)
          if (res) {
            const formData = new FormData();
            formData.append("pdfFile", file);
            formData.append("id", res.data.data.id)
            axios.put(`/services/documentservice/api/dms/send-second-step`, formData).then(result => {
              console.log('response', result)
              props.history.push('/')
              setLoading(false)
              toast.success('Berhasil Membuat Dokumen', {
                position: "top-left",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
              });
            }).catch(async (err) => {
              console.log('response', err.response)
              toast.error(translate(`${err.response.data.message}`));
              await delayTime(3000)
              if (err.response.data.message === 'error.errordocservice.message.error27') {
                history.replace('/draft')
              }
              setLoading(false)
            })

          }
        })
      })

    }).catch(err => {

    })

  }


  useEffect(() => {
    let datapreviewStorage = localStorage.getItem('datapreview')
    let datapreview = JSON.parse(props.location.state.data)

    console.log('data router', props.location.state)
    console.log('hasiltest', datapreview)
    axios.post('/services/documentservice/api/documents/get-data-for-preview', datapreview).then(res => {
      console.log('hasil get preview', res.data.data)
      setDataDocument(res.data.data)
    }).catch(err => {
      toast.error(translate(`${err.response.data.message}`));
    })

  }, []);

  const renderViewPDF = (id) => {
    props.viewPDF(id)
  }








  if (dataDocument) {
    return (
      <div className="container">
        <Row>
          <Col>
            <h2>Draft</h2>
            <p>File Preview Draft</p>
          </Col>
          <Col className='text-right'>
            {/* <button className='btn btn-primary mr-2'>Kembali</button>
              <button className='btn btn-primary'>Print Document</button> */}
          </Col>
        </Row>
        <PDFViewer style={{ width: '100%', height: '1000px' }} >
          <PreviewPDFComponent
            from={dataDocument?.senderBranchName}
            title={submitFirst ? fileNumber : dataDocument?.fileNumber}
            regarding={dataDocument?.regarding}
            date={dataDocument?.dateFormat}
            to={dataDocument?.to}
            fileContent={dataDocument?.fileContent}
            cc={dataDocument?.cc}
            dataDocument={dataDocument}
            acknowledge={dataDocument.acknowledgeList}
            notesApprove={notesApprove}
            notesReject={notesReject}
          />
        </PDFViewer>
        <Row className='mb-4 mt-4'>
          <Col className='text-right' xs="12" sm="12" md="6" lg="6">
          </Col>
          <Col className='text-right' xs="12" sm="12" md="6" lg="6">
            {/* <button className='btn btn-secondary mr-2 px-4'>Cancel</button>
              <button className='btn btn-danger mr-2 px-3' onClick={() => handleOpenReject()}><XCircleIcon className=
              'mr-1'/>Reject</button>
              <button className='btn btn-warning mr-2 px-4' onClick={() => handleOpenSign()}>Sign</button> */}
            {/* 
            <button className='btn btn-secondary px-3 mr-2' onClick={() => props.history.goBack()} disabled={loading}> Back</button> */}
            

            

            <button className='btn btn-secondary px-3 mr-2' onClick={() => props.history.goBack()} disabled={loading}> Back</button>
            {/* <Button tag={Link} to={`/draft/new/edit`} className='btn btn-secondary px-3 mr-2' disabled={loading}> Back</Button> */}
            <button className='btn btn-primary px-3' onClick={() => newSubmitDocument()} disabled={loading}>
              {loading ? <Spinner color='#fff' size="sm" className='mr-1' /> : <></>}
              Send </button>

            {/* <button className='btn btn-primary px-3' onClick={() => sendDocument()} disabled={loading}>
              {loading ? <Spinner color='#fff' size="sm" className='mr-1' /> : <></>}
              Send2</button> */}

            {/* <button className='btn btn-secondary mr-2 px-4'>Cancel</button> */}
          </Col>
        </Row>
      </div>
    )
  } else {
    return <></>
  }

};

const mapStateToProps = ({ document }: IRootState) => ({
  documentList: document.entities,
  loading: document.loading,
  detaildocument: document.detaildocument,
  filePDFSign: document.filePDFsign,
  acknowledgelist: document.acknowledgelist,
  fileUrl: document.fileUrlBlob,
  blobPreview: document.fileBlobPreview
});

const mapDispatchToProps = {
  getSearchEntities,
  getEntities,
  getEntitiesByEmpId,
  getDocumentDetail,
  postDocument,
  signDocument,
  approveDocument,
  viewPDF,
  rejectDocument
};

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(mapStateToProps, mapDispatchToProps)(Preview);
