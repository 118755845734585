import '../../../../../../scss/bootstrap.min.scss'
import '../../../../../../scss/app.min.scss'
import './home.scss';
import { renderToString } from "react-dom/server";
import { render } from "react-dom";
import tableAppr from 'app/modules/home/table'
import { Listbox, Transition } from '@headlessui/react'
// import { CheckIcon, SelectorIcon } from '@heroicons/react/solid'
import { PDFViewer } from '@react-pdf/renderer';

const list = [
  { value: 2 },
  { value: 5 },
  { value: 10 },
  { value: 15 },
  { value: 20 },
  { value: 25 },
]


import React, { useEffect, useState, Fragment } from 'react';
import { Link, RouteComponentProps } from 'react-router-dom';
import { Translate } from 'react-jhipster';
import { connect } from 'react-redux';
import { CardGroup, Row, Col, Alert, Tooltip, Modal, ModalHeader, ModalBody, FormGroup, Input, ModalFooter, Button, Spinner } from 'reactstrap';

import { IRootState } from 'app/shared/reducers';


import SideBar from 'app/shared/layout/sidebar/sidebar';
import Approval from 'app/shared/layout/card/approval';
import HandshakeIcon from 'app/component/handshake-icon';
import EyeIcon from 'app/component/eye-icon';
import EnvelopeIcon from 'app/component/envelope-icon';
import PaperPlaneIcon from 'app/component/paperplane-icon';
import InfoIcon from 'app/component/InfoIcon';

import { getSearchEntities, getEntities, getEntitiesByEmpId, getDocumentApproval, getDocumentAcknowledge, getDocumentSuratKeluar, getDocumentSuratMasuk } from './document.reducer';
import Axios from 'axios';
import Page from 'app/component/pagination/Page';

import ArrowRightIcon from 'app/component/arrow-right-icon';
import moment from 'moment-timezone';
import axios from 'axios'
import { size } from 'lodash';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import NotFoundImage from 'app/component/notfound';



export const NotFound = (props) => {
  // const { account } = props;

  const [category, setCategory] = useState<any>(0)
  const [page, setPage] = useState<any>(1)
  const [limit, setLimit] = useState<any>(5)
  const [selected, setSelected] = useState(list[0])
  const [listDocumentDraft, setListDocumentDraf] = useState<any>([])
  const [showModal, setShowModal] = useState<any>(false)
  const [documentSelected, setDocumentSelected] = useState<any>(null)
  const [loadingButton, setLoadingButton] = useState<boolean>(false)


  const handleClose = () => {
    setDocumentSelected(null)
    setShowModal(false)
  };

  const handleOpen = (item) => {
    setDocumentSelected(item)
    setShowModal(true)
  }

  useEffect(() => {
      // getListDocumentDraft(page, limit)
  }, []);
  

  const changePage = (pageParams) => {
    setPage(pageParams)
    getListDocumentDraft(pageParams, limit)
   
  }

  

  // const changeCategory = (categoryParams) => {
  //   setCategory(categoryParams)
  //   if(category == 0) {
  //     setPage(1)
  //     props.getDocumentApproval("3103077", page, limit, true);
  //   } else if (category == 1) {
  //     setPage(1)
  //     props.getDocumentAcknowledge("3103077", page, limit, true);
  //   } else if (category == 2) {
  //     setPage(1)
  //     props.getDocumentSuratMasuk("3103077", page, limit, true);
  //   } else if(category == 3){
  //     setPage(1)
  //     props.getDocumentSuratKeluar("3103077", page, limit, true);
  //   } 
  // } 


  const renderStatusPriority = (data) => {
    if(data == "1"){
      return <span style={{
        backgroundColor: '#FFF4F2', 
        paddingRight: 10, 
        paddingLeft: 10, 
        paddingTop: 6, 
        paddingBottom: 6, 
        border: '1px solid #CB3A31', 
        color: '#CB3A31', 
        borderRadius: 8,
        fontSize: 13,
        fontWeight: 600
      }}>High</span>
    } else if (data == "2") {
      return <span style={{
        backgroundColor: '#FFF4EB', 
        paddingRight: 10, 
        paddingLeft: 10, 
        paddingTop: 6, 
        paddingBottom: 6, 
        border: '1px solid #F37F26', 
        color: '#F37F26', 
        borderRadius: 8,
        fontSize: 13,
        fontWeight: 600
      }}>Medium</span>
    } else {
      return <span style={{
        backgroundColor: '#F0F3FF', 
        paddingRight: 10, 
        paddingLeft: 10, 
        paddingTop: 6, 
        paddingBottom: 6, 
        border: '1px solid #3267E3', 
        color: '#3267E3', 
        borderRadius: 8,
        fontSize: 13,
        fontWeight: 600
      }}>Low</span>
    }
  }


  const renderBatasWaktu = (data) => {
    if(!data) return '-'

    return moment(data).format('DD MMM YY | HH:mm') + " WIB"
  }

//   const renderStatus = (status) => {

// //     - DRAFT

// // - WAITING FOR ACKNOWLEDGEMENT

// // - WAITING FOR APPROVAL

// // - APPROVED

// // - REJECT
//     if(status == 'DRAFT') {
//       return 'DRAFT'
//     } else if(status == 'WAITING_FOR_ACKNOWLEDGEMENT') {
//       return 'WAITING FOR ACKNOWLEDGMENT'
//     } else if (status == 'WAITING_FOR_APPROVAL') {
//       return 'WAITING FOR APPROVAL'
//     } else if(status == 'APPROVED') {
//       return 'APPROVED'
//     } else if (status == 'REJECT') {
//       return 'REJECTED'
//     } else {
//       return ''
//     }
//   }

  const changeShow = (e) => {
    // alert(e.target.value)
    let show = e.target.value
    console.log(limit)
    setPage(1)
    setLimit(show)
    getListDocumentDraft(1, show)
  }


  const deleteDocument = (id) => {
    setLoadingButton(true)
    axios.delete(`/services/documentservice/api/documents/${id}`).then(res => {
      setLoadingButton(false)
      
      setPage(1),
      setLimit(5)
      getListDocumentDraft(1, 5)
      setShowModal(false)
    }).catch(err => {
      setLoadingButton(false)
      setPage(1),
      setLimit(5)
      getListDocumentDraft(1, 5)
      setShowModal(false)
    })
  }



//   Axios.interceptors.response.use(response => {
//     console.log('Getting Response:', response)
//     return response
//   })


  const getListDocumentDraft = (page, size) => {
    axios.get(`/services/documentservice/api/documents/draft?page=${page}&size=${size}`).then(res => {
      console.log('ini datanya', res.data.data)
      setListDocumentDraf(res.data.data)
     
    }).catch(err => {

    })
  }
 

 const gotoPreview = (id) => {
   props.history.push(`/preview/only/pdf/${id}`)
 }

 const gotoEdit = (id) => {
   props.history.push(`/draft/${id}/edit`)
 }

  const { documentList, loading } = props;
  return ( 
    <div style={{height: '90vh', width: '100%', verticalAlign: 'middle', alignContent: 'center', display: 'flex', justifyContent: 'center', alignItems: 'center', alignSelf: 'center', flexDirection: 'column'}}>
    <div className="px-5 pt-4 text-center" >
 
      <NotFoundImage />
      <br></br>

    </div >
    <div className='my-3 text-center'>
        <h2 className='text-center' style={{color: '#404040'}}>Oops, we were unable to find that page</h2>
        <p className='text-center'>Please check your network or try again later</p>
        <button className='btn btn-primary' onClick={() => props.history.replace('/')}>Back</button>
      </div>
    </div>
    
  );
};

const mapStateToProps = ({ document }: IRootState) => ({
  documentList: document.documentDashboardList,
  documentListAcknowledge: document.documentListAcknowledge,
  documentListSuratMasuk: document.documentListSuratMasuk,
  documentListSuratKeluar: document.documentListSuratKeluar,
  loading: document.loading,
  totalApproval: document.totalApproval,
  totalSuratMasuk: document.totalSuratMasuk,
  totalAcknowledge: document.totalAcknowledge,
  totalSuratKeluar: document.totalSuratKeluar,
  totalPageApproval: document.totalPageApproval,
  totalPageAcknowledge: document.totalPageAcknowledge,
  totalPageKotakMasuk: document.totalPageKotakMasuk,
  totalPageKotakKeluar: document.totalPageKotakKeluar
});


const mapDispatchToProps = {
  getSearchEntities,
  getEntities,
  getEntitiesByEmpId,
  getDocumentApproval,
  getDocumentAcknowledge,
  getDocumentSuratKeluar,
  getDocumentSuratMasuk
};
type StateProps = ReturnType<typeof mapStateToProps>
type DispatchProps = typeof mapDispatchToProps;;

export default connect(mapStateToProps, mapDispatchToProps)(NotFound);
