import '../../../../../../scss/bootstrap.min.scss';
import '../../../../../../scss/app.min.scss';
import '../announcement/announcement.scss';
import { renderToString } from 'react-dom/server';
import { render } from 'react-dom';
import tableAppr from 'app/modules/home/table';
import { Listbox, Transition } from '@headlessui/react';
// import { CheckIcon, SelectorIcon } from '@heroicons/react/solid'
import { PDFViewer } from '@react-pdf/renderer';
import Pagination from '@mui/material/Pagination';
const list = [
  { value: 2 },
  { value: 5 },
  { value: 10 },
  { value: 15 },
  { value: 20 },
  { value: 25 },
];
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from '@mui/material/Select';

import React, { useEffect, useState, Fragment } from 'react';
import { Link, RouteComponentProps } from 'react-router-dom';
import { Translate } from 'react-jhipster';
import { connect } from 'react-redux';
import {
  CardGroup,
  Row,
  Col,
  Alert,
  Tooltip,
  Modal,
  ModalHeader,
  ModalBody,
  FormGroup,
  Input,
  ModalFooter,
  Button,
  Spinner,
} from 'reactstrap';

import { IRootState } from 'app/shared/reducers';

import DatePicker from 'react-datepicker';
import { Dropdown, DropdownButton } from 'react-bootstrap';

import SideBar from 'app/shared/layout/sidebar/sidebar';
import Approval from 'app/shared/layout/card/approval';
import HandshakeIcon from 'app/component/handshake-icon';
import EyeIcon from 'app/component/eye-icon';
import EnvelopeIcon from 'app/component/envelope-icon';
import PaperPlaneIcon from 'app/component/paperplane-icon';
import InfoIcon from 'app/component/InfoIcon';

import {
  getSearchEntities,
  getEntities,
  getEntitiesByEmpId,
  getDocumentApproval,
  getDocumentAcknowledge,
  getDocumentSuratKeluar,
  getDocumentSuratMasuk,
} from '../document/document.reducer';
import Axios from 'axios';
import Page from 'app/component/pagination/Page';
import { useHistory } from 'react-router-dom';
import ArrowRightIcon from 'app/component/arrow-right-icon';
import moment from 'moment-timezone';
import axios from 'axios';
import { size } from 'lodash';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import BottomSort from 'app/component/bottom-sort';
import Illustration1 from 'app/component/illustration-1';
import AccountAnnounceIcon from 'app/component/announ-icon';
import EyeSmallIcon from 'app/component/eye-small-icon';
import PencilIcon from 'app/component/pencil-icon';
import { toast } from 'react-toastify';
import UploadDocumentListIcon from 'app/component/upload-document-karyawan';
import formatDate from 'app/utils/utils';
import { BASE_URL } from 'app/utils/constant';
export interface IHeaderProps {
  isAuthenticated?: boolean;
  isAdmin?: boolean;
  ribbonEnv?: string;
  isInProduction?: boolean;
  isSwaggerEnabled?: boolean;
  currentLocale?: string;
  onLocaleChange?: Function;
  account?: any;
  logout?: any;
  location?: any;
}

const initialFormData = {
  id: '0',
  type: '',
  priority: '',
  documentSecurity: '',
  startDate: new Date(new Date().setDate(new Date().getDate() - 30)),
  lastDate: new Date(),
  regarding: '',
  fileContent: '',
  to: [],
  cc: [],
  acknowledge: [],
  approval: [],
};

const initialModalData = {
  modal: false,
  custName: '',
  nik: '',
  dialCode: '',
  phoneNumber: '',
  email: '',
  userId: '',
  address: '',
  city: '',
  rejectDate: '',
  verification: '',
  updatedDate: ''
};

export const Reports = (props: IHeaderProps) => {
  let history = useHistory();
  const [search, setSearch] = useState('');
  const [listSearch, setListSearch] = useState([]);
  const [loadingList, setLoadingList] = useState(false);
  const [showList, setShowList] = useState(false);
  const [page, setPage] = useState(1);
  const [totalPage, setTotalPage] = useState(1);
  const [size, setSize] = useState<any>(10);
  const [sortType, setSortType] = useState('typeName.keyword,asc');
  const [sortPriority, setSortPriority] = useState('priority.keyword,asc');
  const [sortLastModified, setSortLastModified] = useState(
    'lastApprovedByName.keyword,asc'
  );
  const [sortDate, setSortDate] = useState('date,asc');
  const [limit, setLimit] = useState<any>(5);
  const [listReport, setListReport] = useState<any>([]);
  const [showModal, setShowModal] = useState<any>(false);
  const [selectedItem, setItemSelected] = useState<any>(null);
  const [loadingDelete, setLoadingDelete] = useState<any>(false);
  const [isAdmin, setIsAdmin] = useState<Boolean>(false);
  const [isAnnounce, setIsAnnounce] = useState<Boolean>(false);

  const [formData, setFormData] = useState(initialFormData);
  const [searchByDropDown, setSearchByDropDown] = useState<any>('Search by');
  const [filterAccountType, setfilterAccountType] = useState<any>('');
  const [filterBankRdn, setfilterBankRdn] = useState<any>('');
  const [filterRegistrationPlatform, setfilterRegistrationPlatform] = useState<any>('');
  const [filterOpeningBranch, setfilterOpeningBranch] = useState<any>('');
  const [filterStatus, setfilterStatus] = useState<any>('');

  const [modalAdd, setModalAdd] = useState<any>(false);

  const [searchBy, setSearchBy] = useState('');
  const [category, setCategory] = useState<any>(0);
  const [searchValue, setSearchValue] = useState('');

  const [sort, setSort] = useState<any>('REG-DATE');
  const [ascending, setAscending] = useState<any>(false);

  const [totalItems, setTotalItems] = useState<any>(0);
  const [dataUser, setDataUser] = useState<any>(null);

  const [showModalCompare, setShowModalCompare] = useState(initialModalData);

  // const ws = new WebSocket(
  //   `${BASE_URL.WSS_HOTSREGIS}ws/approval/all-history-by-customerId`
  // );

  const [listReject, setListReject] = useState<any>([]);
  const [sortListReject, setSortListReject] = useState<any>('createdDate');
  const [ascendingListReject, setAscendingListReject] = useState<any>(false);

  const [totalItemsListReject, setTotalItemsListReject] = useState<any>(0);

  const [pageListReject, setPageListReject] = useState(1);
  const [totalPageListReject, setTotalPageListReject] = useState(1);
  const [sizeListReject, setSizeListReject] = useState<any>(5);

  const [loadingListReject, setLoadingListReject] = useState(false);

  const [userId, setUserId] = useState<any>('');

  const [showModalApprovalHistory, setshowModalApprovalHistory] = useState<any>(
    false
  );

  const [listApprovalSelected, setListApprovalSelected] = useState<any>([]);

  const [sortDetailReject, setSortDetailReject] = useState<any>('REG-DATE');
  const [ascendingDetailReject, setAscendingDetailReject] = useState<any>(false);

  useEffect(() => {
    // alert('data search', props.location.state.search)
    // alert(props.location.state.search)
    if (props.location.state?.search == '') {
      setListSearch([]);
      setDefault();
    } else {
      getSearch(props.location.state?.search);
    }
  }, [
    props.location.state?.search,
    sortType,
    sortPriority,
    sortLastModified,
    sortDate,
    page,
    size,
    totalPage,
  ]);

  useEffect(() => {
    if (dataUser) {
      getListReport(page, limit);
    }
  }, [
    sort,
    page,
    limit,
    ascending,
    formData,
    filterStatus,
    filterBankRdn,
    filterRegistrationPlatform,
    filterAccountType,
    filterOpeningBranch,
    dataUser,
  ]);

  useEffect(() => {
    setLoadingListReject(true);
    var data = JSON.stringify({
      id: showModalCompare.userId,
      page: pageListReject,
      size: sizeListReject,
      sortBy: sortListReject,
      sortValue: ascendingListReject ? 'asc' : 'desc',
    });

    const axiosConfig = {
      headers: {
        'content-Type': 'application/json',
      },
    };

    axios
      .post(
        `${BASE_URL.HOTSREGIS}api/approval/all-history-by-customerId`,
        data,
        axiosConfig
      )
      .then((res) => {
        setListReject(res.data.data?.content);
        setTotalPageListReject(res.data.data?.totalPages);
        setTotalItemsListReject(res?.data?.totalItems);
        setLoadingListReject(false);
      })
      .catch((err) => {
        setLoadingListReject(false);
      });
  }, [
    showModalCompare.userId,
    sortListReject,
    ascendingListReject,
    pageListReject,
    sizeListReject,
  ]);

  useEffect(() => {
    const getData = async () => {
      getIsAdmin();
      await getDataUser();
    };

    // call the function
    getData()
  }, []);

  const setDefault = () => {
    setSortType('typeName.keyword,asc');
    setSortPriority('priority.keyword,asc');
    setSortLastModified('lastApprovedByName.keyword,asc');
    setSortDate('date,asc');
  };

  const getListReport = (
    page,
    limit,
    status = '',
    bankRDN = '',
    statusFilter = ''
  ) => {
    setLoadingList(true);
    var data = JSON.stringify({
      searchBy: searchBy,
      searchValue: searchValue,
      nik: '',
      ypid: '',
      sid: '',
      name: '',
      username: '',
      email: '',
      phoneNumber: '',
      status: status,
      filterAccType: filterAccountType,
      filterMembership: '',
      filterBankRDN: filterBankRdn,
      filterRegDateFrom: moment(formData.startDate).format('DD/MM/YYYY'),
      filterRegDateTo: moment(formData.lastDate).format('DD/MM/YYYY'),
      filterStatus: filterStatus,
      filterBranch: filterOpeningBranch,
      page: page,
      size: limit,
      sortBy: sort,
      sortValue: ascending ? 'asc' : 'desc',
      employeeId: dataUser?.employeeNo,
      filterRegChannel: filterRegistrationPlatform,
    });
    const axiosConfig = {
      headers: {
        'content-Type': 'application/json',
      },
    };

    axios
      .post(
        `${BASE_URL.HOTSREGIS}api/backOffice/admin-report`,
        data,
        axiosConfig
      )
      .then((res) => {
        setListReport(res.data.data?.content);
        setTotalPage(res.data.data?.totalPages);
        setTotalItems(res.data.data?.totalItems);
        setLoadingList(false);
      })
      .catch((err) => {
        if (page > 1) {
          setPage(1);
          getListReport(1, 5);
        } else {
          setLoadingList(false);
          toast.error('Failed Load Data', {
            position: 'top-left',
            autoClose: 8000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        }
      });
  };

  const exportExcel = () => {
    setLoadingList(true);
    var data = JSON.stringify({
      searchBy: searchBy,
      searchValue: searchValue,
      nik: '',
      ypid: '',
      sid: '',
      name: '',
      username: '',
      email: '',
      phoneNumber: '',
      status: '',
      filterAccType: filterAccountType,
      filterMembership: '',
      filterBankRDN: filterBankRdn,
      filterRegDateFrom: moment(formData.startDate).format('DD/MM/YYYY'),
      filterRegDateTo: moment(formData.lastDate).format('DD/MM/YYYY'),
      filterStatus: filterStatus,
      filterBranch: filterOpeningBranch,
      page: page,
      size: limit,
      sortBy: sort,
      sortValue: ascending ? 'asc' : 'desc',
      employeeId: dataUser?.employeeNo,
      filterRegChannel: filterRegistrationPlatform,
    });
    const axiosConfig = {
      headers: {
        'content-Type': 'application/json',
      },
    };

    axios({
      method: 'POST',
      url: `${BASE_URL.HOTSREGIS}api/backOffice/client-report/xlsx`,
      responseType: 'blob',
      headers: {
        'content-Type': 'application/json',
      },
      data: data
    }).then((res: any) => {

      const url = window.URL.createObjectURL(new Blob([res.data], { type: 'application/vnd.ms-excel' }));
      const link = document.createElement('a');

      link.href = url;
      link.setAttribute('download', `client_report_${moment(formData.startDate).format('YYYY-MM-DD')}_${moment(formData.lastDate).format('YYYY-MM-DD')}.xlsx`);
      document.body.appendChild(link);
      link.click();

      toast.success(
        'Customer registration report (' +
        moment(formData.startDate).format('YYYY-MM-DD') +
        ' to ' +
        moment(formData.lastDate).format('YYYY-MM-DD') +
        ') exported as an Excel file',
        {
          position: 'top-left',
          autoClose: 8000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        }
      );
      setLoadingList(false);
    }).catch((err) => {
      setLoadingList(false);
    })
  };

  const delayTime = (ms) => {
    return new Promise((resolve) => setTimeout(resolve, ms));
  };

  const getIsAdmin = () => {
    axios
      .get('/services/uaadocservice/api/is-admin-dms')
      .then((res) => {
        setIsAdmin(res.data);
      })
      .catch((err) => {
        setIsAdmin(false);
      });
  };

  const getIsAnnounce = () => {
    axios
      .get('/services/uaadocservice/api/is-user-may-announce')
      .then((res) => {
        setIsAnnounce(res.data);
      })
      .catch((err) => {
        setIsAnnounce(false);
      });
  };

  const getSearch = async (e = '') => {
    if (e.length > 1) {
      setLoadingList(true);
      axios
        .get(
          `services/documentservice/api/custom-search/documents?keyword=${e}&page=${page}&size=${size}&sort=${sortType}&sort=${sortPriority}&sort=${sortLastModified}&sort=${sortDate}`
        )
        .then(async (result) => {
          setListSearch(result.data.content);
          setTotalPage(result.data?.totalPages);
          await delayTime(2000);
          setLoadingList(false);
        })
        .catch((err) => {
          setListSearch([]);
          setLoadingList(false);
        });
    }
  };

  const changeSortType = () => {
    if (sortType == 'typeName.keyword,asc') {
      setSortType('typeName.keyword,desc');
    } else {
      setSortType('typeName.keyword,asc');
    }
  };

  const changeSortPriority = () => {
    if (sortPriority == 'priority.keyword,asc') {
      setSortPriority('priority.keyword,desc');
    } else {
      setSortPriority('priority.keyword,asc');
    }
  };

  const changeSortLastModified = () => {
    if (sortLastModified == 'lastApprovedByName.keyword,asc') {
      setSortLastModified('lastApprovedByName.keyword,desc');
    } else {
      setSortLastModified('lastApprovedByName.keyword,asc');
    }
  };

  const changeSortDate = () => {
    if (sortDate == 'date,asc') {
      setSortDate('date,desc');
    } else {
      setSortDate('date,asc');
    }
  };

  const goToPage = (id) => {
    setSearch('');
    history.push(`/preview/only/pdf/${id}`);
  };

  const changePage = (event: React.ChangeEvent<unknown>, value: number) => {
    setPage(value);
    getListReport(value, limit);
  };

  const changeShow = (e) => {
    // alert(e.target.value)
    let show = e.target.value;
    setPage(1);
    setLimit(show);
    getListReport(1, show);
    // getListDocumentHistori(1, show)
  };

  const handleClose = () => {
    setShowModal(false);
    setShowModalCompare({
      ...showModalCompare,
      modal: false,
      nik: '',
      custName: '',
      dialCode: '',
      phoneNumber: '',
      email: '',
      address: '',
      city: '',
      rejectDate: '',
      verification: '',
      updatedDate: '',
    });
  };

  const handleCloseModalDetailReject = () => {
    setshowModalApprovalHistory(false);
  };

  const openModalDelete = (item) => {
    setShowModal(true);
    setItemSelected(item);
  };

  const deleteFile = (id) => {
    setLoadingDelete(true);
    axios
      .delete(`services/documentservice/api/announcement/${id}`)
      .then((res) => {
        setLoadingDelete(false);
        toast.success('Success Delete Annoucement', {
          position: 'top-left',
          autoClose: 8000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
        setShowModal(false);
        setItemSelected(null);
        setPage(1);
        setLimit(5);
        getListReport(1, 5);
      })
      .catch((err) => {
        setLoadingDelete(false);
        toast.error('Failed Delete Announcement', {
          position: 'top-left',
          autoClose: 8000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
        setShowModal(false);
      });
  };

  const goToCreate = () => {
    history.push('/announcement/create');
  };

  const goToDetail = (data) => {
    let languageValue = 'ID';
    axios
      .get(
        `${BASE_URL.HOTSREGIS}api/backOffice/customer-verification/${data?.userId}?language=${languageValue}`
      )
      .then((res) => {
        if (res.data.code == 'SUCCESS') {
          history.push(`/reports/view/${data.userId}`);
        } else {
          toast.error('Error Reject:' + res.data.message, {
            position: 'top-left',
            autoClose: 8000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        }
      })
      .catch((err) => {
        toast.error('Error Reject:' + err, {
          position: 'top-left',
          autoClose: 8000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
        history.push(`/reports/view/${data.userId}`);
      });
  };

  const goToView = (id) => {
    history.push(`/announcement/edit/${id}`);
  };

  const handleFilterAccountType = (e) => {
    setPage(1);
    setfilterAccountType(e);
  };

  const handleFilterStatus = (e) => {
    setPage(1);
    setfilterStatus(e);
    // getListReport(page, limit, '', '', e.toUpperCase());
  };

  const handleFilterBankRdn = (e) => {
    setPage(1);
    setfilterBankRdn(e);
    // getListReport(page, limit, '', e.toUpperCase());
  };

  const handleRegistrationPlatform = (e) => {
    setPage(1);
    setfilterRegistrationPlatform(e);
  };

  const handleFilterOpeningBranch = (e) => {
    setPage(1);
    setfilterOpeningBranch(e);
  };

  const handleSearchBy = (e) => {
    var searchByString = '';
    switch (e) {
      case 'NIK':
        searchByString = 'NIK';
        break;
      case 'YP ID':
        searchByString = 'YP-ID';
        break;
      case 'SID':
        searchByString = 'SID';
        break;
      case 'Name':
        searchByString = 'CUSTOMER-NAME';
        break;
      case 'Email':
        searchByString = 'EMAIL';
        break;
      case 'User ID':
        searchByString = 'USERNAME';
        break;
      case 'Phone':
        searchByString = 'PHONE-NUMBER';
        break;
      default:
        searchByString = '';
        break;
    }
    setSearchByDropDown(e);
    setSearchBy(searchByString);
  };

  function changeBankName(bankName: String) {
    var bankShortName = '';

    switch (bankName) {
      case 'BANK RAKYAT INDONESIA (BRI)':
        bankShortName = 'BRI';
        break;
      case 'BANK MANDIRI':
        bankShortName = 'MANDIRI';
        break;
      case 'BANK SINARMAS':
        bankShortName = 'SINARMAS';
        break;
      case 'BCA (BANK CENTRAL ASIA)':
        bankShortName = 'BCA';
        break;
      case 'BANK CIMB NIAGA':
        bankShortName = 'CIMB';
        break;
      default:
        bankShortName = '-';
        break;
    }
    return bankShortName;
  }

  function getDataUser() {
    return axios
      .get(`services/uaadocservice/api/account/get-detail`)
      .then((res) => {
        setDataUser(res.data);
      })
      .catch((err) => {
        setDataUser(null);
      });
  }

  function handleRejectList(item: any) {
    var rejectReason = '';

    if (item?.listReject != null) {
      rejectReason = item?.listReject[0]?.rejectReasonDetail;
    } else {
      //Masuk ke else
    }

    return rejectReason;
  }

  const changePageListReject = (
    event: React.ChangeEvent<unknown>,
    value: number
  ) => {
    setPageListReject(value);
  };

  const changeShowListReject = (e) => {
    let show = e.target.value;
    setPageListReject(1);
    setSizeListReject(show);
  };

  const handleUserId = (userId) => {
    setUserId(userId);
  };

  const handlePercentage = (status) => {
    switch (status) {
      case 'PREREGIS_SUCCESS':
      case 'PREREGIS_ONPROGRESS':
        return '10%';
        break;
      case 'EMAIL_VERIFICATION':
        return '15%';
        break;
      case 'AML_CHECKING':
      case 'AML_APPROVED':
      case 'AML_REJECTED':
        return '20%';
        break;
      case 'EKYC_APPROVED':
      case 'EKYC_REJECTED_HARD':
      case 'EKYC_REJECTED_REVIEW':
        return '30%';
        break;
      case 'YPID_ONPROGRESS':
        return '35%';
        break;
      case 'YPID_APPROVED':
        return '40%';
        break;
      case 'KSEI_ONREQUEST':
        return '45%';
        break;
      case 'KSEI_ACCEPT':
      case 'KSEI_APPROVED':
      case 'KSEI_REJECTED':
        return '50%';
        break;
      case 'SID_APPROVED':
        return '60%';
        break;
      case 'SRE_APPROVED':
        return '70%';
        break;
      case 'RDN_ONREQUEST':
        return '80%';
        break;
      case 'RDN_APPROVED':
      case 'RDN_REJECTED':
      case 'RDN_HARD_REJECTED':
      case 'RDN_UPDATED':
        return '90%';
        break;
      case 'CL_SENT':
        return '95%';
        break;
      case 'COMPLETED':
        return '100%';
        break;
      default:
        return '-';
        break;
    }
  };

  const viewRegistrationChannel = (item) => {
    var registrationChannel = item?.registrationChannel;
    return (
      <div style={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
        <div style={{
          backgroundColor: registrationChannel == "OOA3" ? "#043B72" : (registrationChannel == "NAVI" ? "#F58220" : (registrationChannel == "MSTOCK" ? "#0086B8" : "#9E9E9E")),
          width: '70px',
          padding: 5,
          borderRadius: 16,
          textAlign: 'center'
        }}>
          <span style={{ color: '#FFFFFF', fontSize: '12px' }}>
            {registrationChannel == "" ? "OTHERS" : registrationChannel}
          </span>
        </div>
      </div>
    )
  }

  return (
    <div className="px-5 pt-4" style={{ background: '#fff' }}>
      <div className="row">
        <div className="col-12">
          <div className="page-title-box d-flex align-items-center justify-content-between">
            <h1 className="mb-0 font-size-28">Reports</h1>
          </div>
          <div className="page-title-box d-flex align-items-center justify-content-between">
            <h1 className="mb-0 font-size-14">New CMS Dashboard</h1>
          </div>
        </div>
      </div>

      <div className="row mb-4">
        <div className="col-1 col-md-2">
          <DropdownButton
            id="dropdown-basic-button"
            className=""
            title={searchByDropDown == '' ? 'Search by' : searchByDropDown}
            onSelect={handleSearchBy}
          >
            <Dropdown.ItemText>Select Search</Dropdown.ItemText>
            <Dropdown.Divider className="mx-4"></Dropdown.Divider>
            <Dropdown.Item eventKey="NIK">NIK</Dropdown.Item>
            <Dropdown.Item eventKey="YP ID">YP ID</Dropdown.Item>
            <Dropdown.Item eventKey="SID">SID</Dropdown.Item>
            <Dropdown.Item eventKey="Name">Customer Name</Dropdown.Item>
            <Dropdown.Item eventKey="Email">Email</Dropdown.Item>
            <Dropdown.Item eventKey="User ID">User ID</Dropdown.Item>
            <Dropdown.Item eventKey="Phone">Phone Number</Dropdown.Item>
          </DropdownButton>
        </div>
        <div className="app-search col-9 col-md-7">
          <div className="position-relative">
            <input
              type="text"
              className="form-control"
              placeholder="Enter value here"
              onChange={(e) => {
                if (e.target.value === '') {
                  setPage(1);
                  setSearchValue(e.target.value);
                  getListReport(page, limit);
                } else {
                  setSearchValue(e.target.value);
                }
              }}
              onKeyPress={(e) => {
                if (e.key == 'Enter') {
                  setPage(1);
                  getListReport(page, limit);
                }
              }}
            />
            <span className="bx bx-search-alt"></span>
          </div>
        </div>
        <div className="col-2 col-md-3">
          <a
            className="btn btn-primary w-100 text-white"
            onClick={(e) => {
              getListReport(page, limit);
            }}
          >
            Search Customer
          </a>
        </div>
      </div>

      <div className="row mb-4">
        <div className="col">
          <h5>Filter</h5>
          <div style={{ display: 'flex', flexDirection: 'row' }}>
            <DropdownButton
              id="dropdown-basic-button"
              className="mr-3"
              title={
                filterAccountType == ''
                  ? 'Account Type'
                  : filterAccountType == 'All'
                    ? 'Account Type'
                    : filterAccountType
              }
              onSelect={handleFilterAccountType}
            >
              <Dropdown.ItemText>Select Account Type</Dropdown.ItemText>
              <Dropdown.Divider className="mx-4"></Dropdown.Divider>
              <Dropdown.Item eventKey="All">All</Dropdown.Item>
              <Dropdown.Item eventKey="Marjin">Marjin</Dropdown.Item>
              <Dropdown.Item eventKey="Syariah">Syariah</Dropdown.Item>
              <Dropdown.Item eventKey="Reguler">Reguler</Dropdown.Item>
            </DropdownButton>
            <DropdownButton
              id="dropdown-basic-button"
              className="mr-3"
              title={
                filterBankRdn == ''
                  ? 'Bank RDN'
                  : filterBankRdn == 'All'
                    ? 'Bank RDN'
                    : filterBankRdn
              }
              onSelect={handleFilterBankRdn}
            >
              <Dropdown.ItemText>Select Bank RDN</Dropdown.ItemText>
              <Dropdown.Divider className="mx-4"></Dropdown.Divider>
              <Dropdown.Item eventKey="All">All</Dropdown.Item>
              <Dropdown.Item eventKey="BCA">BCA</Dropdown.Item>
              <Dropdown.Item eventKey="BRI">BRI</Dropdown.Item>
              <Dropdown.Item eventKey="MANDIRI">Mandiri</Dropdown.Item>
              <Dropdown.Item eventKey="CIMB">CIMB</Dropdown.Item>
              <Dropdown.Item eventKey="SINARMAS">Sinarmas</Dropdown.Item>
            </DropdownButton>
            <DropdownButton
              id="dropdown-basic-button"
              className="mr-3"
              title={
                filterOpeningBranch == ''
                  ? 'Opening Branch'
                  : filterOpeningBranch == 'All'
                    ? 'Opening Branch'
                    : filterOpeningBranch
              }
              onSelect={handleFilterOpeningBranch}
            >
              <Dropdown.ItemText>Select Opening Branch</Dropdown.ItemText>
              <Dropdown.Divider className="mx-4"></Dropdown.Divider>
              <Dropdown.Item eventKey="">All</Dropdown.Item>
              <Dropdown.Item eventKey="Bali">Bali</Dropdown.Item>
              <Dropdown.Item eventKey="Bandung Dago">Bandung Dago</Dropdown.Item>
              <Dropdown.Item eventKey="Batam">Batam</Dropdown.Item>
              <Dropdown.Item eventKey="Bekasi">Bekasi</Dropdown.Item>
              <Dropdown.Item eventKey="Bintaro">Bintaro</Dropdown.Item>
              <Dropdown.Item eventKey="Bogor">Bogor</Dropdown.Item>
              <Dropdown.Item eventKey="BSD">BSD</Dropdown.Item>
              <Dropdown.Item eventKey="Central Park">Central Park</Dropdown.Item>
              <Dropdown.Item eventKey="Depok">Depok</Dropdown.Item>
              <Dropdown.Item eventKey="Invesment House SCBD">Invesment House SCBD</Dropdown.Item>
              <Dropdown.Item eventKey="Kelapa Gading">Kelapa Gading</Dropdown.Item>
              <Dropdown.Item eventKey="KP Jambi">KP Jambi</Dropdown.Item>
              <Dropdown.Item eventKey="Lebak Bulus">Lebak Bulus</Dropdown.Item>
              <Dropdown.Item eventKey="Malang">Malang</Dropdown.Item>
              <Dropdown.Item eventKey="Medan Asia">Medan Asia</Dropdown.Item>
              <Dropdown.Item eventKey="Palembang">Palembang</Dropdown.Item>
              <Dropdown.Item eventKey="PIPM Balikpapan">PIPM Balikpapan</Dropdown.Item>
              <Dropdown.Item eventKey="PIPM Makasar">PIPM Makasar</Dropdown.Item>
              <Dropdown.Item eventKey="PIPM Padang">PIPM Padang</Dropdown.Item>
              <Dropdown.Item eventKey="Pontianak">Pontianak</Dropdown.Item>
              <Dropdown.Item eventKey="Puri Kencana">Puri Kencana</Dropdown.Item>
              <Dropdown.Item eventKey="Roxy">Roxy</Dropdown.Item>
              <Dropdown.Item eventKey="Semarang">Semarang</Dropdown.Item>
              <Dropdown.Item eventKey="Setia Budi">Setia Budi</Dropdown.Item>
              <Dropdown.Item eventKey="Solo">Solo</Dropdown.Item>
              <Dropdown.Item eventKey="Surabaya Kombes Duryat">Surabaya Kombes Duryat</Dropdown.Item>
              <Dropdown.Item eventKey="Surabaya Mayjen Sungkono">Surabaya Mayjen Sungkono</Dropdown.Item>
              <Dropdown.Item eventKey="Tangerang">Tangerang</Dropdown.Item>
              <Dropdown.Item eventKey="Yogyakarta">Yogyakarta</Dropdown.Item>
            </DropdownButton>
            <DropdownButton
              id="dropdown-basic-button"
              className="mr-3"
              title={
                filterStatus == ''
                  ? 'Status'
                  : filterStatus == 'All'
                    ? 'Status'
                    : filterStatus
              }
              onSelect={handleFilterStatus}
            >
              <Dropdown.ItemText>Select Status</Dropdown.ItemText>
              <Dropdown.Divider className="mx-4"></Dropdown.Divider>
              <Dropdown.Item eventKey="All">All</Dropdown.Item>
              <Dropdown.Item eventKey="AML_APPROVED">
                AML APPROVED
              </Dropdown.Item>
              <Dropdown.Item eventKey="EKYC_APPROVED">
                EKYC APPROVED
              </Dropdown.Item>
              <Dropdown.Item eventKey="YPID_ONPROGRESS">
                YPID ON PROGRESS
              </Dropdown.Item>
              <Dropdown.Item eventKey="YPID_APPROVED">
                YPID APPROVED
              </Dropdown.Item>
              <Dropdown.Item eventKey="KSEI_ONREQUEST">
                KSEI ON REQUEST
              </Dropdown.Item>
              <Dropdown.Item eventKey="KSEI_ACCEPT">KSEI ACCEPT</Dropdown.Item>
              <Dropdown.Item eventKey="SID_APPROVED">
                SID APPROVED
              </Dropdown.Item>
              <Dropdown.Item eventKey="SRE_APPROVED">
                SRE APPROVED
              </Dropdown.Item>
              <Dropdown.Item eventKey="RDN_ONREQUEST">
                RDN ON REQUEST
              </Dropdown.Item>
              <Dropdown.Item eventKey="RDN_APPROVED">
                RDN APPROVED
              </Dropdown.Item>
              <Dropdown.Item eventKey="RDN_UPDATED">RDN UPDATED</Dropdown.Item>
              <Dropdown.Item eventKey="CL_SENT">CL SENT</Dropdown.Item>
              <Dropdown.Item eventKey="COMPLETED">COMPLETED</Dropdown.Item>
              <Dropdown.Item eventKey="EKYC_REJECTED_HARD">
                EKYC REJECTED HARD
              </Dropdown.Item>
              <Dropdown.Item eventKey="KSEI_REJECTED">
                KSEI REJECTED
              </Dropdown.Item>
              <Dropdown.Item eventKey="RDN_REJECTED">
                RDN REJECTED
              </Dropdown.Item>
              <Dropdown.Item eventKey="PREREGIS_ONPROGRESS">
                PREREGIS ON PROGRESS
              </Dropdown.Item>
              <Dropdown.Item eventKey="EKYC_REJECTED_REVIEW">
                EKYC REJECTED REVIEW
              </Dropdown.Item>
            </DropdownButton>
            <DropdownButton
              id="dropdown-basic-button"
              className="mr-3"
              title={filterRegistrationPlatform == '' ? 'Registration Platform' : filterRegistrationPlatform}
              onSelect={handleRegistrationPlatform}
            >
              <Dropdown.ItemText>Select Registration Platform</Dropdown.ItemText>
              <Dropdown.Divider className="mx-4"></Dropdown.Divider>
              <Dropdown.Item eventKey="All">All</Dropdown.Item>
              <Dropdown.Item eventKey="OOA3">OOA3</Dropdown.Item>
              <Dropdown.Item eventKey="MSTOCK">MSTOCK</Dropdown.Item>
              <Dropdown.Item eventKey="NAVI">NAVI</Dropdown.Item>
            </DropdownButton>
          </div>
        </div>
        <div className="col">
          <h5>Date</h5>
          <div className="row">
            <div className="col-3">
              <DatePicker
                id="dropdown-basic-button"
                className="btn"
                dateFormat="d/MM/yyyy"
                selected={formData.startDate}
                onChange={(e) => {
                  setPage(1);
                  setFormData({
                    ...formData,
                    startDate: e,
                  });
                }}
                selectsStart
                startDate={formData.startDate}
                endDate={formData.lastDate}
                maxDate={formData.lastDate}
              />
            </div>
            <div className="col-3">
              <DatePicker
                id="dropdown-basic-button"
                className="btn"
                dateFormat="d/MM/yyyy"
                selected={formData.lastDate}
                onChange={(e) => {
                  setPage(1);
                  setFormData({
                    ...formData,
                    lastDate: e,
                  });
                }}
                selectsEnd
                startDate={formData.startDate}
                endDate={formData.lastDate}
                minDate={formData.startDate}
                maxDate={moment().toDate()}
              />
            </div>
            <div className="col-3">
              {/* <a
                className="btn btn-primary mx-2"
                href={
                  `${BASE_URL.HOTSREGIS}api/backOffice/client-report/pdf` +
                  '?startDate=' +
                  moment(formData.startDate).format('YYYY-MM-DD') +
                  '&endDate=' +
                  moment(formData.lastDate).format('YYYY-MM-DD')
                }
                target="_blank"
                onClick={() => {
                  toast.success(
                    'Customer registration report (' +
                    moment(formData.startDate).format('YYYY-MM-DD') +
                    ' to ' +
                    moment(formData.lastDate).format('YYYY-MM-DD') +
                    ') exported as a PDF file',
                    {
                      position: 'top-left',
                      autoClose: 8000,
                      hideProgressBar: false,
                      closeOnClick: true,
                      pauseOnHover: true,
                      draggable: true,
                      progress: undefined,
                    }
                  );
                }}
              >
                {' '}
                Download PDF
              </a> */}
              <div
                className="btn btn-danger mx-2"
                onClick={() => {
                  exportExcel()
                }}
              >
                {' '}
                Export
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="my-3 bg-light">
        <table className="table table-centered table-nowrap mb-0 table-responsive">
          <thead
            className=""
            style={{
              background: '#fff',
              borderTopLeftRadius: 30,
              borderTopRightRadius: 30,
            }}
          >
            <tr>
              <th
                className="py-4 px-4"
                style={{ cursor: 'pointer' }}
                onClick={() => {
                  setSort('REG-DATE');
                  setAscending(!ascending);
                }}
              >
                <div
                  style={{ display: 'flex', alignItems: 'center' }}
                  className="gap-4"
                >
                  <span className="mr-1">Reg Date</span>
                  <span className="mt-1">
                    {sort == 'REG-DATE' && ascending == true && (
                      <i
                        className="bx bxs-up-arrow"
                        style={{ fontSize: '10px' }}
                      ></i>
                    )}
                    {sort == 'REG-DATE' && ascending == false && (
                      <i
                        className="bx bxs-down-arrow  text-right"
                        style={{ fontSize: '10px' }}
                      ></i>
                    )}
                    {sort != 'REG-DATE' && (
                      <i
                        className="bx bxs-sort-alt text-right"
                        style={{ fontSize: '14px' }}
                      ></i>
                    )}
                  </span>
                </div>
              </th>
              <th
                className="py-4 px-4"
                style={{ cursor: 'pointer' }}
                onClick={() => {
                  setSort('YP-ID');
                  setAscending(!ascending);
                }}
              >
                <div
                  style={{ display: 'flex', alignItems: 'center' }}
                  className="gap-4"
                >
                  <span className="mr-1">YP ID</span>
                  <span className="mt-1">
                    {sort == 'YP-ID' && ascending == true && (
                      <i
                        className="bx bxs-up-arrow"
                        style={{ fontSize: '10px' }}
                      ></i>
                    )}
                    {sort == 'YP-ID' && ascending == false && (
                      <i
                        className="bx bxs-down-arrow  text-right"
                        style={{ fontSize: '10px' }}
                      ></i>
                    )}
                    {sort != 'YP-ID' && (
                      <i
                        className="bx bxs-sort-alt text-right"
                        style={{ fontSize: '14px' }}
                      ></i>
                    )}
                  </span>
                </div>
              </th>
              <th className="px-4">Cust Name</th>
              <th className="px-4">Recommender</th>
              <th className="px-4">Registration Platform</th>
              <th className="px-4">Opening Branch</th>
              <th className="px-4 text-center">RDN</th>
              <th className="px-4">Status</th>
              <th className="px-4">Revision</th>
              <th className="px-4">Percentage (%)</th>
              {/* <th className="px-4">Status CL & FPR</th>   */}
              <th className="px-4 text-center" style={{ width: '4%' }}>
                Action
              </th>
            </tr>
          </thead>
          <tbody className="" style={{ background: '#fff' }}>
            {!loadingList ? (
              totalItems != 0 ? (
                listReport.map((item, index) => {
                  return (
                    <tr
                      className=""
                      style={{ background: '#FFF', cursor: 'pointer' }}
                    >
                      <td
                        className=""
                        onClick={(d) => {
                          setShowModalCompare({
                            ...showModalCompare,
                            modal: true,
                            nik: item?.nik,
                            dialCode: item?.dialCode,
                            custName: item?.custName,
                            phoneNumber: item?.phoneNumber,
                            email: item?.email,
                            userId: item?.userId,
                            address: item?.address,
                            city: item?.city,
                            rejectDate: item?.rejectDate,
                            verification: item?.verification,
                            updatedDate: item?.updatedDate
                          });
                        }}
                      >
                        {formatDate(item?.regDate, 'DD/MM/YY HH:mm')}
                      </td>
                      <td
                        className=""
                        style={{
                          wordBreak: 'break-all',
                          minWidth: '100px',
                          maxWidth: '150px',
                          whiteSpace: 'pre-wrap',
                          background: '#FFF',
                          cursor: 'pointer',
                        }}
                        onClick={(d) => {
                          setShowModalCompare({
                            ...showModalCompare,
                            modal: true,
                            nik: item?.nik,
                            custName: item?.custName,
                            dialCode: item?.dialCode,
                            phoneNumber: item?.phoneNumber,
                            email: item?.email,
                            userId: item?.userId,
                            address: item?.address,
                            city: item?.city,
                            rejectDate: item?.rejectDate,
                            verification: item?.verification,
                            updatedDate: item?.updatedDate

                          });
                        }}
                      >
                        {item?.ypId}
                      </td>
                      <td
                        className=""
                        style={{
                          wordBreak: 'break-all',
                          minWidth: '200px',
                          maxWidth: '2500px',
                          whiteSpace: 'pre-wrap',
                        }}
                        onClick={(d) => {
                          setShowModalCompare({
                            ...showModalCompare,
                            modal: true,
                            nik: item?.nik,
                            custName: item?.custName,
                            dialCode: item?.dialCode,
                            phoneNumber: item?.phoneNumber,
                            email: item?.email,
                            userId: item?.userId,
                            address: item?.address,
                            city: item?.city,
                            rejectDate: item?.rejectDate,
                            verification: item?.verification,
                            updatedDate: item?.updatedDate
                          });
                        }}
                      >
                        {item?.custName}
                      </td>
                      <td
                        className=""
                        onClick={(d) => {
                          setShowModalCompare({
                            ...showModalCompare,
                            modal: true,
                            nik: item?.nik,
                            custName: item?.custName,
                            dialCode: item?.dialCode,
                            phoneNumber: item?.phoneNumber,
                            email: item?.email,
                            userId: item?.userId,
                            address: item?.address,
                            city: item?.city,
                            rejectDate: item?.rejectDate,
                            verification: item?.verification,
                            updatedDate: item?.updatedDate
                          });
                        }}
                      >
                        {item?.recommender}
                      </td>

                      <td
                        className=""
                        style={{
                          wordBreak: 'break-all',
                          minWidth: '100px',
                          maxWidth: '250px',
                          whiteSpace: 'pre-wrap',
                        }}
                        onClick={(d) => {
                          setShowModalCompare({
                            ...showModalCompare,
                            modal: true,
                            nik: item?.nik,
                            custName: item?.custName,
                            dialCode: item?.dialCode,
                            phoneNumber: item?.phoneNumber,
                            email: item?.email,
                            userId: item?.userId,
                            address: item?.address,
                            city: item?.city,
                            rejectDate: item?.rejectDate,
                            verification: item?.verification,
                            updatedDate: item?.updatedDate
                          });
                        }}
                      >
                        {viewRegistrationChannel(item)}
                      </td>

                      <td
                        className=""
                        style={{
                          wordBreak: 'break-all',
                          minWidth: '100px',
                          maxWidth: '250px',
                          whiteSpace: 'pre-wrap',
                        }}
                        onClick={(d) => {
                          setShowModalCompare({
                            ...showModalCompare,
                            modal: true,
                            nik: item?.nik,
                            custName: item?.custName,
                            dialCode: item?.dialCode,
                            phoneNumber: item?.phoneNumber,
                            email: item?.email,
                            userId: item?.userId,
                            address: item?.address,
                            city: item?.city,
                            rejectDate: item?.rejectDate,
                            verification: item?.verification,
                            updatedDate: item?.updatedDate
                          });
                        }}
                      >
                        {item?.branch}
                      </td>
                      <td
                        className="text-center"
                        onClick={(d) => {
                          setShowModalCompare({
                            ...showModalCompare,
                            modal: true,
                            nik: item?.nik,
                            custName: item?.custName,
                            dialCode: item?.dialCode,
                            phoneNumber: item?.phoneNumber,
                            email: item?.email,
                            userId: item?.userId,
                            address: item?.address,
                            city: item?.city,
                            rejectDate: item?.rejectDate,
                            verification: item?.verification,
                            updatedDate: item?.updatedDate
                          });
                        }}
                      >
                        {changeBankName(item?.bankRDN)}
                      </td>
                      <td
                        className=""
                        onClick={(d) => {
                          setShowModalCompare({
                            ...showModalCompare,
                            modal: true,
                            nik: item?.nik,
                            custName: item?.custName,
                            dialCode: item?.dialCode,
                            phoneNumber: item?.phoneNumber,
                            email: item?.email,
                            userId: item?.userId,
                            address: item?.address,
                            city: item?.city,
                            rejectDate: item?.rejectDate,
                            verification: item?.verification,
                            updatedDate: item?.updatedDate
                          });
                        }}
                      >
                        {item?.status}
                      </td>
                      <td
                        className=""
                        onClick={(d) => {
                          setShowModalCompare({
                            ...showModalCompare,
                            modal: true,
                            nik: item?.nik,
                            custName: item?.custName,
                            dialCode: item?.dialCode,
                            phoneNumber: item?.phoneNumber,
                            email: item?.email,
                            userId: item?.userId,
                            address: item?.address,
                            city: item?.city,
                            rejectDate: item?.rejectDate,
                            verification: item?.verification,
                            updatedDate: item?.updatedDate
                          });
                        }}
                      >
                        {item?.listReject ? <>
                          <b>{item.listReject[item.listReject.length - 1].rejectReasonName}</b>
                          <p>{item.listReject[item.listReject.length - 1].rejectReasonDetail}</p>
                        </> : '-'}
                      </td>
                      <td
                        className="text-center"
                        onClick={(d) => {
                          setShowModalCompare({
                            ...showModalCompare,
                            modal: true,
                            nik: item?.nik,
                            custName: item?.custName,
                            dialCode: item?.dialCode,
                            phoneNumber: item?.phoneNumber,
                            email: item?.email,
                            userId: item?.userId,
                            address: item?.address,
                            city: item?.city,
                            rejectDate: item?.rejectDate,
                            verification: item?.verification,
                            updatedDate: item?.updatedDate
                          });
                        }}
                      >
                        {handlePercentage(item?.status)}
                      </td>

                      <td className="">
                        <a
                          className="btn mx-2"
                          style={{ background: '#B8DBCA' }}
                          href={
                            `${BASE_URL.HOTSREGIS}api/backOffice/client-report/xlsx/single` +
                            '?id=' +
                            item?.userId
                          }
                          target="_blank"
                          onClick={() => {
                            toast.success(
                              'Individual customer registration report exported as an Excel file',
                              {
                                position: 'top-left',
                                autoClose: 8000,
                                hideProgressBar: false,
                                closeOnClick: true,
                                pauseOnHover: true,
                                draggable: true,
                                progress: undefined,
                              }
                            );
                          }}
                        >
                          {' '}
                          <svg
                            width="16px"
                            height="16px"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <image href="content/images/reports/ic_excel.svg" />
                          </svg>
                        </a>
                        <a
                          className="btn mx-2"
                          style={{ background: '#B8DBCA' }}
                          href={
                            `${BASE_URL.HOTSREGIS}api/backOffice/client-report/pdf/single` +
                            '?id=' +
                            item?.userId
                          }
                          target="_blank"
                          onClick={() => {
                            toast.success(
                              'Individual customer registration report exported as a PDF file',
                              {
                                position: 'top-left',
                                autoClose: 8000,
                                hideProgressBar: false,
                                closeOnClick: true,
                                pauseOnHover: true,
                                draggable: true,
                                progress: undefined,
                              }
                            );
                          }}
                        >
                          {' '}
                          <svg
                            width="16px"
                            height="16px"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <image href="content/images/reports/ic_pdf.svg" />
                          </svg>
                        </a>
                      </td>
                    </tr>
                  );
                })
              ) : (
                <tr>
                  <td colSpan={11} className="text-center">
                    Data Not Found
                  </td>
                </tr>
              )
            ) : (
              <>
                <tr>
                  <td colSpan={11}>
                    <div className="row" style={{ height: '70px' }}>
                      <div className="shimmerBG"></div>
                    </div>
                  </td>
                </tr>
                <tr>
                  <td colSpan={11}>
                    <div className="row" style={{ height: '70px' }}>
                      <div className="shimmerBG"></div>
                    </div>
                  </td>
                </tr>
              </>
            )}
          </tbody>
        </table>
      </div>

      <div className="row">
        <div className="col-12 col-md-6 col-lg-6">
          <div className="form-group row">
            <label htmlFor="priority" className="col-sm-1 col-form-label">
              Show
            </label>

            <div className="" style={{ width: '80px' }}>
              <select
                name="show"
                value={limit}
                className="custom-select"
                onChange={(e) => changeShow(e)}
              >
                <option value="5">5</option>
                <option value="10" selected>
                  10
                </option>
                <option value="15">15</option>
                <option value="20">20</option>
              </select>
            </div>
            <label htmlFor="priority" className="col-sm-2 col-form-label">
              of{' '}
              <span className="text-decoration-underline fw-bold mx-1">
                {totalItems}
              </span>{' '}
              Entries
            </label>
          </div>
        </div>
        <div
          className="col-12 col-md-6 col-lg-6"
          style={{ justifyContent: 'end', display: 'flex' }}
        >
          {/* <Page currentPage={page} totalPage={listDocumentHistori.totalPages} last={listDocumentHistori.last} first={listDocumentHistori.first} changePage={(page) => changePage(page)}/> */}
          <Pagination
            count={totalPage}
            color="primary"
            page={page}
            showFirstButton
            showLastButton
            onChange={changePage}
          />
        </div>
      </div>
      <Modal
        isOpen={showModal}
        toggle={handleClose}
        size="lg"
        centered
        onClosed={() => {
          setShowModal(false);
          setItemSelected(null);
        }}
      >
        <ModalHeader
          toggle={handleClose}
          style={{ borderBottom: 'none' }}
        ></ModalHeader>
        <ModalBody id="documenttestApp.document.delete.question">
          <h3 className="text-center">
            Are you sure want to delete{' '}
            <span className="font-weight-bold">"{selectedItem?.title}"</span>{' '}
            announcement ?
          </h3>
          <div className="text-center mt-5">
            <button
              className="btn btn-success mx-2 px-4"
              onClick={() => deleteFile(selectedItem?.id)}
              disabled={loadingDelete}
            >
              {loadingDelete ? (
                <Spinner color="#fff" size="sm" className="mr-1" />
              ) : (
                <></>
              )}{' '}
              Yes
            </button>
            <button
              disabled={loadingDelete}
              className="btn btn-secondary mx-2"
              onClick={() => {
                setShowModal(false);
                setItemSelected(null);
              }}
            >
              Cancel
            </button>
          </div>
        </ModalBody>
        <ModalFooter style={{ borderTop: 'none' }}></ModalFooter>
      </Modal>

      <Modal
        isOpen={showModalCompare.modal}
        toggle={handleClose}
        size="xl"
        style={{ marginTop: '5%' }}
        onClosed={() => {
          setShowModalCompare({
            ...showModalCompare,
            modal: false,
            nik: '',
            custName: '',
            dialCode: '',
            phoneNumber: '',
            email: '',
            address: '',
            city: '',
            rejectDate: '',
            verification: '',
            updatedDate: ''
          });
          setItemSelected(null);
        }}
      >
        <div className="my-5">
          <div className="px-5 pt-4" style={{ height: '100%' }}>
            <div className="row">
              <div className="col-12">
                <div className="row page-title-box">
                  <div className="col-11">
                    <h1 className="mb-0 font-size-28">Detail Reports</h1>
                  </div>
                  <div className="col-1 text-right">
                    <a
                      className="mx-2"
                      style={{ cursor: 'pointer' }}
                      onClick={() => {
                        setShowModalCompare({
                          ...showModalCompare,
                          modal: false,
                          nik: '',
                          custName: '',
                          dialCode: '',
                          phoneNumber: '',
                          email: '',
                          address: '',
                          city: '',
                          rejectDate: '',
                          verification: '',
                          updatedDate: ''
                        });
                        setItemSelected(null);
                      }}
                    >
                      <svg
                        width="20px"
                        height="20px"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <image href="content/images/common/ic_close.svg" />
                      </svg>
                    </a>
                  </div>
                </div>
              </div>
            </div>

            <div className="row mb-3">
              <div className="col-6">
                <div className="row mb-3 align-items-center">
                  <div className="col-4">Nomor Telepon</div>
                  <div className="col-8">
                    <input
                      className="py-2 px-2 rounded w-100"
                      type="text"
                      disabled={true}
                      value={
                        showModalCompare?.dialCode +
                        showModalCompare?.phoneNumber
                      }
                    />
                  </div>
                </div>
                <div className="row mb-3 align-items-center">
                  <div className="col-4">Nomor Identitas</div>
                  <div className="col-8">
                    <input
                      className="py-2 px-2 rounded w-100"
                      type="text"
                      disabled={true}
                      value={showModalCompare?.nik}
                    />
                  </div>
                </div>
                <div className="row mb-3 align-items-center">
                  <div className="col-4">Alamat</div>
                  <div className="col-8">
                    <input
                      className="py-2 px-2 rounded w-100"
                      type="text"
                      disabled={true}
                      value={showModalCompare?.address}
                    />
                  </div>
                </div>
                <div className="row mb-3 align-items-center">
                  <div className="col-4">Kota</div>
                  <div className="col-8">
                    <input
                      className="py-2 px-2 rounded w-100"
                      type="text"
                      disabled={true}
                      value={showModalCompare?.city}
                    />
                  </div>
                </div>
                <div className="row mb-3 align-items-center">
                  <div className="col-4">Tanggal terakhir update</div>
                  <div className="col-8">
                    <input
                      className="py-2 px-2 rounded w-100"
                      type="text"
                      disabled={true}
                      value={formatDate(showModalCompare?.updatedDate, 'DD/MM/YY HH:mm')}
                    />
                  </div>
                </div>
                {/* <div className="row mb-3 align-items-center">
                  <div className="col-4">Alasan ditolak</div>
                  <div className="col-8">
                    <input
                      className="py-2 px-2 rounded w-100"
                      type="text"
                      disabled={true}
                      value={"-"}
                    />
                  </div>
                </div> */}
              </div>
              <div className="col-6">
                <div className="row mb-3 align-items-center">
                  <div className="col-4">Nama Lengkap (Sesuai KTP)</div>
                  <div className="col-8">
                    <input
                      className="py-2 px-2 rounded w-100"
                      type="text"
                      disabled={true}
                      value={showModalCompare?.custName}
                    />
                  </div>
                </div>
                <div className="row mb-3 align-items-center">
                  <div className="col-4">Email</div>
                  <div className="col-8">
                    <input
                      className="py-2 px-2 rounded w-100"
                      type="text"
                      disabled={true}
                      value={showModalCompare?.email}
                    />
                  </div>
                </div>
                <div className="row mb-3 align-items-center">
                  <div className="col-4">Tanggal ditolak</div>
                  <div className="col-8">
                    <input
                      className="py-2 px-2 rounded w-100"
                      type="text"
                      disabled={true}
                      value={formatDate(showModalCompare?.rejectDate, 'DD/MM/YY HH:mm') === 'Invalid date' ? '-' : formatDate(showModalCompare?.rejectDate, 'DD/MM/YY HH:mm')}
                    />
                  </div>
                </div>
                <div className="row mb-3 align-items-center">
                  <div className="col-4">Ditolak oleh</div>
                  <div className="col-8">
                    <input
                      className="py-2 px-2 rounded w-100"
                      type="text"
                      disabled={true}
                      value={showModalCompare?.verification}
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="row align-items-center mb-3">
                <table className="table table-centered table-nowrap mb-0 table-responsive">
                  <thead
                    className=""
                    style={{
                      background: '#fff',
                      borderTopLeftRadius: 30,
                      borderTopRightRadius: 30,
                    }}
                  >
                    <tr>
                      <th className="py-4 px-4">No</th>
                      <th
                        className="py-4 px-4"
                        style={{
                          wordBreak: 'break-all',
                          minWidth: '200px',
                          maxWidth: '250px',
                          whiteSpace: 'pre-wrap',
                          cursor: 'pointer',
                        }}
                        onClick={() => {
                          setSortListReject('createdDate');
                          setAscendingListReject(!ascendingListReject);
                        }}
                      >
                        <div
                          style={{ display: 'flex', alignItems: 'center' }}
                          className="gap-4"
                        >
                          <span className="mr-1">Created Date</span>
                          <span className="mt-1">
                            {sortListReject == 'createdDate' &&
                              ascendingListReject == true && (
                                <i
                                  className="bx bxs-up-arrow"
                                  style={{ fontSize: '10px' }}
                                ></i>
                              )}
                            {sortListReject == 'createdDate' &&
                              ascendingListReject == false && (
                                <i
                                  className="bx bxs-down-arrow  text-right"
                                  style={{ fontSize: '10px' }}
                                ></i>
                              )}
                            {sortListReject != 'createdDate' && (
                              <i
                                className="bx bxs-sort-alt text-right"
                                style={{ fontSize: '14px' }}
                              ></i>
                            )}
                          </span>
                        </div>
                      </th>
                      {/* <th className="px-4 w-25">User ID</th> */}
                      <th
                        className="py-4 px-4"
                        style={{
                          wordBreak: 'break-all',
                          minWidth: '300px',
                          maxWidth: '350px',
                          whiteSpace: 'pre-wrap',
                          cursor: 'pointer',
                        }}
                        onClick={() => {
                          setSortListReject('status');
                          setAscendingListReject(!ascendingListReject);
                        }}
                      >
                        <div
                          style={{ display: 'flex', alignItems: 'center' }}
                          className="gap-4"
                        >
                          <span className="mr-1">Status</span>
                          <span className="mt-1">
                            {sortListReject == 'status' &&
                              ascendingListReject == true && (
                                <i
                                  className="bx bxs-up-arrow"
                                  style={{ fontSize: '10px' }}
                                ></i>
                              )}
                            {sortListReject == 'status' &&
                              ascendingListReject == false && (
                                <i
                                  className="bx bxs-down-arrow  text-right"
                                  style={{ fontSize: '10px' }}
                                ></i>
                              )}
                            {sortListReject != 'status' && (
                              <i
                                className="bx bxs-sort-alt text-right"
                                style={{ fontSize: '14px' }}
                              ></i>
                            )}
                          </span>
                        </div>
                      </th>
                      <th
                        className="py-4 px-4 w-25"
                        style={{
                          wordBreak: 'break-all',
                          minWidth: '200px',
                          maxWidth: '300px',
                          whiteSpace: 'pre-wrap',
                          cursor: 'pointer',
                        }}
                        onClick={() => {
                          setSortListReject('createdBy');
                          setAscendingListReject(!ascendingListReject);
                        }}
                      >
                        <div
                          style={{ display: 'flex', alignItems: 'center' }}
                          className="gap-4"
                        >
                          <span className="mr-1">Approval</span>
                          <span className="mt-1">
                            {sortListReject == 'createdBy' &&
                              ascendingListReject == true && (
                                <i
                                  className="bx bxs-up-arrow"
                                  style={{ fontSize: '10px' }}
                                ></i>
                              )}
                            {sortListReject == 'createdBy' &&
                              ascendingListReject == false && (
                                <i
                                  className="bx bxs-down-arrow  text-right"
                                  style={{ fontSize: '10px' }}
                                ></i>
                              )}
                            {sortListReject != 'createdBy' && (
                              <i
                                className="bx bxs-sort-alt text-right"
                                style={{ fontSize: '14px' }}
                              ></i>
                            )}
                          </span>
                        </div>
                      </th>
                      <th
                        className="px-4 w-25"
                        style={{
                          wordBreak: 'break-all',
                          minWidth: '350px',
                          maxWidth: '500px',
                          whiteSpace: 'pre-wrap',
                        }}
                      >
                        Revision
                      </th>
                    </tr>
                  </thead>
                  <tbody className="" style={{ background: '#fff' }}>
                    {!loadingListReject ? (
                      totalItemsListReject != 0 ? (
                        listReject?.map((item, index) => {
                          return (
                            <tr
                              className=""
                              style={{
                                background: '#FFF',
                              }}
                            >
                              <td className="text-center">{index + 1}</td>
                              <td className="">
                                {formatDate(
                                  item?.createdDate,
                                  'DD/MM/YY HH:mm'
                                )}
                              </td>
                              {/* <td
                                className=""
                                style={{
                                  wordBreak: 'break-all',
                                  minWidth: '300px',
                                  maxWidth: '500px',
                                  whiteSpace: 'pre-wrap',
                                }}
                              >
                                {item?.username}
                              </td> */}
                              <td className="">{item?.status}</td>
                              <td className="">{item?.createdBy}</td>
                              <td className="align-items-center">
                                {item?.listReject?.length == null ? (
                                  <></>
                                ) : (
                                  <div className="row">
                                    <div
                                      className="col-1"
                                      onClick={(data) => {
                                        setshowModalApprovalHistory(true);
                                        setListApprovalSelected(
                                          item?.listReject
                                        );
                                      }}
                                      style={{ cursor: 'pointer' }}
                                    >
                                      <svg
                                        width="30px"
                                        height="30px"
                                        xmlns="http://www.w3.org/2000/svg"
                                        className="mt-2 mr-2"
                                      >
                                        {item?.listReject?.length > 1 ? (
                                          <image href="content/images/approval/ic_many_revision.svg" />
                                        ) : (
                                          <image href="content/images/approval/ic_one_revision.svg" />
                                        )}
                                      </svg>
                                    </div>
                                    <div className="col-11">
                                      {handleRejectList(item)}
                                    </div>
                                  </div>
                                )}
                              </td>
                            </tr>
                          );
                        })
                      ) : (
                        <tr>
                          <td colSpan={10} className="text-center">
                            Data Not Found
                          </td>
                        </tr>
                      )
                    ) : (
                      <>
                        <tr>
                          <td colSpan={10}>
                            <div className="row" style={{ height: '70px' }}>
                              <div className="shimmerBG"></div>
                            </div>
                          </td>
                        </tr>
                        <tr>
                          <td colSpan={10}>
                            <div className="row" style={{ height: '70px' }}>
                              <div className="shimmerBG"></div>
                            </div>
                          </td>
                        </tr>
                      </>
                    )}
                  </tbody>
                </table>
              </div>
              <div className="row">
                <div className="col-12 col-md-6 col-lg-6">
                  <div className="form-group row">
                    <label
                      htmlFor="priority"
                      className="col-sm-1 col-form-label"
                    >
                      Show
                    </label>

                    <div className="" style={{ width: '90px' }}>
                      <select
                        name="show"
                        value={sizeListReject}
                        className="custom-select"
                        onChange={(e) => changeShowListReject(e)}
                      >
                        <option value="5">5</option>
                        <option value="10" selected>
                          10
                        </option>
                        <option value="15">15</option>
                        <option value="20">20</option>
                      </select>
                    </div>
                    <label
                      htmlFor="priority"
                      className="col-sm-2 col-form-label"
                    >
                      Entries
                    </label>
                  </div>
                </div>
                <div
                  className="col-12 col-md-6 col-lg-6"
                  style={{ justifyContent: 'end', display: 'flex' }}
                >
                  {/* <Page currentPage={page} totalPage={listDocumentHistori.totalPages} last={listDocumentHistori.last} first={listDocumentHistori.first} changePage={(page) => changePage(page)}/> */}
                  <Pagination
                    count={totalPageListReject}
                    color="primary"
                    page={pageListReject}
                    showFirstButton
                    showLastButton
                    onChange={changePageListReject}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </Modal>

      <Modal
        isOpen={showModalApprovalHistory}
        toggle={handleCloseModalDetailReject}
        size="xl"
        style={{ marginTop: '5%' }}
        onClosed={() => {
          setshowModalApprovalHistory(false);
          setItemSelected(null);
        }}
      >
        <div className="my-5">
          <div className="px-5 pt-4" style={{ height: '100%' }}>
            <div className="row">
              <div className="col-12">
                <div className="row page-title-box">
                  <div className="col-11">
                    <h1 className="mb-0 font-size-28">List Reject Reason</h1>
                  </div>
                  <div className="col-1 text-right">
                    <a
                      style={{ background: '#FFF', cursor: 'pointer' }}
                      className="mx-2"
                      onClick={() => {
                        setshowModalApprovalHistory(false);
                        setItemSelected(null);
                      }}
                    >
                      {' '}
                      <svg
                        width="20px"
                        height="20px"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <image href="content/images/common/ic_close.svg" />
                      </svg>
                    </a>
                  </div>
                </div>
              </div>
            </div>

            <div className="row align-items-center mb-3">
              <table className="table table-centered table-nowrap mb-0 table-responsive">
                <thead
                  className=""
                  style={{
                    background: '#fff',
                    borderTopLeftRadius: 30,
                    borderTopRightRadius: 30,
                  }}
                >
                  <tr>
                    <th className="py-4 px-4 text-center">No</th>

                    <th className="px-4 w-25">Reject Name</th>
                    <th
                      className="py-4 px-4"
                      onClick={() => {
                        // setSortDetailReject('status');
                        // setAscendingDetailReject(!ascendingDetailReject);
                      }}
                    >
                      <div
                        style={{ display: 'flex', alignItems: 'center' }}
                        className="gap-4"
                      >
                        <span className="mr-1 w-100">Reject Reason Detail</span>
                      </div>
                    </th>
                  </tr>
                </thead>
                <tbody className="" style={{ background: '#fff' }}>
                  {listApprovalSelected.map((reason, index) => {
                    return (
                      <tr
                        className=""
                        style={{
                          background: '#FFF',
                        }}
                      >
                        <td className="text-center">{index + 1}</td>

                        <td
                          className=""
                          style={{
                            wordBreak: 'break-all',
                            minWidth: '300px',
                            maxWidth: '500px',
                            whiteSpace: 'pre-wrap',
                          }}
                        >
                          {reason?.rejectReasonName}
                        </td>
                        <td
                          className=""
                          style={{
                            wordBreak: 'break-all',
                            minWidth: '300px',
                            maxWidth: '400px',
                            whiteSpace: 'pre-wrap',
                          }}
                        >
                          {reason?.rejectReasonDetail}
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </Modal>
    </div>
  );
};

const mapStateToProps = ({ document }: IRootState) => ({});

const mapDispatchToProps = {};
type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(mapStateToProps, mapDispatchToProps)(Reports);
