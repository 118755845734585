import { Moment } from 'moment';

export interface IDocument {
  id?: number;
  type?: string;
  fileNumber?: string;
  priority?: string;
  documentSecurity?: string;
  date?: string;
  expDate?: string;
  sender?: string;
  senderPosition?: string;
  senderLevel?: string;
  receiver?: string;
  // cc?: string;
  regarding?: string;
  fileContent?: string;
  fileStatus?: string;
  version?: string;
  createdBy?: string;
  createdDate?: string;
  lastModifiedBy?: string;
  lastModifiedDate?: string;
  description?: string;
  fileId?: number;
  filePath?: string;
  senderEmployeeNo?: string;
  senderBranchName?: string;
  // senderBranchName?: string;
  processInstanceId?: string;
  to?:IBranchCode[];
  cc?:IBranchCode[];
  approval?:IApproval[];
  acknowledge?:IApproval[];
  priorityCode?: any,
  expDateFormat?: any,
  fileStatusName?: any,
  canView?: any

}

export const defaultValue: Readonly<IDocument> = {};

export interface IBranchCode {
  branchCode?: string;

}

export interface IApproval {
  employeeNo?: string;
}