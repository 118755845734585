import '../../../../../../scss/bootstrap.min.scss'
import '../../../../../../scss/app.min.scss'
import './notification.scss';
import { renderToString } from "react-dom/server";
import { render } from "react-dom";
import tableAppr from 'app/modules/home/table'
import { Listbox, Transition } from '@headlessui/react'
// import { CheckIcon, SelectorIcon } from '@heroicons/react/solid'
import { PDFViewer } from '@react-pdf/renderer';
import Pagination from '@mui/material/Pagination';
import Toggle from 'react-toggle'
const list = [
  { value: 2 },
  { value: 5 },
  { value: 10 },
  { value: 15 },
  { value: 20 },
  { value: 25 },
]
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import {Select as SelectMui } from '@mui/material';

import React, { useEffect, useState, Fragment } from 'react';
import { Link, RouteComponentProps } from 'react-router-dom';
import { Translate } from 'react-jhipster';
import { connect } from 'react-redux';
import { CardGroup, Row, Col, Alert, Tooltip, Modal, ModalHeader, ModalBody, FormGroup, Input, ModalFooter, Button, Spinner } from 'reactstrap';

import { IRootState } from 'app/shared/reducers';


import SideBar from 'app/shared/layout/sidebar/sidebar';
import Approval from 'app/shared/layout/card/approval';
import HandshakeIcon from 'app/component/handshake-icon';
import EyeIcon from 'app/component/eye-icon';
import EnvelopeIcon from 'app/component/envelope-icon';
import PaperPlaneIcon from 'app/component/paperplane-icon';
import InfoIcon from 'app/component/InfoIcon';

import { getSearchEntities, getEntities, getEntitiesByEmpId, getDocumentApproval, getDocumentAcknowledge, getDocumentSuratKeluar, getDocumentSuratMasuk } from '../document/document.reducer';
import Axios from 'axios';
import Page from 'app/component/pagination/Page';
import { useHistory } from "react-router-dom";
import ArrowRightIcon from 'app/component/arrow-right-icon';
import moment from 'moment-timezone';
import axios from 'axios'
import { size } from 'lodash';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import BottomSort from 'app/component/bottom-sort';
import Illustration1 from 'app/component/illustration-1';
import EditBlue from 'app/component/edit-blue';
import DeleteRed from 'app/component/delete-red';

import UploadDocumentListIcon from 'app/component/upload-document-karyawan';
import UserAddKaryawanIcon from 'app/component/user-add-karyawan';

import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import Select from 'react-select';
import DatePicker from "react-datepicker";
import PencilIcon from 'app/component/pencil-icon';
import { toast } from 'react-toastify';
import { Item } from 'react-bootstrap/lib/Pagination';

export interface IHeaderProps {
  isAuthenticated?: boolean;
  isAdmin?: boolean;
  ribbonEnv?: string;
  isInProduction?: boolean;
  isSwaggerEnabled?: boolean;
  currentLocale?: string;
  onLocaleChange?: Function;
  account?: any,
  logout?: any,
  location?: any,
  match?: any
}


export const GroupDetail = (props: IHeaderProps) => {
  let history = useHistory();
  const [search, setSearch] = useState('')
  const [loading, setLoading] = useState(false)
  const [loadingSaveKaryawan, setLoadingSaveKaryawan] = useState(false)
  const [keyword, setKeyword] = useState('')
  const [listSearch, setListSearch] = useState([])
  const [loadingList, setLoadingList] = useState(false)
  const [showList, setShowList] = useState(false)
  const [page, setPage] = useState(1)
  const [totalPage, setTotalPage] = useState(1)
  const [size, setSize] = useState<any>(10)
  const [sortType, setSortType] = useState('typeName.keyword,asc')
  const [sortPriority, setSortPriority] = useState('priority.keyword,asc')
  const [sortLastModified, setSortLastModified] = useState('lastApprovedByName.keyword,asc')
  const [sortDate, setSortDate] = useState('date,asc')
  const [listNotification, setListNotification] = useState<any>([])
  const [listKaryawan, setListKaryawan] = useState<any>([])
  const [showModal, setShowModal] = useState<any>(false)
  const [showModalPermission, setShowModalPermission] = useState<any>(false)
  const [fullname, setFullName] = useState<any>("")
  const [iddelete, setIdDelete] = useState<any>(null)
  const [loadingDelete, setLoadingDelete] = useState<any>(false)
  const [listDepartment, setListDepartment] = useState<any>([])
  const [modalEdit, setModalEdit] = useState<any>(false)
  const [modalAdd, setModalAdd] = useState<any>(false)
  const [dataDetail, setDataDetail] = useState<any>(null)
  const [dataHC, setDataHC] = useState<any>({
    value: "",
    label: ""
  })

  const [listHC, setListHC] = useState<any>([])
  const [groupName, setGroupName] = useState<any>("")
  const [isEdit, setIsEdit] = useState<any>("")
  const [listMember, setListMember] = useState<any>([])
  const [keywordKaryawan, setKeywordKaryawan] = useState<any>("")
  const [listPermissions, setListPermissionUser] = useState<any>([])
  const [loadingSetPermission,  setLoadingSetPermission] = useState<any>(false)
  const [idUpdate,setIdUpdate] = useState<any>(null)

  const [appData, setData] = useState([]);
  const selectAll = () => {
    if(appData.every(el => el.checked)){
      const tempData = appData.map(el => ({ ...el, checked: false }));
      setData(tempData);
    } else {
      const tempData = appData.map(el => ({ ...el, checked: true }));
      setData(tempData);
    }    
  };

  const checkOne = id => {
    const tempData = appData.map(el => {
      if (el.employeeNo === id) {
        return { ...el, checked: !el.checked };
      }
      return el;
    });
    setData(tempData);
  };

  const customStyles = {
    control: base => ({
      ...base,
      height: 54,
      minHeight: 54
    })
  };

  const getDepartmentList = () => {
    axios.get('services/uaadocservice/api/department/list').then(res => {
      console.log('dept', res.data)
      setListDepartment(res.data)
    }).catch(err => {
      console.log(err)
    })
  }

  const LoginSchema = Yup.object().shape({
    nik: Yup.string()
    .required("NIK is required"),
    group: Yup.string()
    .required("Group is required"),
    date: Yup.string()
    .required("Date is required"),
    fullname: Yup.string()
      .min(5, "Fullname must be 5 characters at minimum")
      .required("Fullname is required"),
      
  });

  const addFormGroup = () => {
    let payload = {
      page: 0,
      limit: 20,
      keyword: ""
    }
    setModalEdit(true)

    axios.get(`/services/uaadocservice/api/department/47`).then((res) => {
      console.log('group', res)
   
  }).catch((err) => {


  })
  }
  

  useEffect(() => {
    // alert('data search', props.location.state.search)
    // alert(props.location.state.search)
    
    getMemberKaryawan()
   
  }, [page, size, totalPage]);

  const handleClose = () => {
      setShowModal(false)
  }

  
  const handleClosePermission = () => {
    setShowModalPermission(false)
}


  
    // alert('data search', props.location.state.search)
    // alert(props.location.state.search)
    
  


  const handleChange = (event) => {
    setPage(1)
    setSize(parseInt(event.target.value));
  };


  useEffect(() => {
    console.log('data search', props.location.state?.search)
    // alert(props.location.state.search)
    getDetailGroup()
    getListKaryawanHC()
    getMemberKaryawan()
    getListKaryawan()
  }, []);

  const setDefault = () => {
    setSortType('typeName.keyword,asc')
    setSortPriority('priority.keyword,asc')
    setSortLastModified('lastApprovedByName.keyword,asc')
    setSortDate('date,asc')
  }

  const getListDataKaryawan = () => {
    let pageReal = page
    setLoadingList(true)
    let payload = {
      sort: "",
      is_ascending: true,
      page: pageReal - 1,
      limit: size,
      keyword: keyword
    }
    axios.post(`/services/uaadocservice/api/group/list`, payload).then((res) => {
        console.log('group', res)
        setListKaryawan(res.data.data)
        
    }).catch((err) => {
      setLoadingList(false)

    })
  }

 


  const delayTime = (ms) => {
    return new Promise(
      resolve => setTimeout(resolve, ms)
    )
  }

  
  const getDetailGroup = async () => {
      let payload = {
          page: 0,
          limit: 10000,
          keyword: ""
      } 
      axios.post(`/services/uaadocservice/api/group/${props.match.params.id}`, payload).then( async (result) => {
        console.log('detail group', result.data)
        setDataDetail(result.data.data.data)
        setGroupName(result.data.data.data?.groupName)
        setDataHC({label: result.data.data?.data?.adminHCDetails?.fullName, value: result.data.data.data?.adminHCDetails?.employeeNo})
      }).catch(err => {
        
      })
    }

     
  const getMemberKaryawan = async () => {
    axios.get(`/services/uaadocservice/api/group/get-list-members?keyword=${keyword}&groupId=${props.match.params.id}&page=${page}&size=${size}`).then( async (result) => {
      console.log('list member', result.data)
      setListMember(result.data.data.content)
      setTotalPage(result.data.data.totalPages)
    }).catch(err => {
      setListMember([])
    })
  }


  const getPermissionUser = (id) => {
    axios.get(`/services/uaadocservice/api/group/${props.match.params.id}/get-authorization?employeeNo=${id}`).then( async (result) => {
      console.log('permission member', result.data.data.permissions)
      // setListMember(result.data.data.content)
      // setPermissionUser(id)
      setListPermissionUser(result.data.data.permissions)
      setShowModalPermission(true)
      setIdUpdate(id)
    }).catch(err => {
      // setListMember([])
      setListPermissionUser([])
      setShowModalPermission(false)
    })
  }

  const setPermissionUser = () => {
    setLoadingSetPermission(true)

    let permission = listPermissions.reduce(
      (obj, item) => Object.assign(obj, { [item.name]: item.value }), {});
      

  //   let permission = {
  //     mayCreate : true,
  //     mayApprove: false,
  //     mayAcknowledge: false,
  //     mayAnnounce: false
  // }
    axios.put(`/services/uaadocservice/api/group/${props.match.params.id}/set-authorization?employeeNo=${idUpdate}`, permission).then( async (result) => {
      console.log('permission member', result)
      toast.success('Success Update Permission', {
        position: "top-left",
        autoClose: 8000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        });
      setShowModalPermission(false)
      setLoadingSetPermission(false)
    }).catch(err => {
      toast.error('Failed Update Permission', {
        position: "top-left",
        autoClose: 8000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        });
      setLoadingSetPermission(false)
    })
  }
  

  const getListKaryawan = async () => {
    let payload = {
      sort: "fullName",
      is_ascending: true,
      page: 0,
      limit: 10000,
      keyword: keywordKaryawan
    }
    axios.post(`/services/uaadocservice/api/personal-identity/karyawan/list`, payload).then( async (result) => {
      console.log('list karyawan', result.data)
      setData(result.data.data.data)
    }).catch(err => {
      
    })
  }

    const getListKaryawanHC = () => {
      let payload = {
        page: 0,
        limit: 10000,
        keyword: ""
        }
      axios.post('services/uaadocservice/api/group/9', payload).then(res => {
        console.log('hc karyawan list', res.data.data.data.members)
        // setListDepartment(res.data)
        setListHC(res.data.data.data.members)
      }).catch(err => {
        console.log(err)
        setListHC([])
      })
    }

    const getListDataKaryawanMember = () => {
      let pageReal = page
      setLoadingList(true)
      let payload = {
        sort: "fullName",
        is_ascending: true,
        page: pageReal - 1,
        limit: 100000,
        keyword: ""
      }
      axios.post(`/services/uaadocservice/api/personal-identity/karyawan/list`, payload).then((res) => {
          console.log('member', res)
          setListMember(res.data.data.data)
         
      }).catch((err) => {
        setLoadingList(false)
  
      })
    }
  

    
  


  


  const goToPage = () => {
    setSearch('')
    history.push('/karyawan/upload')
  }

  const goToPageManual = () => {
    setSearch('')
    history.push('/karyawan/add')
  }


  const handleChangePage = (event: React.ChangeEvent<unknown>, value: number) => {
    setPage(value);
  };


  const deleteKaryawan = () => {
    let payload = {
      employeeNoList: [iddelete]
    }
    console.log(iddelete)
    console.log(payload)
    setLoadingDelete(true)
    axios.delete(`/services/uaadocservice/api/group/${props.match.params.id}/remove-member`, {data: payload}).then(res => {
      console.log(res)
      setShowModal(false)
      setIdDelete(null)
      setLoadingDelete(false)
      getMemberKaryawan()
      toast.success('Success Delete Employee', {
        position: "top-left",
        autoClose: 8000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        });
    }).catch(err => {
      console.log(err.response)
      setLoadingDelete(false)
      toast.error('Failed Delete Employee', {
        position: "top-left",
        autoClose: 8000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        });
    })
  }
 

  const submitUpdateGroup = () => {
   
    // setLoading(true)
    let payload = {
        groupName: groupName,
        adminHC: dataHC.value
    }
    axios.put(`/services/uaadocservice/api/group/${props.match.params.id}/update`, payload).then(res => {
      // console.log(res.data.status)
      toast.success('Success Update Group', {
        position: "top-left",
        autoClose: 8000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        });
        
        getDetailGroup()
        setIsEdit(false)
    }).catch(err => {
      toast.error('Failed Update Group', {
        position: "top-left",
        autoClose: 8000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        });
    })
  }


  const submitUpdateHC = (id) => {
   
    // setLoading(true)
    let payload = {
        adminHC: id
    }
    axios.put(`/services/uaadocservice/api/group/${props.match.params.id}/update`, payload).then(res => {
      // console.log(res.data.status)
      toast.success('Success Update Group', {
        position: "top-left",
        autoClose: 8000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        });
        
        getDetailGroup()
        setIsEdit(false)
    }).catch(err => {
      toast.error('Failed Update Group', {
        position: "top-left",
        autoClose: 8000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        });
    })
  }


  const changeValue = (e) => {
      console.log(e)

      let updatedList = listPermissions.map(item => 
        {
          if (item.name == e.name){
            return {...item, value: !e.value}; //gets everything that was already in item, and updates "done"
          }
          return item; // else return unmodified item 
        });
    
      setListPermissionUser(updatedList)
  }

  const submitKaryawanToGroup = () => {
   
    // setLoading(true)
    
    console.log(appData)
    let employeeNoList = []

    appData.map((item, index) => {
      if (item.checked == true) {
        employeeNoList.push(item.employeeNo)
      }
    })
    let payload = {
      employeeNoList: employeeNoList
    }

    axios.post(`/services/uaadocservice/api/group/${props.match.params.id}/add-member`, payload).then(res => {
      // console.log(res.data.status)
      toast.success('Success add karyawan to Group', {
        position: "top-left",
        autoClose: 8000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        });
        setModalAdd(false)
        getMemberKaryawan()
        getListKaryawan()
    }).catch(err => {
      toast.error('Success Add Karyawan To Group', {
        position: "top-left",
        autoClose: 8000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        });
    })
  }



 
  return (
    <div className="px-5 pt-4" style={{height: '100%'}}>



            <button className="btn px-0 mb-4" onClick={() => history.goBack()}>
            <FontAwesomeIcon size="2x" icon="arrow-left" color="#0A0A0A" />{' '}
            </button>
              <div className="card bg-white py-3 px-3"> 
                <div className="row">
                  <div className="col-11">
                      {
                        isEdit ?  <input type="text" value={groupName} className="form-control bg-white py-4" onKeyDown={(e) => {
                          if(e.keyCode == 13) {
                             submitUpdateGroup()
                          }
                        }} placeholder="" onChange={(e) => {
                            setGroupName(e.target.value)
                        }} />:   <h3 className="mb-0">
                        {groupName}
                        </h3>
                      }
                  </div>

                  <div className="col-1 text-center" style={{cursor: 'pointer', display: 'flex', alignItems: 'center', justifyContent: 'center'}} onClick={() => {
                     setIsEdit(!isEdit)
                     getDetailGroup()
                  }}>
                      <EditBlue />
                  </div>
                </div>
              </div>

               <div className="form-group my-5">
                  <h5 className="text-xl">Admin HC</h5>
                    <Select
                      name="group"
                      styles={customStyles}
                      placeholder="Find Name Or Division"
                      value={dataHC}
                      defaultValue={dataHC}
                      options={listHC.map(e => ({ label: e.employee.fullName, value: e.employee.employeeNo}))}
                      onChange={newValue => {
                        setDataHC(newValue)
                        submitUpdateHC(newValue.value)
                      }}
                    />
                </div>

              

          <div className='row my-3'>
        
              <div className="col-12 col-md-2 col-lg-2 px-2">
                  <div className='btn btn-primary w-100 py-2' onClick={(data) => setModalAdd(true)}>
                  <UserAddKaryawanIcon/> Add Personel
                  </div>
              </div>
              <div className="col-12 col-md-10 col-lg-10 px-0">
                  <div className="d-flex px-3" style={{ flexGrow: 10 }}>
                      <div className="app-search w-100 py-0 bg-white">
                        <div className="position-relative">
                          <input type="text" className="form-control bg-white py-3" placeholder="Cari member" onChange={(e) => {
                            if(e.target.value === '') {
                              setKeyword(e.target.value)
                              getMemberKaryawan()
                            } else {
                              setKeyword(e.target.value)
                            }
                          }} onKeyPress={(e) => {
                            console.log(e.key)
                            if(e.key == 'Enter') {
                          
                              getMemberKaryawan()
                            }
                          }}/>
                          <span className="bx bx-search-alt my-0"></span>
                        </div>
                      </div>
                    </div>
                  </div>
          </div>

          <div className="table-responsive mt-4">
                        <table className="table table-striped table-centered  mb-0">
                          <thead className="thead-light">
                      <tr>
                      
                        <th className='py-3'>Employee Number</th>
                        <th>Full Name</th>
                        <th>Email</th>
                        <th>Permission</th>
                        <th className='text-center'>Action</th>
                      </tr>
                    </thead>
                    <tbody>
                    

                       {
                        listMember ? listMember.map((item, index) => {
                          return (
                            <tr>
                            <td className='py-2' style={{wordBreak: 'break-all'}}>{item.employeeNo?? "-"}</td>
                            <td style={{wordBreak: 'break-all'}}>{item.fullName ?? "-"}</td>
                            <td style={{wordBreak: 'break-all'}}>{item.email}</td>
                            <td style={{wordBreak: 'break-all'}}>
                              <button className='btn text-info' onClick={() => getPermissionUser(item.employeeNo)}>
                                  Edit Permission
                              </button>
                            </td>
                            <td style={{wordBreak: 'break-all', width: '10%'}}>
                              <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'space-evenly'}}>
                                <div style={{cursor: 'pointer'}} className="mx-2">
                                    <DeleteRed onClick={() => {
                                      setShowModal(true)
                                      setFullName(item.fullName)
                                      setIdDelete(item.employeeNo)
                                    }}/>
                                </div>
                              </div>
                            </td>
                          </tr>         
                          ) 
                        }) : <></>
                      }       
                    </tbody>
                  </table>
                </div>

  
      <div className="row mt-4">
      <div className="col-lg-7 col-sm-12 col-xs-12 my-2">
            <div style={{display: 'flex'}}>
              <span className="mt-3 mx-2">
                Show
              </span>
              <span>
              <FormControl variant="standard" sx={{ m: 1, minWidth: 50}}>
            
            <SelectMui
              labelId="demo-simple-select-standard-label"
              id="demo-simple-select-standard"
              value={size}
              onChange={handleChange}
              label="Limit"
            >
              <MenuItem value={5}>5</MenuItem>
              <MenuItem value={10}>10</MenuItem>
              <MenuItem value={20}>20</MenuItem>
              <MenuItem value={30}>30</MenuItem>
            </SelectMui>
          </FormControl>
              </span>
              <span className="mt-3 mx-2">
                Entries
              </span>
            </div>
        </div>
        
         <div className="col-lg-5 col-sm-12 col-xs-12 my-2" style={{display: 'flex', justifyContent: 'end'}}>
              <Pagination count={totalPage} color="primary" page={page} showFirstButton showLastButton onChange={handleChangePage}/>
        </div>
      </div>
      <Modal isOpen={modalAdd} toggle={handleClose} size="xl" style={{marginTop: '15%'}}>
        <div className="my-4">
        <div className="px-5 pt-4" style={{height: '100%'}}>
          <div className="row">
          <div className="col-12">
            <div className="page-title-box d-flex align-items-center justify-content-between pb-0">
              <h1 className="mb-0 font-size-28">Tambah Personel</h1>
            </div>
            <div className="page-title-box d-flex align-items-center justify-content-between">
              {/* <h1 className="mb-0 font-size-14">Lorem ipsum dolor sit amet, consectetur adipiscing elit</h1> */}
            </div>
          </div>
          </div>
          <div className=""> 
          <div className="col-12 col-md-12 col-lg-12 px-0 mb-4">
                  <div className="d-flex" style={{ flexGrow: 10 }}>
                      <div className="app-search w-100 py-0 bg-white" >
                        <div className="position-relative">
                          <input type="text" className="form-control  py-3" style={{background: '#EDEDED'}} placeholder="Cari Karyawan" onChange={(e) => {
                            if(e.target.value === '') {
                              setKeywordKaryawan(e.target.value)
                              getListKaryawan()
                            } else {
                              setKeywordKaryawan(e.target.value)
                            }
                          }} onKeyPress={(e) => {
                            console.log(e.key)
                            if(e.key == 'Enter') {
                             
                                getListKaryawan()
                            }
                          }}/>
                          <span className="bx bx-search-alt my-0"></span>
                        </div>
                      </div>
                    </div>
                  </div>
          <div className="table-wrapper-scroll-y" style={{height: '400px', overflowY: 'scroll'}}>
                <table className="table" >
                  <thead>
                    <tr>
                      <th className='' style={{background: '#E0E0E0', position: 'sticky', top: 0,}}>Employee No</th>
                      <th style={{background: '#E0E0E0',position: 'sticky', top: 0}}>Fullname</th>
                      <th style={{background: '#E0E0E0',position: 'sticky', top: 0}}>Email</th>
                      <th className='text-center' style={{background: '#E0E0E0',position: 'sticky', top: 0}}>
                      
                        <input
                          type="checkbox"
                          checked={appData.every(el => el.checked)}
                          onChange={selectAll}
                        />
                      </th>
                    
                    </tr>
                  </thead>
                  <tbody style={{height: '400px', overflowY: 'scroll', overflowX: 'hidden'}}>
                    {appData.map(el => (
                      <tr key={el.employeeNo}>
                        <td>{el.employeeNo}</td>
                        <td>{el.fullName}</td>
                        <td>{el.email}</td>
                        <td className='text-center'>
                          <input
                            type="checkbox"
                            checked={el.checked}
                            onChange={() => checkOne(el.employeeNo)}
                          />
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
              <Row className='mb-4 mt-4'>
                        <Col className='text-right' xs="12" sm="12" md="6" lg="6">
                        </Col>
                        <Col className='text-right' xs="12" sm="12" md="6" lg="6">
                        <div className='btn btn-primary px-5 mr-2' style={{background: '#002F48', borderColor: '#002F48'}} onClick={() => setModalAdd(false)}>
                            {loadingSaveKaryawan ? <Spinner color='#fff' size="sm" className='mr-1' /> : <></>}
                           Cancel </div>
                          <button className='btn btn-primary px-5' type="submit" disabled={!appData.some( item => item.checked == true )} onClick={() => submitKaryawanToGroup()}>
                            {loadingSaveKaryawan ? <Spinner color='#fff' size="sm" className='mr-1' /> : <></>}
                            Save</button>
                        </Col>
                  </Row>
          </div>
            </div>
              </div>
              
          </Modal>

          <Modal isOpen={showModalPermission} toggle={handleClosePermission} size="lg" style={{marginTop: '15%'}}>
        <div className="my-4">
        <div className="px-5 pt-4" style={{height: '100%'}}>
          <div className="row">
          <div className="col-12">
            <div className="page-title-box d-flex align-items-center justify-content-between pb-0">
              <h1 className="mb-0 font-size-28">Permission Personel</h1>
            </div>
            <div className="page-title-box d-flex align-items-center justify-content-between">
              {/* <h1 className="mb-0 font-size-14">Lorem ipsum dolor sit amet, consectetur adipiscing elit</h1> */}
            </div>
          </div>
          </div>
          <div className=""> 
          <div className="table-wrapper-scroll-y" style={{height: '400px', overflowY: 'scroll'}}>
                <table className="table" >
                  <thead>
                    <tr>
                      <th className='' style={{background: '#E0E0E0', position: 'sticky', top: 0, zIndex: 99}}>Permission Name</th>
                      <th style={{background: '#E0E0E0',position: 'sticky', top: 0, zIndex: 99}}>Status</th>
                    </tr>
                  </thead>
                  <tbody style={{height: '400px', overflowY: 'scroll', overflowX: 'hidden'}}>
                    {listPermissions.map(el => (
                      <tr key={el}>
                          <td>{el.name}</td>
                          <td><Toggle
                            defaultChecked={el.value}
                            aria-label='No label tag'
                            onChange={(e) => changeValue(el)}/></td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
              <Row className='mb-4 mt-4'>
                        <Col className='text-right' xs="12" sm="12" md="6" lg="6">
                        </Col>
                        <Col className='text-right' xs="12" sm="12" md="6" lg="6">
                        <div className='btn btn-primary px-5 mr-2' style={{background: '#002F48', borderColor: '#002F48'}} onClick={() => setShowModalPermission(false)}>
                            {loadingSetPermission ? <Spinner color='#fff' size="sm" className='mr-1' /> : <></>}
                           Cancel </div>
                          <button className='btn btn-primary px-5' type="submit" onClick={() => setPermissionUser()}>
                            {loadingSetPermission ? <Spinner color='#fff' size="sm" className='mr-1' /> : <></>}
                            Save</button>
                        </Col>
                  </Row>
          </div>
            </div>
              </div>
              
          </Modal>


      <Modal isOpen={modalEdit} toggle={handleClose} size="lg" style={{marginTop: '15%'}}>
        <div className="my-4">
        <div className="px-5 pt-4" style={{height: '100%'}}>
          <div className="row">
          <div className="col-12">
            <div className="page-title-box d-flex align-items-center justify-content-between pb-0">
              <h1 className="mb-0 font-size-28">Edit Data Group</h1>
            </div>
            <div className="page-title-box d-flex align-items-center justify-content-between">
              {/* <h1 className="mb-0 font-size-14">Lorem ipsum dolor sit amet, consectetur adipiscing elit</h1> */}
            </div>
          </div>
          </div>

<div className="row">
 
  <Formik
        initialValues={{ group_name: "", adminHC: ""}}
        validationSchema={LoginSchema}
        onSubmit={(values) => {
          console.log(values);
          // alert("Form is validated! Submitting the form...");
          // submitDataKaryawan(values)
        }}
      >
        {({ touched, errors, isSubmitting, values, setFieldValue}) =>
          (
            <div>
              <Form>
               <div className="">
               <div className="form-group">
                  <h5 className="text-xl">Nama Group</h5>
                  <Field
                    type="text"
                    name="group_name"
                    placeholder=""
                    autocomplete="off"
                    className={`mt-2 form-control py-4
                    ${touched.group_name && errors.group_name ? "is-invalid" : ""}`}
                  />

                  <ErrorMessage
                    component="div"
                    name="group_name"
                    className="invalid-feedback"
                  />
                </div>

             

                
                

                <div className="form-group">
                  <h5 className="text-xl">Admin HC</h5>
                  <Select
                    name="group"
                    className={`
                    ${touched.adminHC && errors.adminHC ? "is-invalid" : ""}`}
                    styles={customStyles}
                    placeholder="Find Name Or Division"
                    options={listDepartment.map(e => ({ label: e.deptName, value: e.deptCode}))}
                    onChange={newValue => setFieldValue('group', newValue)}
                  />
                 <div className="text-danger " style={{fontSize: '10px'}}>
                     {touched.adminHC && errors.adminHC ? errors.adminHC : ""}
                  </div>
                </div>
                </div>
             
                
                  <Row className='mb-4 mt-4'>
                        <Col className='text-right' xs="12" sm="12" md="6" lg="6">
                        </Col>
                        <Col className='text-right' xs="12" sm="12" md="6" lg="6">
                        <div className='btn btn-primary px-5 mr-2' style={{background: '#002F48', borderColor: '#002F48'}} onClick={() => setModalEdit(false)}>
                            {loading ? <Spinner color='#fff' size="sm" className='mr-1' /> : <></>}
                           Cancel </div>
                          <button className='btn btn-primary px-5' type="submit">
                            {loading ? <Spinner color='#fff' size="sm" className='mr-1' /> : <></>}
                            Save</button>
                        </Col>
                  </Row>
              </Form>
           

            </div>
          )
        }
      </Formik>
  
</div>







</div>
        </div>
        
    </Modal>

    <Modal isOpen={showModal} toggle={handleClose} size="md" style={{marginTop: '15%'}}>
      <ModalHeader toggle={handleClose}>
            <div className="w-100">
              <h2 className="">Delete Confirmation</h2>
              <h3 style={{fontSize: '12px'}}>{`Apakah anda yakin menghapus User Karyawan ${fullname} dari group`}</h3>
            </div>
      </ModalHeader>
        <div className="my-4">
          <Row className='w-100'>
              <Col lg={12} className="text-right">
                <Button color="secondary" onClick={handleClose} className="mr-2">
                  <Translate contentKey="entity.action.cancel">Cancel</Translate>
                </Button>
                <Button  color="success px-4" onClick={() => deleteKaryawan()} disabled={loadingDelete}>
                {loadingDelete ? <Spinner color='#fff' size="sm" className='mr-1' /> : <></>} Yes
                </Button>
              </Col>
            </Row>             
        </div>
        
    </Modal>
    </div> 
  );
};

const mapStateToProps = ({ document }: IRootState) => ({
 
});


const mapDispatchToProps = {
 
};
type StateProps = ReturnType<typeof mapStateToProps>
type DispatchProps = typeof mapDispatchToProps;;

export default connect(mapStateToProps, mapDispatchToProps)(GroupDetail);
