import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { Link, RouteComponentProps } from 'react-router-dom';
import { Button, InputGroup, Col, Row, Table } from 'reactstrap';
import { AvForm, AvGroup, AvInput } from 'availity-reactstrap-validation';
import { Translate, translate, ICrudSearchAction, ICrudGetAllAction, TextFormat } from 'react-jhipster';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { IRootState } from 'app/shared/reducers';
import { getSearchEntities, getEntities, getEntitiesByEmpId, getDocumentDetail, postDocument } from './../document/document.reducer';
import { IDocument } from 'app/shared/model/document.model';
import { APP_DATE_FORMAT, APP_LOCAL_DATE_FORMAT } from 'app/config/constants';

import axios from 'axios';
import { BlobProvider, pdf, PDFViewer } from '@react-pdf/renderer';
import PreviewPDFComponent from 'app/modules/pdf/preview';

export interface IDocumentProps extends StateProps, DispatchProps, RouteComponentProps<{ url: string }> { }


export interface Props {
  getDocumentDetail: any,
  match: any,
  viewPDF: any,
  detaildocument: any,
  signDocument: any,
  approveDocument: any,
  blobPreview: any,
  filePDFSign: any,
  rejectDocument: any,
  PreviewPDFComponent: any,
  postDocument: any
}


export const Preview: React.FC<Props> = (props) => {
  const [search, setSearch] = useState('');
  const [fileblob, setFileblob] = useState(null);


  useEffect(() => {
    // console.log('isian', props.match.params.id)
    // props.getDocumentDetail();
  }, []);

  const submit = () => {
    pdf(<PreviewPDFComponent
      from={props.detaildocument?.senderBranchName}
      title={props.detaildocument?.fileNumber}
      regarding={props.detaildocument?.regarding}
      date={props.detaildocument?.date}
      to={props.detaildocument?.to}
      fileContent={props.detaildocument?.fileContent}
      cc={props.detaildocument?.cc}
      dataDocument={props.detaildocument}
    />).toBlob().then(res => {
      // console.log(res)
      props.postDocument(res)
    })
    // props.postDocument(fileblob)
  }


  let datadetail = props.detaildocument
  // let datafinal = JSON.parse(datadetail)
  //  console.log(datadetail)
  //  console.log(props)

  //  let hihi = ""
  let fileContent = sessionStorage.getItem('fileContent');

  let type = sessionStorage.getItem('type');
  let priority = sessionStorage.getItem('priority');
  let documentSecurity = sessionStorage.getItem('documentSecurity');
  var to = sessionStorage.getItem('to');
  var to_parsed = JSON.parse(to)

  var to_data = []
  //  console.log("########data to");
  //  for (var i = 0; i < to_parsed.length; i++) {
  //   // console.log(to_parsed[i]['branchCode']);
  //   to_data.push(to_parsed[i]['branchCode'])

  // }
  to_data.push("112");
  to_data.push("113");
  console.log("########data to");
  console.log(to_parsed);



  let cc = sessionStorage.getItem('cc');
  let regarding = sessionStorage.getItem('regarding');
  let date = sessionStorage.getItem('date');
  let expDate = sessionStorage.getItem('expDate');
  let approval = sessionStorage.getItem('approval');
  let acknowledge = sessionStorage.getItem('acknowledge');

  let to_s = "to wkwkkw"
  let cc_s = "cc wkwkkw"
  let app_s = "app wkwkkw"
  let ack_s = "ack wkwkkw"
  let from_s = "from wkwkkw"
  let title_s = "null"
  




  // console.log("########data to");
  //  console.log(to_data);
  //  console.log(hihi2);

  //  const getData = () => {
  //   console.log('Our data is fetched');
  //   console.log(localStorage.getItem('fileContent'));
  //   let hihi = localStorage.getItem('fileContent');
  //   // useState({
  //   //   fileContent:  localStorage.getItem('fileContent'),

  //   // })


  //   // setTimeout(() => {

  //   // }, 1000)
  // }



  return (
    <div className="container">
      <Row>
        <Col>
          <h2>Preview</h2>
          <p>File Preview</p>
        </Col>


        {/* <Col className='text-right'>
            <button className='btn btn-primary mr-2'>Kembali</button>
            <button className='btn btn-primary' onClick={() => submit()}>Print Document</button>
          </Col> */}
      </Row>

      <h2>Under Contruction</h2>


      {/* <PDFViewer style={{ width: '100%', height: '1000px' }} >
        <PreviewPDFComponent
          from={from_s}
          title={title_s}
          regarding={regarding}
          date={date}
          to={to_s}
          // to={to_data}
          fileContent={fileContent}
          cc={cc_s}
          dataDocument={app_s}
        />
      </PDFViewer> */}

      {/* <div>
        <BlobProvider document={
          <PreviewPDFComponent
            from={props.detaildocument?.senderBranchName}
            title={props.detaildocument?.fileNumber}
            regarding={props.detaildocument?.regarding}
            date={props.detaildocument?.date}
            to={props.detaildocument?.to}
            fileContent={fileContent}
            cc={props.detaildocument?.cc}
            dataDocument={props.detaildocument}
          />
        }>
          {({ blob, url, loading, error }) => {
            // Do whatever you need with blob here
            // console.log(blob)
            // setFileblob(blob)
            return <div>There's something going on on the fly</div>;
          }}
        </BlobProvider>
      </div> */}
    </div>
  );
};

const mapStateToProps = ({ document }: IRootState) => ({
  documentList: document.entities,
  loading: document.loading,
  detaildocument: document.detaildocument
});

const mapDispatchToProps = {
  // getSearchEntities,
  // getEntities,
  // getEntitiesByEmpId,
  // getDocumentDetail,
  // postDocument
};

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(mapStateToProps, mapDispatchToProps)(Preview);
