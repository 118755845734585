import React from 'react';
import { Switch } from 'react-router-dom';

import ErrorBoundaryRoute from 'app/shared/error/error-boundary-route';

// import Document from './document';
// import DocumentDetail from './document-detail';
// import DocumentUpdate from './document-update';
// import DocumentDeleteDialog from './document-delete-dialog';
import './../../../content/css/loading.css'
import './../../../content/css/miraeloading.css'
import preview from './preview';
import previewapproval from './previewapproval';
import previewdraft from './previewdraft';
import previewacknowledge from './previewacknowledge';
import DocumentPreview from './document-preview'
import previewnewbackend from './previewnewbackend';

const Routes = ({ match }) => (
  <>
    <Switch>
      {/* <ErrorBoundaryRoute exact path={`${match.url}/new`} component={DocumentUpdate} />
      <ErrorBoundaryRoute exact path={`${match.url}/:id/edit`} component={DocumentUpdate} />
      <ErrorBoundaryRoute exact path={`${match.url}/:id`} component={DocumentDetail} /> */}
      <ErrorBoundaryRoute path={`${match.url}/pdf/:id`} component={preview} />
      <ErrorBoundaryRoute path={`${match.url}/preview/pdf/send`} component={preview} />
      <ErrorBoundaryRoute path={`/preview/document/pdf`} component={previewnewbackend} />
      <ErrorBoundaryRoute path={`${match.url}/approval/pdf/:id`} component={previewapproval} />
      <ErrorBoundaryRoute path={`${match.url}/draft/pdf/:id`} component={previewdraft} />
      <ErrorBoundaryRoute path={`${match.url}/only/pdf/:id`} component={previewacknowledge} />
      <ErrorBoundaryRoute path={`${match.url}/new`} component={DocumentPreview} />
    </Switch>
    {/* <ErrorBoundaryRoute exact path={`${match.url}/:id/delete`} component={DocumentDeleteDialog} /> */}
  </>
);

export default Routes;
