import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { Redirect, RouteComponentProps } from 'react-router-dom';

import { IRootState } from 'app/shared/reducers';
import { login } from 'app/shared/reducers/authentication';
import { Brand, BrandIcon } from 'app/shared/layout/header/header-components';
import Accordion from 'react-bootstrap/Accordion'
import './document.scss'
import UserOutline from 'app/component/user-outline';
import ChecklistGreenIcon from 'app/component/checklist';
export interface ILoginProps extends StateProps, DispatchProps, RouteComponentProps<{}> {}
import axios from 'axios'
import moment from 'moment';
import { render } from '@headlessui/react/dist/utils/render';
import useWindowDimensions from 'app/utils/ScreenDimesions';

export interface Props {
  history: any,
  match: any
}


export const DocumentVerifiedPrivate = (props: Props) => {
  const { height, width } = useWindowDimensions();
  const [data, setData] = useState<any>(null)

  useEffect(() => {
   
    getFileHistories()
  }, []);

 

 

  const getFileHistories = () => {
    axios.get(`/services/documentservice/api/file-histories/public/${props.match.params.id}`).then(res => {
      console.log('ini data',res.data.data)
      setData(res.data.data)
    }).catch(err => {

    })
  }

  const renderStatusFileHistories = (status, statusName) => {
       if (status == "CREATED" || status == "REVISE") {
          return <span className="text-mirae">{statusName}</span>
       } else if(status == 'APPROVE' || status == 'APPROVE_REVISED_VERSION') {
          return <span className='text-success'>{statusName}</span>
       } else {
        return <span className='text-danger'>{statusName}</span>
       }
  }

  return <>
    <div className='container' style={width < 995 ? {height: '100%'} : {height: '100%', marginLeft: '25%'}}>
        <div className='text-center mt-5'>
          <img src="content/images/logo-mirae.webp" style={{height: 70}} alt="Logo"/>
        </div>

       <div className='mt-5'>
         <h3 className='font-weight-bold'>Riwayat Dokumen</h3>
         <h5 className='font-weight-light font-italic'>Document History</h5>
       </div>

       <div className='mt-4'>
         <h3 className='font-weight-bold'>PT Mirae Asset Sekuritas Indonesia Menyatakan Bahwa : </h3>
         <h5 className='font-weight-light font-italic'>PT Mirae Asset Sekuritas Indonesia states that :</h5>
       </div>


       <div className='mt-5 px-4 py-4 rounded-lg shadow-sm'  style={{background: '#F5FCFF'}}>
          <div>
            <h5 className='font-weight-bold'>Nama Dokumen <span className='font-italic font-weight-light'> / Document Title :</span></h5>
            <h4 className='font-weight-bold mt-3 ' style={{wordWrap: 'break-word'}}>
            {data?.documentTitle}
            </h4>
          </div>

          <div className='mt-5'>
            <h5 className='font-weight-bold'>Nomor Seri <span className='font-italic font-weight-light'> / Serial Number :</span></h5>
            <h4 className='font-weight-bold mt-3' style={{wordWrap: 'break-word'}}>
            {data?.serialNumber}
            </h4>
          </div>

          <div className='mt-5'>
            <h5 className='font-weight-normal'>Telah ditandatangani oleh pengguna Mirae Asset sebagai berikut : </h5>
            <h5 className='font-weight-light font-italic'>Has been signed by Mirae Asset user as follows : </h5>
          </div>
          

          <Accordion defaultActiveKey={["0"]} alwaysOpen>
            {data?.fileHistories.map((item, index) => {
              return <Accordion.Item eventKey={index}>
                <Accordion.Header><div className='row'  style={{wordWrap: 'break-word'}}>
                  <div className="col-xs-12 col-sm-9 col-md-1 mx-auto text-center">
                  <UserOutline/>
                  </div>
                  <div className="col-xs-12 col-sm-3 col-md-11 mt-1">
                      <span className='font-weight-bold ml-1' style={{wordBreak: 'break-word'}}>{item?.name} ({item?.uniqueCode})</span>
                  </div>
                  </div></Accordion.Header>
                <Accordion.Body>
                  <div className='mt-4'>
                    <p className='font-weight-normal text-gray mb-2' style={{color: '#9E9E9E'}}>Timestamp : </p>
                    <h5 className='font-weight-normal' style={{wordBreak: 'break-word'}}>{item?.timestampFormat}</h5>
                  </div>

                  <div className='mt-4'>
                    <p className='font-weight-normal text-gray mb-2' style={{color: '#9E9E9E'}}>Status : </p>
                    <h5 className='font-weight-normal' style={{wordBreak: 'break-word'}}>{renderStatusFileHistories(item?.status, item?.statusName)}</h5>
                  </div>

                  <div className='mt-4' style={{minHeight: '80px'}}>
                    <p className='font-weight-normal text-gray mb-2' style={{color: '#9E9E9E'}}>Notes : </p>
                    <h5 className='font-weight-normal' style={{wordBreak: 'break-word'}}>{item?.notes}</h5>
                  </div>

                </Accordion.Body>
                </Accordion.Item>
            })}


          
          </Accordion>

          <div className='mt-5 mb-5'>
            <div className="row">
              <div className="col-12 col-lg-1 col-md-1 py-3" style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                  <ChecklistGreenIcon />
              </div>
              <div className='col-12 col-lg-11 col-md-1 px-0'>
                <div className=''>
                  <h4 className='font-weight-bold text-gray mb-2' >Adalah benar dan tercatat dalam audit trail kami. </h4>
                  <h5 className='font-weight-normal' style={{color: '#9E9E9E'}}>That is true and it is recorded in our audit trail.</h5>
                </div>
              </div>
            </div>
          </div>
          <hr />
          <div className='mt-5'>
            <h4 className='font-weight-normal text-center' style={{wordBreak: 'break-word'}}> Untuk memastikan kebenaran pernyataan ini pastikan URL pada browser anda adalah https://sekuritas.miraeasset.co.id/</h4>
            <h4 className='font-weight-light font-italic text-center mt-3'  style={{wordBreak: 'break-word'}}>If you wish to check the validity of this statement, please ensure the URL of your browser is https://sekuritas.miraeasset.co.id/ </h4>
          </div>

       </div>
    </div>
  </>
};

const mapStateToProps = ({ authentication }: IRootState) => ({
  isAuthenticated: authentication.isAuthenticated,
  loginError: authentication.loginError,
  showModal: authentication.showModalLogin,
});

const mapDispatchToProps = { login };

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(mapStateToProps, mapDispatchToProps)(DocumentVerifiedPrivate);
