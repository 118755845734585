import * as React from "react"
import { SVGProps } from "react"

const BottomSort = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width={10}
    height={6}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M.293.293a1 1 0 0 1 1.414 0L5 3.586 8.293.293a1 1 0 0 1 1.414 1.414l-4 4a1 1 0 0 1-1.414 0l-4-4a1 1 0 0 1 0-1.414Z"
      fill="#0A0A0A"
    />
  </svg>
)

export default BottomSort
