import React, { Component } from 'react';
import {Button, ButtonProps, Spinner} from 'reactstrap';

const DarkPurpleButton = (props: ButtonProps) => {
    return (
        <Button disabled={props.disabled} onClick={props.onClick} type="submit" className="py-2 h2 text-white font-weight-bold rounded"
            style={
                {
                    backgroundColor: '#0F2D3C', 
                    width: (props.width != null)?props.width:'100%',
                }
            }>
            {(props.disabled)? <Spinner color='#fff' size="sm" style={{marginRight: "8px"}}/> : <></>}
            {props.text}
        </Button>
    )
}

export default DarkPurpleButton;