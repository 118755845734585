import React, { useState, useEffect, useRef } from 'react';
import { connect } from 'react-redux';
import { Link, RouteComponentProps } from 'react-router-dom';
import { Button, Row, Col, Label, Spinner } from 'reactstrap';
import { AvFeedback, AvForm, AvGroup, AvInput, AvField } from 'availity-reactstrap-validation';
import { Translate, translate, ICrudGetAction, ICrudGetAllAction, ICrudPutAction } from 'react-jhipster';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IRootState } from 'app/shared/reducers';
import { v4 as uuidv4 } from 'uuid';
import Select from 'react-select'


import {
  getEntity,
  updateEntity,
  createEntity,
  reset,
  getDocumentDetail,
  getUserList,
  getApprovalList,
  getAcknowledgeList,
  getDeptList,
  getListDocumentCode,
  sendNewDocument,
  saveDocumentToDraft
}
  from './document.reducer';
import { IDocument, IBranchCode, IApproval } from 'app/shared/model/document.model';
import { convertDateTimeFromServer, convertDateTimeToServer, displayDefaultDateTime } from 'app/shared/util/date-utils';
import { mapIdList } from 'app/shared/util/entity-utils';
import documentPreview from '../preview/document-preview';
import { Editor } from '@tinymce/tinymce-react';

import { MultiSelect } from "react-multi-select-component"
import ArrowRightIcon from 'app/component/arrow-right-icon';
import ArrowRightIconWhite from 'app/component/arrow-right-icon-white';
import SignatureIcon from 'app/component/signature-icon';
import SignatureWhiteCreateFile from 'app/component/signature-white-create-file';
import SaveDraftIcon from 'app/component/save-draft-icon';
import { stringify } from 'querystring';

import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css'

import axios from 'axios'

import { toast } from 'react-toastify';

import './document-update.scss';
import moment from 'moment-timezone';
import { isEmpty } from 'lodash';
import UploadFileIcon from 'app/component/upload-icon';
import { faTrash } from '@fortawesome/free-solid-svg-icons';
import { Item } from 'react-bootstrap/lib/Pagination';
import { type } from 'os';
import { documentSecurity, priorityDocument, typeDocument } from './dataMock';
import Axios from 'axios';
import { b64toBlob, bytesToSize, isValidFileUploaded, replaceP, validateSize, validateUrl } from './utils';
import { DocumentForm } from './type';
import { red } from '@mui/material/colors';
import approval from '../approval/approval';



export interface Props {
  getDocumentDetail?: any,
  detaildocument?: any,
  location: {
    state?: {
      file: any,
      data: any,
      link: any
    }
  }
}





export interface IDocumentUpdateProps extends StateProps, DispatchProps, RouteComponentProps<{ id: string }> { }

export const DocumentCreate = (props: any, editMode = true) => {
 
  const {
    documentEntity,
    loading,
    updating,
    deptList,
    userList,
    detaildocument,
    loadingDepartement,
    loadingDetail,
    approvalList,
    documentListCode,
    acknowledgeList
  } = props;

  const initialFormData = {
    id : "0",
    type : "",
    priority : "", 
    documentSecurity : "", 
    date: new Date(),
    expDate : new Date(),
    regarding : "",
    fileContent : "", 
    to : [],
    cc : [],
    acknowledge : [], 
    approval : []
  }

  const initialDocumentNo = {
    documentCode: null,
    documentNumber: null,
    groupId: null
  }
 
  const editorRef = useRef(null);
  const [listAttachment, setListAttachments] = useState([])
  const [listFile, setListFile] = useState([])
  const [inputValue, setInputValue] = useState("")
  const [loadingLink, setLoadingLink] = useState(false)
  const [formData, setFormData] = useState(initialFormData)
  const [formDocumentNo, setFormDocumentNo] = useState<DocumentForm>(initialDocumentNo)
  const [groupListDocument, setGroupListDocument] = useState([])
  const [departmentList, setDepartmentList] = useState([])

  const [listApproval, setListApproval] = useState([])
  const [listAcknowledge, setListAcknowledge] = useState([])
  const [initialValue, setInitialValue] = useState(undefined);
  const [loadingSaveAsDraft, setLoadingSaveAsDraft] = useState(false)
  const [loadingNextStep, setLoadingNextStep] = useState(false)
  const [fileStatus, setFileStatus] = useState("")
  


  const getDetailDocument = (id) => {
    axios.get(`/services/documentservice/api/documents/${id}`).then(res => {
      handleEditByStatus(res.data.data)
    }).catch(err => {

    })
  }


  const handleEditByStatus = (data) => {
    setFileStatus(data.fileStatus)
  

    if(data.fileStatus == 'DRAFT') {
      console.log('detail', data)
      let approvalRespon = [] 
      let ackRespon = []
      let toRespon = []
      let ccRespon = []
      let attachments = []
      data.approval.map((item) =>  {
        approvalRespon.push({label: item.employeeName, value: item.employeeNo})
      })
      data.acknowledgeList.map((item) =>  {
        ackRespon.push({label: item.employeeName, value: item.employeeNo})
      })
  
      data.cc.map((item) => {
        ccRespon.push({label: item.branchName, value: item.branchCode})
      })
  
      data.to.map((item) => {
        toRespon.push({label: item.branchName, value: item.branchCode})
      })

      data.fileAttachment.map((item) => {
        attachments.push({id: item.attachmentId, value: item.attachmentName})
      })

      setFormData({
        ...formData,
        id: data.id,
        priority: data.priority,
        type: data.type,
        documentSecurity: data.documentSecurity,
        regarding: data.regarding,
        fileContent: data.fileContent,
        date: new Date(),
        expDate: subtractHours(-7, new Date(data.expDate)),
        acknowledge: ackRespon,
        approval: approvalRespon,
        to: toRespon,
        cc: ccRespon
      })
      setInitialValue(data.fileContent)
      Axios.get('/services/uaadocservice/api/group/get-list-document-code').then(res => {
        console.log('document no list', res.data)
        if(res.data.filter((x) => x.id === data.groupId).length > 0) {
          generateDocumentNumberByGroup(data.groupId)
        }
        setGroupListDocument(res.data)
      }).catch(err => {
        setGroupListDocument([])
      })
      
   
      // setListFile(attachments)
     
    } else {
      console.log('detail', data)
      let approvalRespon = [] 
      let ackRespon = []
      let toRespon = []
      let ccRespon = []
      let attachments = []
      data.approval.map((item) =>  {
        approvalRespon.push({label: item.employeeName, value: item.employeeNo})
      })
      data.acknowledgeList.map((item) =>  {
        ackRespon.push({label: item.employeeName, value: item.employeeNo})
      })
  
      data.cc.map((item) => {
        ccRespon.push({label: item.branchName, value: item.branchCode})
      })
  
      data.to.map((item) => {
        toRespon.push({label: item.branchName, value: item.branchCode})
      })

      data.fileAttachment.map((item) => {
        attachments.push({id: item.attachmentId, value: item.attachmentName})
      })

      setFormData({
        ...formData,
        id: data.id,
        priority: data.priority,
        type: data.type,
        documentSecurity: data.documentSecurity,
        regarding: data.regarding,
        fileContent: data.fileContent,
        date: new Date(),
        expDate: subtractHours(-7, new Date(data.expDate)),
        acknowledge: ackRespon,
        approval: approvalRespon,
        to: toRespon,
        cc: ccRespon,
  
      })
      setInitialValue(data.fileContent)
      setFormDocumentNo({
        documentNumber: data.documentNumber,
        documentCode: data.documentCode,
        groupId: data.groupId
      })
    }
  }

  const getApproval = (params?: any, data?: any) => {
    // console.log('trigger', selected_to)
   
    if(!params){
      let urlparams = '?'
      formData.to.map((item, index) => {
        urlparams = urlparams + `groups=${item.value}&`
      })
  
      formData.cc.map((item, index) => {
        urlparams = urlparams + `groups=${item.value}&`
      })

      if(formDocumentNo.groupId){
        urlparams = urlparams + `groups=${formDocumentNo.groupId}&`
      }
  
      console.log(urlparams)
    
  
      axios.get(`/services/uaadocservice/api/users/get-approvers-by-certain-groups${urlparams != '?' ? urlparams : ''}`).then(result => {
        console.log(result.data)
        let dataApproval = []
        result.data.map((item, index) => {
            dataApproval.push({
              label: item.full_name,
              value: item.employee_no
            })
        })
  
        setListApproval(dataApproval)
        
      }).catch(err => {
      
      })
    } else {
      let urlparams = '?'
      params.to.map((item, index) => {
        urlparams = urlparams + `groups=${item.value}&`
      })
  
      params.cc.map((item, index) => {
        urlparams = urlparams + `groups=${item.value}&`
      })

      if(params.groupId) {
        urlparams = urlparams + `groups=${params.groupId}&`
      }

    

      return axios.get(`/services/uaadocservice/api/users/get-approvers-by-certain-groups${urlparams != '?' ? urlparams : ''}`).then(result => {
        console.log(result.data)
        let dataApproval = []
        result.data.map((item, index) => {
            dataApproval.push({
              label: item.full_name,
              value: item.employee_no
            })
        })
        return dataApproval
  
        // setListApproval(dataApproval)
       
      }).catch(err => {
      
      })
    }
   }


   const objectToArray = (array, name) => {
    let data = []

    array.map((item) => {
      data.push({[name] : item.value})
    })

    return data
   }

   function subtractHours(numOfHours, date = new Date()) {
    date.setHours(date.getHours() + numOfHours);
  
    return date;
  }

   const SaveAsDraft = () => {
    setLoadingSaveAsDraft(true)
    const formDataFinal = new FormData();
    let dataFormData :  DocumentForm  = formData
    
    dataFormData = {
      ...dataFormData,
      date: subtractHours(7, formData.date),
      expDate: subtractHours(7, formData.expDate),
      to: objectToArray(dataFormData.to, 'branchCode'),
      cc: objectToArray(dataFormData.cc, 'branchCode'),
      acknowledge: objectToArray(dataFormData.acknowledge, 'employeeNo'),
      approval: objectToArray(dataFormData.approval, 'employeeNo'),
      groupId: formDocumentNo.groupId,
      documentNumber : formDocumentNo.documentNumber,
      documentCode : formDocumentNo.documentCode
    }

    formDataFinal.append("data", JSON.stringify(dataFormData));
    listFile.map((item, index) => {
      console.log(item)
      if (!item.fileAttachmentId) {
        formDataFinal.append('fileAttachment', dataURLtoFile(item.file, item.fileName))
      }
    })


    // link gdrive
    listAttachment.map((data, i) => {
      formDataFinal.append("fileAttachmentLinks", data.value);
    })

    // console.log('formData', Object.fromEntries(formDataFinal))

    axios.put('/services/documentservice/api/dms/save_as_draft', formDataFinal).then(res => {
      console.log(res)
      setLoadingSaveAsDraft(false)
      props.history.replace('/draft')
    }).catch(err => {
      console.log(err.response)
      toast.error(translate(`${err.response.data.message}`));
      setLoadingSaveAsDraft(false)
    })
   }


   const NextStep = () => {
    setLoadingNextStep(true)
    let dataFormData: DocumentForm = formData
    
    dataFormData = {
      ...dataFormData,
      date: subtractHours(7, formData.date),
      expDate: subtractHours(7, formData.expDate),
      to: objectToArray(dataFormData.to, 'branchCode'),
      cc: objectToArray(dataFormData.cc, 'branchCode'),
      acknowledge: objectToArray(dataFormData.acknowledge, 'employeeNo'),
      approval: objectToArray(dataFormData.approval, 'employeeNo'),
      fileContent: replaceP(dataFormData.fileContent),
      groupId: formDocumentNo.groupId,
      documentNumber : formDocumentNo.documentNumber,
      documentCode : formDocumentNo.documentCode
    }

    axios.post('/services/documentservice/api/documents/get-data-for-preview', dataFormData).then(res => {
      setLoadingNextStep(false)
      console.log('hasil', res)
      props.history.push('/preview/document/pdf', { data: JSON.stringify(dataFormData), file: JSON.stringify(listFile), link: JSON.stringify(listAttachment) })
    }).catch(err => {
      dataFormData = {
        ...dataFormData,
        date: subtractHours(-7, formData.date),
        expDate: subtractHours(-7, formData.expDate),
        to: objectToArray(dataFormData.to, 'branchCode'),
        cc: objectToArray(dataFormData.cc, 'branchCode'),
        acknowledge: objectToArray(dataFormData.acknowledge, 'employeeNo'),
        approval: objectToArray(dataFormData.approval, 'employeeNo'),
        fileContent: replaceP(dataFormData.fileContent),
        groupId: formDocumentNo.groupId,
        documentNumber : formDocumentNo.documentNumber,
        documentCode : formDocumentNo.documentCode
      }
      console.log(err.response)
      toast.error(translate(`${err.response.data.message}`));
      setLoadingNextStep(false)
    })

   }

   const dataURLtoFile = (dataurl, filename) => {

    var arr = dataurl.split(','),
      mime = arr[0].match(/:(.*?);/)[1],
      bstr = atob(arr[1]),
      n = bstr.length,
      u8arr = new Uint8Array(n);

    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }

    return new File([u8arr], filename, { type: mime });
  }

   const getAcknowledge = (params?: any, data?: any) => {
  
        if(!params) {
          let urlparams = '?'
        formData.to.map((item, index) => {
          urlparams = urlparams + `groups=${item.value}&`
        })

        formData.cc.map((item, index) => {
          urlparams = urlparams + `groups=${item.value}&`
        })
      
        if(formDocumentNo.groupId){
          urlparams = urlparams + `groups=${formDocumentNo.groupId}&`
        }

        console.log(urlparams)

        axios.get(`/services/uaadocservice/api/users/get-acknowledges-by-certain-groups${urlparams != '?' ? urlparams : ''}`).then(result => {
          console.log(result.data)
          let dataApproval = []
          result.data.map((item, index) => {
              dataApproval.push({
                label: item.full_name,
                value: item.employee_no
              })
          })

          setListAcknowledge(dataApproval)
          
        }).catch(err => {
        
        })
        } else {
          let urlparams = '?'
        params.to.map((item, index) => {
          urlparams = urlparams + `groups=${item.value}&`
        })

        params.cc.map((item, index) => {
          urlparams = urlparams + `groups=${item.value}&`
        })

        if(params.groupId) {
          urlparams = urlparams + `groups=${params.groupId}&`
        }
      
        console.log(urlparams)

        return axios.get(`/services/uaadocservice/api/users/get-acknowledges-by-certain-groups${urlparams != '?' ? urlparams : ''}`).then(result => {
          console.log(result.data)
          let dataApproval = []
          result.data.map((item, index) => {
              dataApproval.push({
                label: item.full_name,
                value: item.employee_no
              })
          })

        return dataApproval
          
        }).catch(err => {
        
        })
        }
   }






  



  const deleteLink = (key) => {
    let arr = listAttachment
    var filteredArray = arr.filter(function (e, i) { return e.id !== key })
    setListAttachments(filteredArray)
  }

  const deleteFile = (key) => {
    let arr = listFile
    var filteredArray = arr.filter(function (e, i) { return i !== key })
    setListFile(filteredArray)
  }

  const deleteFileUpload = (fileAttachmentId) => {
    // /services/documentservice/api/dms/delete-attachment?fileAttachmentId=40454&fileId=40362
    axios.delete(`/services/documentservice/api/dms/delete-attachment?fileAttachmentId=${fileAttachmentId}&fileId=${props.match.params.id}`).then(result => {
      let arr = listFile
      var filteredArray = arr.filter(function (e, i) { return e.fileAttachmentId !== fileAttachmentId })
      setListFile(filteredArray)
      toast.success('Berhasil Menghapus File', {
        position: "top-left",
        autoClose: 8000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }).catch(err => {
      toast.error('Gagal Menghapus File', {
        position: "top-left",
        autoClose: 8000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    })
  }

 

  const toBase64 = file => new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = error => reject(error);
  });

 


  const handleUpdate = (newValue, editor) => {

    setFormData({
      ...formData,
      fileContent: newValue
    })
  };

  const handleFileInput = async (e) => {

    if (e.target.files.length < 1) {
      return;
    }
    const file = e.target.files[0];
   
    let filesSelect = e.target.files
  

    Array.from(filesSelect).forEach(async (item: any) => {
      if (!isValidFileUploaded(item)) {
        toast.error('Tipe file tidak diperbolehkan', {
          position: "top-left",
          autoClose: 8000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      } else {
        //file is valid
        if (!validateSize(item)) {
          toast.error('Ukuran file Tidak Boleh Lebih Dari 10MB', {
            position: "top-left",
            autoClose: 8000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        } else {

          let data = {
            fileAttachmentId: null,
            fileName: item.name,
            file: await toBase64(item),
            fileContentType: item.type,
            fileSize: item.size
          }
          setListFile(oldArray => [...oldArray, data]);
        }
      }
    });


  }

 

  const openFilePreview = (base64, filetype) => {
    const blob = b64toBlob(base64, filetype);
    const blobUrl = URL.createObjectURL(blob);

    window.open(blobUrl)
  }

  useEffect(() => {
    
    
    getApproval()
    getAcknowledge()
}, [formData.to, formData.cc, formDocumentNo.groupId]);



  useEffect(() => {
      getListGroupNo()
      getDepartmentList()
      console.log(props.match.params.id)
      console.log('ini balikan', props.location.state)
      

      if(props.match.params.id) {
        getListAttachment()
        getDetailDocument(props.match.params.id)
      } else if(props.location.state) {

        
        setListFile(props.location.state.file)
        setListAttachments(props.location.state.link)
        let data = JSON.parse(props.location.state.data)
        console.log('balikan', data)
        let approvalRespon = []
        let ackRespon = []
        let toRespon = []
        let ccRespon = []
        let departmentListNew = []
        axios.get('services/uaadocservice/api/users/get-active-group').then( async (res) => {
          console.log('list', res)
          if(res.data.length > 1) {
            res.data.map((data, i) => {
              // setListTo([...listTo, {label: data.deptName, value: data.deptCode}])
            departmentListNew.push({label: data.groupName, value: data.id});
      
            })
            departmentListNew.map((item) => {
              data.to.map((data) => {
          
                if(item.value === data.branchCode) {
                  toRespon.push(item)
                }
              })
          })

          departmentListNew.map((item) => {
            data.cc.map((data) => {
           
              if(item.value === data.branchCode) {
                ccRespon.push(item)
              }
            })
        })

        getApproval({to: toRespon, cc: ccRespon, groupId: data.groupId}, data).then((res: any) => {
         
          res.map((item) => {
            data.approval.map((data) => {
              if(item.value == data.employeeNo){
                approvalRespon.push(item)
              }
            })
          })
       
        })
   
        getAcknowledge({to: toRespon, cc: ccRespon, groupId: data.groupId}, data).then((res : any) => {
          res.map((item) => {
            data.acknowledge.map((data) => {
              if(item.value == data.employeeNo){
                ackRespon.push(item)
              }
            })
          })
        })
        }
        }).catch(err => {
          console.log(err)
        })
    
        
        
       setTimeout(() => {
        setFormData({
          ...formData,
          type: data.type,
          priority: data.priority,
          documentSecurity: data.documentSecurity,
          fileContent: data.fileContent,
          regarding: data.regarding,
          expDate: subtractHours(-7, new Date(data.expDate)),
          date: new Date(),
          approval: approvalRespon,
          acknowledge: ackRespon,
          to: toRespon,
          cc: ccRespon,
        })  
        setInitialValue(data.fileContent)
        generateDocumentNumberByGroup(data.groupId)
       }, 1000)
      }
  }, []);

  const getListAttachment = () => {
    axios.get(`/services/documentservice/api/dms/get_attachment_from_gcp?fileId=${props.match.params.id}`).then(result => {
      console.log('attachment', result)
      setListFile(result.data.data.content)
    }).then(err => {

    })
  }


  const getListGroupNo = () => {
    Axios.get('/services/uaadocservice/api/group/get-list-document-code').then(res => {
      console.log('document no list', res.data)

      setGroupListDocument(res.data)
    }).catch(err => {
      setGroupListDocument([])
    })
  }

  const getDepartmentList = () => {
    axios.get('services/uaadocservice/api/users/get-active-group').then(res => {
      console.log('list', res)
      if(res.data.length > 1) {
        res.data.map((data, i) => {
          // setListTo([...listTo, {label: data.deptName, value: data.deptCode}])
          setDepartmentList(oldArray => [...oldArray, {label: data.groupName, value: data.id}]);
        })
      }
    }).catch(err => {
      console.log(err)
    })
  }

  const generateDocumentNumberByGroup = (id) => {
    Axios.get(`/services/documentservice/api/dms/generateDocumentNumber?groupId=${id}`).then(res => {
      console.log('list', res)
      setFormDocumentNo({
        groupId: id,
        documentNumber: res.data.data.documentNumber,
        documentCode: res.data.data.documentCode
      })
    }).catch(err => {

    })
  }

  


 

  const checkValidasiURL = (value) => {
    const formData = new FormData();
    formData.append("fileAttachmentLinks", value);
    axios.post('services/documentservice/api/dms/validasi_file_attachment', formData).then(res => {
      setListAttachments([...listAttachment, { id: uuidv4(), value: value }])
      setInputValue("")
      setLoadingLink(false)
    }).catch(err => {
      setLoadingLink(false)
      toast.error(translate(`${err.response.data.message}`), {
        position: "top-left",
        autoClose: 8000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    })
  }



  const _handleKeyDown = (e) => {
    setLoadingLink(true)
    console.log(e.target.value);
    console.log('validasi', validateUrl(e.target.value))
    if (validateUrl(inputValue)) {
      checkValidasiURL(inputValue)
    } else {
      setLoadingLink(false)
      toast.error('Link yang dimasukkan kurang tepat', {
        position: "top-left",
        autoClose: 8000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }


  }


 const handleInput = (name, value) => {
    console.log(name)
    console.log(value)

    setFormData({
      ...formData,
      [name] : value.value
    })
 }

 const handleInputDocumentNo = (name, value : any) => {
    setFormDocumentNo({
      ...formDocumentNo,
      [name]: value
    })
 }




  return (

    <div className="container">
      <Row className="">
        <Col md="8">
          <h2 id="documenttestApp.document.home.createOrEditLabel">
            <Translate contentKey="create.label">Create or edit a Document</Translate>

          </h2>
          <p id="documenttestApp.document.home.createOrEditLabel">
            <Translate contentKey="create.description">Create or edit a Document</Translate>

          </p>
        </Col>
      </Row>

 

      <Row className="justify-content-center">
        <Col md="12">
            <div className="card  mb-4">
              <div className="card-body">
                <div className="row mt-3 mb-4">
                  <div className="col-6">
                      <div className="row">
                        <div className="col-12 col-sm-12 col-md-2" style={{alignSelf: 'center'}}>
                            <div className='font-weight-bold' style={{display: 'flex', alignSelf: 'center'}}>            
                                    Type
                            </div>
                        </div>
                        <div className="col-12 col-sm-12 col-md-10">
                        <Select
                            name="group"
                            value={typeDocument.filter((function(option: any) {
                                return option.value == formData.type
                            }))}
                            isDisabled={fileStatus == 'APPROVED_BY_REJECTOR' || fileStatus == 'REJECTED'}
                            // isDisabled="true"
                            placeholder="Select Type Document"
                            options={typeDocument}
                            onChange={(e) => handleInput("type", e)}

                            
                          />
                        </div>
                      </div>
                  </div>          
                  <div className="col-6">
                      <div className="row">
                        <div className="col-12 col-sm-12 col-md-2" style={{alignSelf: 'center'}}>
                           <div className='font-weight-bold' style={{display: 'flex', alignSelf: 'center'}}>
                             
                                    Priority 
                             
                            </div>
                        </div>
                        <div className="col-12 col-sm-12 col-md-10">
                        <Select
                            name="group"
                            value={priorityDocument.filter((function(option: any) {
                              return option.value == formData.priority
                          }))}
                          isDisabled={fileStatus == 'APPROVED_BY_REJECTOR' }
                            placeholder="Select Priority Document"
                            options={priorityDocument}
                            onChange={(e) => handleInput("priority", e)}

                            
                          />
                        </div>
                      </div>
                  </div>              
                </div>

                <div className="row ">
                  <div className="col-6">
                      <div className="row">
                        <div className="col-12 col-sm-12 col-md-2" style={{alignSelf: 'center'}}>
                            <div className='font-weight-bold' style={{display: 'flex', alignSelf: 'center'}}>                   
                                  No File
                            </div>
                        </div>
                        <div className="col-12 col-sm-12 col-md-10">
                        <Select
                            name="group"
                            value={groupListDocument.filter((function(option: any) {
                              return option.id == formDocumentNo.groupId
                          }))}
                            placeholder="Select Document Group"
                            options={groupListDocument}
                            onChange={(e) => {
                              console.log(e.id)
                              // get List
                              setFormData({...formData, approval: [], acknowledge: []})
                              generateDocumentNumberByGroup(e.id)
                            }}
                            isDisabled={fileStatus == 'APPROVED_BY_REJECTOR'  || fileStatus == 'REJECTED'}
                            getOptionLabel={(option:any) => option.groupName}
                            getOptionValue={(option: any) => option.id}
                          />
                        </div>
                        
                      </div>

                      <div className="row my-3">
                        <div className="col-12 col-sm-12 col-md-2" style={{alignSelf: 'center'}}>
                            {/* <div className='font-weight-bold' style={{display: 'flex', justifyContent: 'center', alignSelf: 'center'}}>                   
                                  No File
                            </div> */}
                        </div>
                        <div className="col-12 col-sm-12 col-md-3">
                            <input className='form-control' disabled={fileStatus == 'REJECTED'} type='number' onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleInputDocumentNo('documentNumber', parseInt(e.target.value) < 1 ? 1 : e.target.value)} value={formDocumentNo.documentNumber}/>
                        </div>
                        <div className="col-12 col-sm-12 col-md-7">
                         <input disabled={true} className='form-control'  value={formDocumentNo.documentCode}/>
                        </div>
                        
                      </div>
                  </div>          
                  <div className="col-6">
                      <div className="row">
                        <div className="col-12 col-sm-12 col-md-2" style={{alignSelf: 'center'}}>
                           <div className='font-weight-bold' style={{display: 'flex', alignSelf: 'center'}}>
                             
                                  Document Security
                             
                            </div>
                        </div>
                        <div className="col-12 col-sm-12 col-md-10">
                        <Select
                            name="group"
                            value={documentSecurity.filter((function(option: any) {
                              return option.value == formData.documentSecurity
                          }))}
                          isDisabled={fileStatus == 'APPROVED_BY_REJECTOR'}
                            placeholder="Select Document Security"
                            options={documentSecurity}
                            onChange={(e) => handleInput("documentSecurity", e)}
                          />
                        </div>
                      </div>
                  </div>              
                </div>
                <div className="row">
                  <div className="col-1">
                    
                  </div>
                  <div className="col-5">
                    <span style={{fontSize: 10}}>The document number will only be saved after you send your document</span>
                  </div>
                </div>
              </div>
            </div>

            <div className="card  mb-4">
              <div className="card-body">
                <div className="row mt-3 mb-4">
                  <div className="col-12 mb-2">
                      <div className="row">
                        <div className="col-12 col-sm-12 col-md-1" style={{alignSelf: 'center'}}>
                            <div className='font-weight-bold' style={{display: 'flex', alignSelf: 'center'}}>
                               To  
                            </div>
                        </div>
                        <div className="col-12 col-sm-12 col-md-11">
                        <MultiSelect
                          overrideStrings={{
                            allItemsAreSelected: "All Divisions/Teams are Selected",
                            selectAll: "Select All Divisions/Teams",
                            search: "Search",
                          }}
                          labelledBy="Select"
                          disabled={fileStatus == 'APPROVED_BY_REJECTOR'}
                          options={departmentList}
                          value={formData.to}
                          onChange={(e) =>  {
                            console.log(e)
                            let dataTo = formData.to
                            dataTo = e
                            setFormData({...formData, to: dataTo, approval: [], acknowledge: []})
                          
                          }}
                          // getOptionLabel={(option:any) => option.groupName}
                          // getOptionValue={(option: any) => option.id}
                        />
                        </div>
                      </div>
                  </div>          
                  <div className="col-12 my-3">
                      <div className="row">
                        <div className="col-12 col-sm-12 col-md-1" style={{alignSelf: 'center'}}>
                           <div className='font-weight-bold' style={{display: 'flex', alignSelf: 'center'}}>
                              cc
                            </div>
                        </div>
                        <div className="col-12 col-sm-12 col-md-11">
                        <MultiSelect
                          disabled={fileStatus == 'APPROVED_BY_REJECTOR'}
                          labelledBy="Select"
                          options={departmentList}
                          value={formData.cc}
                          onChange={(e) =>  {
                            console.log(e)
                            let dataCC = formData.cc
                            dataCC = e
                            setFormData({...formData, cc: dataCC, acknowledge: [], approval: []})
                           
                          }}
                        />
                        </div>
                      </div>
                  </div>              
                </div>

               
              </div>
            </div>

            <div className="card  mb-4">
              <div className="card-body">
                <div className="row mt-3 mb-4">
                  <div className="col-6">
                      <div className="row">
                        <div className="col-12 col-sm-12 col-md-2" style={{alignSelf: 'center'}}>
                            <div className='font-weight-bold' style={{display: 'flex', justifyContent: 'center', alignSelf: 'center'}}>
                               
                                  Date 
                              
                            </div>
                        </div>
                        <div className="col-12 col-sm-12 col-md-10">
                          <DatePicker 
                            value={formData.date}
                            disabled={true}
                            minDate={new Date()}
                            selected={formData.date}
                            dateFormat="d MMMM yyyy - h:mm aa"
                           
                          />
                        </div>
                      </div>
                  </div>          
                  <div className="col-6">
                      <div className="row">
                        <div className="col-12 col-sm-12 col-md-2" style={{alignSelf: 'center'}}>
                           <div className='font-weight-bold' style={{display: 'flex', justifyContent: 'center', alignSelf: 'center'}}>
                             
                                    Exp Date 
                             
                            </div>
                        </div>
                        <div className="col-12 col-sm-12 col-md-10">
                        <DatePicker
                          
                          showTimeSelect
                          timeFormat="HH:mm"
                          timeIntervals={15}
                          timeCaption="time"
                          // dateFormat="MMMM d, yyyy h:mm aa"
                          dateFormat="d MMMM yyyy - h:mm aa"
                          minDate={new Date()}  
                          selected={formData.expDate}
                          onChange={(e) => {
                            setFormData({
                              ...formData,
                              expDate: e
                            })
                          }}
                        />
                        </div>
                      </div>
                  </div>              
                </div>

               
              </div>
            </div>

            <div className="card  mb-4">
              <div className="card-body">
                <div className="row mt-3 mb-4">
                    <div className="col-1">
                        Regarding
                    </div>
                    <div className="col-11">
                    <textarea className='form-control' rows={2} style={{resize: 'none'}} value={formData.regarding}
                     disabled={fileStatus == 'APPROVED_BY_REJECTOR'}
                    onChange={(e) => {
                      console.log(e.target.value.length)
                      if(e.target.value.length  <= 250) {
                        setFormData({
                          ...formData,
                          regarding: e.target.value
                        })
                      } else {
                        setFormData({
                          ...formData,
                          regarding: e.target.value.slice(0, 250)
                        })
                      }
                    }}/>
                    </div>
                </div>

               
              </div>
            </div>


          <div id="editorStyle" className="card  mb-12">

            <div className="card-body">

                {/* <AvField id="document-createdBy" type="text" name="fileContent2" /> */}
                <Editor
                  apiKey="yxncub4wprr57o8ve6m8vevwr8avhp9umi76ntuswcvllw5o"
                  onInit={(evt, editor) => editorRef.current = editor}
                  initialValue={initialValue}
                  value={formData.fileContent}
                  onEditorChange={handleUpdate}
                  // onSubmit={handleEditorSubmit}
                  // onSubmit={saveEntity}
                  // disabled={disabledDocument}
                 disabled={fileStatus == 'APPROVED_BY_REJECTOR'}
                  init={{

                    height: 800,
                    menubar: true,
                    paste_data_images: true,
                    // paste_block_drop: true,
                    forced_root_block_attrs: {
                      'style': 'font-size: 10pt;'
                    },
                    forced_root_block: 'div',
                    force_br_newlines: true,
                    force_p_newlines: false,
                    force_div_newlines: false,
                    formats: {
                      bold: { inline: 'b' },
                      italic: { inline: 'i' },
                      underline: { inline: 'u' }
                    },
                    plugins: [
                      'advlist autolink lists link image charmap print preview anchor ',
                      'searchreplace visualblocks code fullscreen',
                      'insertdatetime media table paste code help wordcount hr'
                    ],
                    toolbar: 'undo redo | fontsizeselect | code |  formatselect | ' +
                      'bold italic backcolor | alignleft aligncenter ' +
                      'alignright alignjustify | bullist numlist outdent indent | ' +
                      ' help hr',
                    content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:12pt }'
                  }}
                />


            </div>
          </div>

          <div className="card  mb-4">
              <div className="card-body">
                <div className="row mt-3 mb-4">
                  <div className="col-12 mb-2">
                      <div className="row">
                        <div className="col-12 col-sm-12 col-md-2" style={{alignSelf: 'center'}}>
                            <div className='font-weight-bold' style={{display: 'flex', alignSelf: 'center'}}>
                               Approval :      
                            </div>
                        </div>
                        <div className="col-12 col-sm-12 col-md-10">
                        <MultiSelect
                          overrideStrings={{
                            allItemsAreSelected: "All Divisions/Teams are Selected",
                            selectAll: "Select All Divisions/Teams",
                            search: "Search",
                          }}
                          disabled={fileStatus == 'APPROVED_BY_REJECTOR'}
                          labelledBy="Select"
                          options={listApproval}
                          value={formData.approval}
                          onChange={(e) =>  {
                            console.log(e)
                            let dataApproval = formData.approval
                            dataApproval = e
                            setFormData({...formData, approval: dataApproval})
                          }}
                          // getOptionLabel={(option:any) => option.groupName}
                          // getOptionValue={(option: any) => option.id}
                        />
                       
                        </div>
                      </div>
                      <div className="row mt-2">
                        <div className="col-12 col-sm-12 col-md-2" style={{alignSelf: 'center'}}>
                            
                        </div>
                        <div className="col-12 col-sm-12 col-md-10">
                          <span className='' style={{fontSize: 10}}>This Approval list is sent in order based on your choice</span>
                        </div>
                      </div>
                  </div>          
                  <div className="col-12 my-3">
                      <div className="row">
                        <div className="col-12 col-sm-12 col-md-2" style={{alignSelf: 'center'}}>
                           <div className='font-weight-bold' style={{display: 'flex', alignSelf: 'center'}}>
                              Acknowledge :
                            </div>
                        </div>
                        <div className="col-12 col-sm-12 col-md-10">
                        <MultiSelect
                           disabled={fileStatus == 'APPROVED_BY_REJECTOR'}
                          labelledBy="Select"
                          options={listAcknowledge}
                          value={formData.acknowledge}
                          onChange={(e) =>  {
                            console.log(e)
                            let dataAcknowledge = formData.acknowledge
                            dataAcknowledge = e
                            setFormData({...formData, acknowledge: dataAcknowledge})
                          }}
                        />
                        </div>
                      </div>
                      <div className="row mt-2">
                        <div className="col-12 col-sm-12 col-md-2" style={{alignSelf: 'center'}}>
                            
                        </div>
                        <div className="col-12 col-sm-12 col-md-10">
                          <span className='' style={{fontSize: 10}}>This Approval list is sent in order based on your choice</span>
                        </div>
                      </div>
                  </div>              
                </div>

               
              </div>
            </div>




          {/* List Attachment */}

          <div className="bg-white rounded shadow my-4 px-4 py-4">
              <h5>
                Attachment
              </h5>

              {
                listFile.length > 0 ? listFile.map((item, index) => {
                  return (<>
                    <div key={`t-${index}`}>
                      <div className="py-2 pl-3 pr-2 rounded shadow my-2 d-inline-block" style={{ background: '#F5F5F5', color: '#3267E3', alignContent: 'c' }} key={`file-${index}`}>
                        <div style={{ display: 'flex', alignItems: 'center' }}>
                          <span onClick={() => openFilePreview(item.file?.replace(/^[^,]+,/, ''), item.fileContentType)}>
                            {item.fileName} ( {bytesToSize(item.fileSize)})
                          </span>
                          {
                            item.fileAttachmentId ? <span className="px-2 py-2 mx-2" style={{ cursor: 'pointer' }} onClick={() => deleteFileUpload(item.fileAttachmentId)}>
                              <FontAwesomeIcon icon="trash" color="red" />
                            </span> : <span className="px-2 py-2 mx-2" style={{ cursor: 'pointer' }} onClick={() => deleteFile(index)}>
                              <FontAwesomeIcon icon="trash" color="red" />
                            </span>
                          }
                        </div>
                      </div>
                    </div>
                  </>
                  )
                }) : <>
                  <div className="text-center my-4">
                    Anda Belum Memilih File
                  </div>
                </>
              }
          </div>
          <div className="bg-white rounded shadow my-4 px-4 py-4">
              <div className="row">
                <div className="col-12 col-lg-2 col-xl-2">
                  Attachment
                </div>
                <div className="col-12 col-lg-10 col-xl-10">
                  <span className="btn btn-primary btn-file">
                    <UploadFileIcon className="mx-1" /> Upload File
                    <input
                      type="file"
                      disabled={fileStatus == 'APPROVED_BY_REJECTOR'}
                      // value={selectedFile}
                      // accept=".csv,.xls,.xlsx,.doc,.docx,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document,application/pdf"
                      onChange={handleFileInput}
                      multiple
                    />
                  </span>
                  <div className="my-2">
                    <span style={{ color: '#757575' }}>Upload .png/.jpg/.jpeg/.docx/.xlsx/.pdf/ .csv file only, with max size 10MB.</span>
                  </div>
                </div>
              </div>
          </div>

         <div className="bg-white rounded shadow my-4 px-4 py-4">
              <h5>
                Link
              </h5>

              {
                listAttachment.length > 0 ? listAttachment.map((data, index) => {
                  return (
                    <div key={`s-${index}`}>
                      <div className="py-2 pl-3 pr-2 rounded shadow my-2 d-inline-block" style={{ background: '#F5F5F5', color: '#3267E3', alignContent: 'c' }} key={`file-${index}`}>
                        <div style={{ display: 'flex', alignItems: 'center' }}>
                          <span>
                            {data.value}
                          </span>
                          {
                            data.id ? <span className="px-2 py-2 mx-2" style={{ cursor: 'pointer' }} onClick={() => deleteLink(data.id)}>
                              <FontAwesomeIcon icon="trash" color="red" />
                            </span> : <span className="px-2 py-2 mx-2" style={{ cursor: 'pointer' }} onClick={() => deleteLink(data.id)}>
                              <FontAwesomeIcon icon="trash" color="red" />
                            </span>
                          }
                        </div>
                      </div>
                    </div>
                  )
                }) : <div className="text-center my-4">
                  Anda Belum Memasukkan Link
                </div>
              }


              <div className="row mt-3">
                <div className="col-12 col-lg-2 my-2">
                  Insert Google Drive Link
                </div>
                <div className="col-lg-6 col-12 col-sm-12 col-xs-12">
                  <input className="form-control" value={inputValue} onChange={(e) => setInputValue(e.target.value)}  disabled={fileStatus == 'APPROVED_BY_REJECTOR'}/>
                  <div className="my-2">
                    <span style={{ color: '#757575' }}>Upload .png/.jpg/.jpeg/.docx/.xlsx/.pdf/ .csv file only, with max size 10MB.</span>
                  </div>

                </div>

                <div className="col-lg-4 col-12 col-sm-12 col-xs-12">
                  <button className="btn btn-primary" onClick={(e) => _handleKeyDown(e)}  disabled={fileStatus == 'APPROVED_BY_REJECTOR' ||  loadingLink}>
                    {loadingLink ? <Spinner color='#fff' size="sm" className='mr-1' /> : <></>}Add Link
                  </button>
                </div>
              </div>

            </div>

            


            <div className="float-right">

              <Button onClick={() => SaveAsDraft()} id="cancel-save2" type="submit" className='mr-2' disabled={loadingSaveAsDraft || loadingNextStep } color="primary">

                &nbsp;
                <span className="d-md-inline">
                   {loadingSaveAsDraft ? <Spinner color='#fff' size="sm" className='mr-1' />  : <SaveDraftIcon className='mr-1' />} 
                
                  Save As Draft
                </span>
              </Button>

              {/* <SaveToDraftButton /> */}




              &nbsp;
              <Button onClick={() => NextStep()} name="next" color="primary" id="save-entity" type="submit" className='pb-2' disabled={loadingSaveAsDraft || loadingNextStep}>
                <span className='mr-1'>
                {loadingNextStep ? <Spinner color='#fff' size="sm" className='mr-1' />  : <></>} 
                  Next</span>
                <ArrowRightIconWhite />
              </Button>

              {/* <SubmitButton /> */}
              </div>

        </Col>
      </Row>
      


    </div>





  );
};

const mapStateToProps = (storeState: IRootState) => ({
  documentEntity: storeState.document.entity,
  loading: storeState.document.loading,
  loadingDepartement: storeState.document.loadingdepartement,
  loadingDetail: storeState.document.loadingdetail,
  updating: storeState.document.updating,
  updateSuccess: storeState.document.updateSuccess,
  deptList: storeState.document.deptList,
  detaildocument: storeState.document.detaildocument,
  userList: storeState.document.userList,
  approvalList: storeState.document.approvalList,
  acknowledgeList: storeState.document.acknowledgeList,
  documentListCode: storeState.document.documentListCode,
});

const mapDispatchToProps = {
  getEntity,
  updateEntity,
  createEntity,
  reset,
  getDeptList,
  getUserList,
  getApprovalList,
  getAcknowledgeList,
  getListDocumentCode,
  sendNewDocument,
  saveDocumentToDraft,
  documentPreview,
  getDocumentDetail
};

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(mapStateToProps, mapDispatchToProps)(DocumentCreate);



