import React, { useState, useEffect, useRef } from 'react';
import { connect } from 'react-redux';
import { Link, Redirect, RouteComponentProps } from 'react-router-dom';
import { Button, InputGroup, Col, Row, Table, Modal, ModalHeader, ModalBody, ModalFooter, Input, FormGroup, Label, Spinner } from 'reactstrap';
import { AvForm, AvGroup, AvInput } from 'availity-reactstrap-validation';
import { Translate, translate, ICrudSearchAction, ICrudGetAllAction, TextFormat } from 'react-jhipster';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IRootState } from 'app/shared/reducers';
import { getSearchEntities, getEntities, getEntitiesByEmpId,  getDocumentDetail, postDocument, signDocument, approveDocument, viewPDF, rejectDocument} from './../document/document.reducer';
import { IDocument } from 'app/shared/model/document.model';
import { APP_DATE_FORMAT, APP_LOCAL_DATE_FORMAT } from 'app/config/constants';
import SignaturePad from 'react-signature-canvas'
import axios from 'axios';
import { BlobProvider, pdf, PDFViewer } from '@react-pdf/renderer';
import PreviewPDFComponent from 'app/modules/pdf/preview';
import XCircleIcon from 'app/component/x-circle-icon';
import CheckCircleIcon from 'app/component/check-circle-icon';
import dataURLtoBlob from 'blueimp-canvas-to-blob'
import { Document, pdfjs } from 'react-pdf';
import createBrowserHistory from 'history/createBrowserHistory';
import { toast } from 'react-toastify';
import DragUploadIcon from 'app/component/upload-drag-icon';
import EyeIcon from 'app/component/eye-icon';
import EyeSmallIcon from 'app/component/eye-small-icon';
import EyeSmallIconDark from 'app/component/eye-small-icon-dark';
import EyeSmallIconDarkHide from 'app/component/eye-small-icon-dark-hide';
import CloseDeleteIcon from 'app/component/close-delete-icon';
import SaveDraftIcon from 'app/component/save-draft-icon';
import FilesDragAndDrop from 'app/component/dragUpload';
import UploadFileIcon from 'app/component/upload-icon';
import { flatMap, result } from 'lodash';
const reader = new FileReader()

const history = createBrowserHistory({forceRefresh:true});

export interface IDocumentProps extends StateProps, DispatchProps, RouteComponentProps<{ url: string }> { }


export interface Props {
  getDocumentDetail?: any,
  match?: any,
  viewPDF?: any,
  detaildocument?: any,
  signDocument?: any,
  approveDocument?: any,
  blobPreview?: any,
  filePDFSign?: any,
  rejectDocument?: any,
  PreviewPDFComponent?: any,
  history?: any
}

export const PreviewApproval: React.FC<Props> = (props) => {
  const [search, setSearch] = useState('');
  const [fileblob, setFileblob] = useState(null);
  const [showModal, setShowModal] = useState(false)
  const [progressApproval, setProgressApproval] = useState(false)
  const [showModalReject, setShowModalReject] = useState(false)
  const [showModalSign, setShowModalSign] = useState(false)
  const [notesApprove, setNotesApprove] = useState('I approved this document')
  const [notesReject, setNotesReject] = useState('')
  const [previewApprove, setPreviewApprove] = useState(false)
  const [manualFlag, setManualFlag] = useState(false)
  const [typeTransaction, setTypeTransaction] = useState(1) // 1 -> approval / 2 -> reject
  const [progressApprove, setProgressApprove] = useState(false)
  const [progressReject, setProgressReject] = useState(false)
  const [listAttachment, setListAttachment] = useState<any>([])
  const [showManualApprove, setManualApprove] = useState<any>(false)
  const [showPassword, setShowPassword] = useState<boolean>(false)
  const [pin, setPin] = useState<any>("")
  const [files, setFiles] = React.useState(null);
  const [urlImage, setUrlImage] = useState<any>(null)
  const [notesManual, setNotesManual] = useState<any>("I Approved this document")
  const [progressManual, setProgressManual] = useState<any>(false)
  const [urlBlob, setUrlBlob] = useState<any>("")

  pdfjs.GlobalWorkerOptions.workerSrc = 
  `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

  const signCanvas = useRef<any>();

    const clear = () => signCanvas.current.clear();
    const save = () => {
    let basse64 = signCanvas.current.getTrimmedCanvas().toDataURL("image/png")  
    console.log(dataURLtoBlob(basse64))
    }
    // const save = () => console.log(signCanvas.getTrimmedCanvas)

  const handleClose = () => {
      setNotesApprove('I approved this document')
      setShowModal(false)
      setShowPassword(false)
      setManualApprove(false)
  };

  const handleOpen = () => {
      setShowModal(true)
  }

  const handleCloseReject = () => {
    setNotesReject('')
    setShowModalReject(false)
};

const handleOpenReject = () => {
    setShowModalReject(true)
}

const resetAction = () => {
  setNotesApprove('I approved this document')
  setNotesReject('')
  setPreviewApprove(false)
  setManualFlag(false)
}


const handleCloseSign = () => {
  setShowModalSign(false)
};

const handleOpenSign = () => {
  setShowModalSign(true)
}
  useEffect(() => {
    // console.log('isian', props.match.params.id)
    // props.getDocumentDetail(props.match.params.id);
    // props.viewPDF(props.match.params.id)
    axios.get(`/services/documentservice/api/dms/get_document_for_approval?fileId=${props.match.params.id}`).then(res => {

    }).catch(err => {
      toast.error(translate(`${err.response.data.message}`), {
        toastId: err.response.data.message,
        position: "top-left",
        autoClose: 8000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      if(err.response.status === 401 || err.response.status === 404 ) {
        history.push('/404')
      }
    })
  }, []);

  const renderViewPDF = (id) => {
    props.viewPDF(id)
  }

  const submit = (datadokumen) => {
  if(datadokumen.isSigned == false){
  pdf(<PreviewPDFComponent 
      from={props.detaildocument?.senderBranchName}
      title={props.detaildocument?.fileNumber}
      regarding={props.detaildocument?.regarding}
      date={props.detaildocument?.date}
      to={props.detaildocument?.to}
      fileContent={props.detaildocument?.fileContent}
      cc={props.detaildocument?.cc}
      dataDocument={props.detaildocument}
      acknowledge={props.detaildocument?.acknowledgeList}
    />).toBlob().then(res => {
      console.log(res)
      let basse64 = signCanvas.current.getTrimmedCanvas().toDataURL("image/png")  
      let signFile = dataURLtoBlob(basse64)
      props.signDocument(res, signFile, props.match.params.id)
      setShowModalSign(false)
      
    })
  } else {
    let basse64 = signCanvas.current.getTrimmedCanvas().toDataURL("image/png")  
    let signFile = dataURLtoBlob(basse64)
    props.signDocument(props.blobPreview, signFile, props.match.params.id)
    setShowModalSign(false)
  }
    // props.postDocument(fileblob)
  }

  const submitApprove = async (datadokumen) => {
    const formData = new FormData();
    formData.append("currentPinApproval", pin)
      axios.post(`/services/uaadocservice/api/personal-identity/login-approval`, formData).then(async (res) => {

        axios.get(`/services/documentservice/api/dms/confirm-approve?id=${props.match.params.id}&notes=${notesApprove}`, {responseType:'blob'}).then(async (result) => {
          await setProgressApproval(true)
          await delayTime(3000)
          await setPreviewApprove(true)
          await setShowModal(false)
          await setProgressApproval(false)
        }).catch(async (err)=> {
          const isJsonBlob = (data) => data instanceof Blob && data.type === "application/json";
          const responseData = isJsonBlob(err.response?.data) ? await (err.response?.data)?.text() : err.response?.data || {};
          const responseJson = (typeof responseData === "string") ? JSON.parse(responseData) : responseData;
          console.log(responseJson)
          toast.error(translate(`${responseJson.message}`), {
            toastId: err.response.data.message,
            position: "top-left",
            autoClose: 8000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        })
      }).catch((err) => {
        console.log(err.response)
        toast.error(translate(`${err.response.data.message}`), {
          position: "top-left",
          autoClose: 8000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          });
      })
  }

  const dataURLtoFile = (dataurl, filename) => {
 
    var arr = dataurl.split(','),
        mime = arr[0].match(/:(.*?);/)[1],
        bstr = atob(arr[1]), 
        n = bstr.length, 
        u8arr = new Uint8Array(n);
        
    while(n--){
        u8arr[n] = bstr.charCodeAt(n);
    }
    
    return new File([u8arr], filename, {type:mime});
}

const manualConfirm = () => {
  console.log(files)
  const dataManual = new FormData()
  dataManual.append("id", props.match.params.id)
  dataManual.append("filePdf", files)
  dataManual.append("notes", notesManual)
  axios.post('/services/documentservice/api/dms/confirm-approve-manual', dataManual, {responseType:'blob'}).then(result => {
    // window.open(URL.createObjectURL(res.data));
    console.log('error', result)
    setManualFlag(true)
    setManualApprove(false)
    setPreviewApprove(true)
    setPin("")
    setUrlBlob(URL.createObjectURL(result.data))
    setProgressManual(false)
    // setFiles(null)
    setNotesManual("I Approved this document")
    setUrlImage("")
  }).catch(async (err) => {
    setProgressManual(false)
    const isJsonBlob = (data) => data instanceof Blob && data.type === "application/json";
    const responseData = isJsonBlob(err.response?.data) ? await (err.response?.data)?.text() : err.response?.data || {};
    const responseJson = (typeof responseData === "string") ? JSON.parse(responseData) : responseData;
    console.log(responseJson)
    toast.error(translate(`${responseJson.message}`), {
      toastId: err.response.data.message,
      position: "top-left",
      autoClose: 8000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  })
}

const submitManualApprove = (datadokumen) => {
  setProgressManual(true)
  const formData = new FormData();
  formData.append("currentPinApproval", pin)
    axios.post(`/services/uaadocservice/api/personal-identity/login-approval`, formData).then(res => {
      manualConfirm()
    }).catch(err => { 
      setProgressManual(false)
      toast.error(translate(`${err.response.data.message}`), {
        position: "top-left",
        autoClose: 8000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        });
    })
}

  const getListAttachment = () => {
    axios.get(`/services/documentservice/api/dms/get_attachment_from_gcp?fileId=${props.match.params.id}`).then(result => {
      console.log(result.data.data)
      setListAttachment(result.data.data.content)
    }).then(err => {
      
    })
  }


  const handlerChangeImage = (data) => {
    console.log(data)
  
    if(!data) return

    if(!isValidFileUploaded(data)){
      toast.error(translate('error.errordocservice.message.error41'), {
        position: "top-left",
        autoClose: 8000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        });
    } else {
      if(!validateSize(data)){
        toast.error(translate('error.errordocservice.message.error39'), {
          position: "top-left",
          autoClose: 8000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          }); 
      } else {
        setFiles(data)
        setUrlImage(URL.createObjectURL(data))
      }
    }
}


const isValidFileUploaded=(file)=>{
  console.log('type', file.type)
  if(file.type == "") {
    return true
  } else {
    console.log('type', file.type)
    console.log('type', file.type.split('/')[1])
    const validExtensions = ['pdf']
    const fileExtension = file.type.split('/')[1]
    console.log(validExtensions.includes(fileExtension))
    return validExtensions.includes(fileExtension)
  }
 
}

function validateSize(input) {
  console.log(input)
  const fileSize = input.size / 1024 / 1024; // in MiB
  if (fileSize > 10) {
    console.log('lebih dari 10 Mb', fileSize)
    return false
  } else {
    return true
  }
}

const getDetailDocument = (id) => {
  axios.get('/services/documentservice/api/documents/get-detail/' + id).then(res => {
    console.log('data detail', res)
  }).catch(err => {
    console.log(err.response)
    toast.error(translate(`${err.response.data.message}`), {
      toastId: err.response.data.message,
      position: "top-left",
      autoClose: 8000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      });
      if(err.response.status == 401 || err.response.status == 404) {
        history.push('/404')
      } 
  })
}






  const sendApprove = async () => {
    setProgressApprove(true)
    axios.post(`/services/documentservice/api/dms/send-approve?id=${props.match.params.id}&notes=${notesApprove}`).then(async(res) => {
      await delayTime(2000)
      setProgressApprove(false)
      toast.success('Sukses Approve Dokumen', {
        position: "top-left",
        autoClose: 8000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        });
      props.history.push('/')
    }).catch(err => {
      setProgressApprove(false)
      toast.error(translate(`${err.response.data.message}`), {
        position: "top-left",
        autoClose: 8000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        });
    })
  }

  const sendApproveManual = async () => {
    setProgressApprove(true)
    const formApproveManual = new FormData()
    formApproveManual.append("id", props.match.params.id)
    formApproveManual.append("filePdf", files)
    formApproveManual.append("notes", notesManual)
    axios.post(`/services/documentservice/api/dms/send-approve-manual`, formApproveManual).then(async(res) => {
      await delayTime(2000)
      setProgressApprove(false)
      setFiles(null)
      toast.success('Sukses Approve Dokumen', {
        position: "top-left",
        autoClose: 8000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        });
      props.history.push('/')
    }).catch(err => {
      setProgressApprove(false)
      toast.error(translate(`${err.response.data.message}`), {
        position: "top-left",
        autoClose: 8000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        });
    })
  }
  
  const delayTime = (ms) => {
    return new Promise(
      resolve => setTimeout(resolve, ms)
    )
  }

    const submitReject = async (datadokumen) => {
      await setProgressReject(true)
      const formData = new FormData();
      formData.append("currentPinApproval", pin)
        axios.post(`/services/uaadocservice/api/personal-identity/login-approval`, formData).then(async (res) => {
          console.log(res)
          axios.post(`services/documentservice/api/dms/confirm-reject?id=${props.match.params.id}&notes=${notesReject}`).then( async (res) => {
            await delayTime(2000)
            await setProgressReject(false)
            await console.log(res)
            await setShowModalReject(false)
            toast.success('Sukses Reject Dokumen', {
              position: "top-left",
              autoClose: 8000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              });
            await props.history.push('/')
          }).catch( async(err) => {
            console.log(err)
            setShowModalReject(false)
            await delayTime(2000)
            await setProgressReject(false)
            toast.error(translate(`${err.response.data.message}`), {
              position: "top-left",
              autoClose: 8000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              });
          })
        }).catch((err) => {
          console.log(err.response)
          toast.error(translate(`${err.response.data.message}`), {
            position: "top-left",
            autoClose: 8000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            });
            setProgressReject(false)
      })
    
     
    }


  let datadetail = props.detaildocument
  // let datafinal = JSON.parse(datadetail)
 console.log(datadetail)
 console.log('data props',)

 const bytesToSize = (bytes) => {
  var sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB'];
  if (bytes == 0) return '0 Byte';
  var i = Math.floor(Math.log(bytes) / Math.log(1024));
  return Math.round(bytes / Math.pow(1024, i)) + ' ' + sizes[i];
}


const b64toBlob = (b64Data, contentType='', sliceSize=512) => {
  const byteCharacters = atob(b64Data);
  const byteArrays = [];

  for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
    const slice = byteCharacters.slice(offset, offset + sliceSize);

    const byteNumbers = new Array(slice.length);
    for (let i = 0; i < slice.length; i++) {
      byteNumbers[i] = slice.charCodeAt(i);
    }

    const byteArray = new Uint8Array(byteNumbers);
    byteArrays.push(byteArray);
  }

  const blob = new Blob(byteArrays, {type: contentType});
  return blob;
}

const openFilePreview = (base64, filetype) => {
  const blob = b64toBlob(base64, filetype);
  const blobUrl = URL.createObjectURL(blob);

  window.open(blobUrl)
}

const manualApprove = () => {
    setManualApprove(true)
}

useEffect(() => {
  // console.log('isian', props.match.params.id)
  // props.getDocumentDetail(props.match.params.id);
  // props.viewPDF(props.match.params.id)
  getDetailDocument(props.match.params.id)
  getListAttachment();
}, []);

 if(props.detaildocument.isSigned == false ){
  return (
    <div className="container">
          <Row>
          <Col>
          <h2>Acknowledge</h2>
           <p>File Preview Acknowledge</p>
          </Col>
        </Row>
       <PDFViewer style={{width: '100%', height: '1000px'}} >
        <PreviewPDFComponent 
          from={props.detaildocument?.senderBranchName}
          title={props.detaildocument?.fileNumber}
          regarding={props.detaildocument?.regarding}
          date={props.detaildocument?.date}
          to={props.detaildocument?.to}
          fileContent={props.detaildocument?.fileContent}
          cc={props.detaildocument?.cc}
          dataDocument={props.detaildocument}
          acknowledge={props.detaildocument?.acknowledgeList}
          notesApprove={notesApprove}
          notesReject={notesReject}
        />
      </PDFViewer>
         <Row className='mb-4 mt-4'>
          <Col>
         
          </Col>
          <Col className='text-right' xs="12" sm="12" >
          {/* <button className='btn btn-secondary mr-2 px-4'>Cancel</button> */}
            <button className='btn btn-danger mr-2 px-3' onClick={() => handleOpenReject()}><XCircleIcon className='mr-1'/>Reject</button>
            <button className='btn btn-warning mr-2 px-4' onClick={() => handleOpenSign()}>Sign</button>
            <button className='btn btn-success px-3' onClick={() => handleOpen()} ><CheckCircleIcon className='mr-1'/> Approve</button>
          </Col>
        </Row>

        <Modal isOpen={showModal} toggle={handleClose} size="lg">
      <ModalHeader toggle={handleClose}>
            <h2>Approval Confirmation</h2>
            <span style={{fontSize: '12px'}}>Dokumen Ini Disetujui Dengan Catatan :</span>
      </ModalHeader>
      <ModalBody id="documenttestApp.document.delete.question">
      <div className="mb-2">Notes</div>
      <FormGroup>
        <Input
          rows={5}
          style={{resize: 'none'}}
          id="exampleText"
          name="text"
          type="textarea"
          maxLength={255}
          value={notesApprove}
          onChange={(e) => setNotesApprove(e.target.value)}
        />
      </FormGroup>
      </ModalBody>
      <ModalFooter>
       <Row className='w-100'>
         <Col lg={6}>
         {/* <FormGroup>
            <Input
              id="exampleText"
              name="text"
              type="password"
             
            />
          </FormGroup> */}
         </Col>
         <Col lg={6} className="text-right">
          <Button color="secondary" onClick={handleClose} className="mr-2">
            <Translate contentKey="entity.action.cancel">Cancel</Translate>
          </Button>
          <Button  color="success" onClick={() => submitApprove(props.detaildocument)}>
              Confirm Approve
          </Button>
         </Col>
       </Row>
      </ModalFooter>
    </Modal>


    <Modal isOpen={showModalReject} toggle={handleCloseReject} size="lg">
      <ModalHeader toggle={handleCloseReject}>
            <h2>Reject Confirmation</h2>
            <span style={{fontSize: '12px'}}>Dokumen Ini Ditolak Dengan Alasan :</span>
      </ModalHeader>
      <ModalBody id="documenttestApp.document.delete.question">
    
      <FormGroup>
        <Input
          rows={5}
          style={{resize: 'none'}}
          id="exampleText"
          name="text"
          type="textarea"
          maxLength={255}
          value={notesReject}
          onChange={(e) => setNotesReject(e.target.value)}
        />
      </FormGroup>
      </ModalBody>
      <ModalFooter>
       <Row className='w-100'>
         <Col lg={6}>
         {/* <FormGroup>
            <Input
              id="exampleText"
              name="text"
              type="password"
            />
          </FormGroup> */}
         </Col>
         <Col lg={6} className="text-right">
          <Button color="secondary" onClick={handleCloseReject} className="mr-2">
              Cancel
          </Button>
          <Button  color="danger" onClick={() => submitReject(props.detaildocument)} disabled={!notesReject || progressReject }>
              {progressReject == true ? <Spinner size="sm" color="#fff"  /> : <></>}
              Confirm Rejectdsd
          </Button>
         </Col>
       </Row>
      </ModalFooter>
    </Modal>


    <Modal isOpen={showModalSign} toggle={handleCloseSign} size="sm">
      <ModalHeader toggle={handleCloseSign}>
            <h2>Sign Confirmation</h2>
            <span style={{fontSize: '12px'}}>Lorem ipsum dolor sit amet consectetur, adipisicing elit</span>
      </ModalHeader>
      <ModalBody id="documenttestApp.document.delete.question" style={{backgroundColor: '#fff', justifyContent: 'center', display: 'flex'}}>
        <div style={{backgroundColor: '#bdbdbd', width: 150, height: 100}}>
          <SignaturePad
          ref={signCanvas}
          canvasProps={{ className: "signature__canvas", width: 150, height: 100, backgroundColor: '#bdbdbd' }}
          />
        </div>
      </ModalBody>
      <ModalFooter>
    
       <Row className='w-100'>
         <Col lg={12}>
         {/* <FormGroup>
            <Input
              id="exampleText"
              name="text"
              type="password"
            />
          </FormGroup> */}
         </Col>
         <Col lg={12} className="text-right">
          <Button color="secondary" onClick={handleCloseSign} className="mr-2 w-100 my-2">
            <Translate contentKey="entity.action.cancel">Cancel</Translate>
          </Button>
          <Button color="secondary" onClick={clear} className="mr-2 w-100 my-2">
            Clear
          </Button>
          <Button  color="warning" onClick={() => submit(props.detaildocument)} className="w-100 my-2">
              Confirm Sign
          </Button>
         </Col>
       </Row>
      </ModalFooter>
    </Modal>
        </div>
  )
 } else {
   return (
    <div className="container">
        <Row>
          <Col>
           <h2>Approval</h2>
           <p>File Preview Approval</p>
          </Col>
        </Row>
        {previewApprove && !manualFlag ?   <iframe src={`${window.location.origin}/services/documentservice/api/dms/confirm-approve?id=${props.match.params.id}&notes=${notesApprove}`} style={{width: '100%', height: '1000px'}} />
        : <></>
        } 
        {
          previewApprove && manualFlag ?  <iframe src={urlBlob} style={{width: '100%', height: '1000px'}} /> : <></>
        }
        {
          !previewApprove && !manualFlag ?  <iframe src={`${window.location.origin}/services/documentservice/api/dms/get_document_for_approval?fileId=${props.match.params.id}`} style={{width: '100%', height: '1000px'}} /> : <></>
        }

<div className="bg-white rounded shadow my-4 px-4 py-4"> 
                <h5>
                  Attachment
                </h5>

        {
                  listAttachment.length > 0 ? listAttachment.map((item, index) => {
                    return ( <div onClick={() => openFilePreview(item.file?.replace(/^[^,]+,/, ''), item.fileContentType)}>
                      <div >
                            <div className="py-2 pl-3 pr-2 rounded shadow my-2 d-inline-block" style={{background: '#F5F5F5', color: '#3267E3', alignContent: 'c'}} key={`file-${index}`}>
                              <div style={{display: 'flex', alignItems: 'center'}}>
                                <span>
                                    {item.fileName} ( {bytesToSize(item.fileSize)} )
                                </span>
                               
                              </div>
                            </div>
                      </div>
                    </div>
                    )
                  }) : <>

                  </>
                }
                </div>
   
      <Row className='mb-4 mt-4'>
          <Col>
         
          </Col>
          {previewApprove ?  <Col className='text-right' xs="12" sm="12" md="6" lg="6">
            <button className='btn btn-light mr-2 px-4' onClick={() => resetAction()} disabled={progressApprove}>Cancel</button>
            <button className='btn btn-primary px-3' onClick={ !manualFlag ? sendApprove : sendApproveManual} disabled={progressApprove}> {progressApprove ? <Spinner color='#fff' size="sm" className="mr-1"/> : <></>}Send</button>
          </Col>:  <Col className='text-right' xs="12" sm="12" md="6" lg="6">
            <button className='btn btn-light mr-2 px-4' style={{border: '1px solid #000'}} onClick={() => props.history.push('/')}>Cancel</button>
           
            <button className='btn btn-danger mr-2 px-3' onClick={() => handleOpenReject()}><XCircleIcon className='mr-1'/>Reject</button>
            {/* <button className='btn btn-warning mr-2 px-4' onClick={() => handleOpenSign()}>Sign</button> */}
            <button className='btn btn-success px-3 mr-2' onClick={() => handleOpen()} ><CheckCircleIcon className='mr-1'/> Approve</button>
            <button className='btn btn-primary mr-2 px-4' onClick={() => manualApprove()}>Manual Approve</button>
          </Col>}
        </Row>
    
      
    

    <Modal isOpen={showModal} toggle={handleClose} size="lg" style={{marginTop: '10%'}}> 
      <ModalHeader toggle={handleClose}>
            <h2>Approval Confirmation</h2>
            <span style={{fontSize: '12px'}}>Dokumen Ini Disetujui Dengan Catatan : </span>
      </ModalHeader>
      <ModalBody id="documenttestApp.document.delete.question">
          <div className='px-2 py-2'>
            <div className='mb-2'>Notes</div>
              <FormGroup>
            <Input
              rows={5}
              style={{resize: 'none'}}
              id="exampleText"
              name="text"
              type="textarea"
              value={notesApprove}
              maxLength={255}
              onChange={(e) => setNotesApprove(e.target.value)}
            />
          </FormGroup>
          </div>
      </ModalBody>
      <ModalFooter className='px-0'>
       <Row className='w-100'>
       <div className=''>PIN</div>
         <Col lg={4}>
       
         <FormGroup> 
            <div style={{border: '1px solid #C2C2C2'}}>
            <Input
              id="exampleText"
              value={pin}
              name="text"
              type={showPassword ? 'text' : 'password'}
              style={{paddingRight: '15%'}}
              onChange={(e) => {
                // alert('test')
                console.log(e.target.value.length)
                if(e.target.value.length < 7) {
                  setPin(e.target.value)
                } else {

                }
              }}
              disabled={progressApproval}
            />
            {
              !showPassword ? <EyeSmallIconDark style={{position: 'absolute', top: '20%', right: '10%', cursor: 'pointer'}}  onClick={() => setShowPassword(!showPassword)}/> :  <EyeSmallIconDarkHide onClick={() => setShowPassword(!showPassword)} style={{position: 'absolute', top: '20%', right: '10%', cursor: 'pointer'}}/>
            }
            </div>
          </FormGroup>
         </Col>
         <Col lg={8} className="text-right">
          <Button color="light" style={{border: '1px solid #000'}} onClick={handleClose} className="mr-2">
            <Translate contentKey="entity.action.cancel">Cancel </Translate>
          </Button>
          <Button  color="success" onClick={() => submitApprove(props.detaildocument)} disabled={!notesApprove || progressApproval || !pin || pin.length < 6}>
              {progressApproval ?    <Spinner color='#fff' size="sm"  className='mr-1'/>  : <></>}
              Confirm Approve
          </Button>
         </Col>
       </Row>
      </ModalFooter>
    </Modal>


    <Modal isOpen={showModalReject} toggle={handleCloseReject} size="lg" style={{marginTop: '10%'}}>
      <ModalHeader toggle={handleCloseReject}>
            <h2>Reject Confirmation</h2>
            <span style={{fontSize: '12px'}}>Dokumen Ini Ditolak Dengan Alasan :</span>
      </ModalHeader>
      <ModalBody id="documenttestApp.document.delete.question">
      <div className='px-2 py-3'>
      <div className='mb-2'>
        Notes
      </div>
      <FormGroup>
        <Input
          rows={5}
          style={{resize: 'none'}}
          id="exampleText"
          name="text"
          type="textarea"
          maxLength={255}
          value={notesReject}
          onChange={(e) => setNotesReject(e.target.value)}
        />
      </FormGroup>
      </div>
      </ModalBody>
      <ModalFooter className='px-0'>
       <Row className='w-100'>
        <span>PIN</span>
         <Col lg={4}>
        
         <FormGroup>
          <div style={{border: '1px solid #C2C2C2'}}>
            <Input
              id="exampleText"
              name="text"
              value={pin}
              type={showPassword ? 'text' : 'password'}
              style={{paddingRight: '15%'}}
              onChange={(e) => {
                if(e.target.value.length < 7) {
                  setPin(e.target.value)
                } else {

                }
              }}
              disabled={progressApproval}
            />
            {
              !showPassword ? <EyeSmallIconDark style={{position: 'absolute', top: '20%', right: '10%', cursor: 'pointer'}}  onClick={() => setShowPassword(!showPassword)}/> :  <EyeSmallIconDarkHide onClick={() => setShowPassword(!showPassword)} style={{position: 'absolute', top: '20%', right: '10%', cursor: 'pointer'}}/>
            }
            </div>
          </FormGroup>
         </Col>
         <Col lg={8} className="text-right">
         <Button color="light" style={{border: '1px solid #000'}} onClick={handleCloseReject} className="mr-2" disabled={progressReject}>
            <Translate contentKey="entity.action.cancel">Cancel</Translate>
          </Button>
       
          <Button  color="danger" onClick={() => submitReject(props.detaildocument)} disabled={!notesReject || progressReject || !pin || pin.length < 6}>
             { progressReject ? <Spinner size="sm" color="#fff" className='mr-1'/> : <></> }
              Confirm Reject  
          </Button>
         </Col>
       </Row>
      </ModalFooter>
    </Modal>


    <Modal isOpen={showModalSign} toggle={handleCloseSign} size="sm">
      <ModalHeader toggle={handleCloseSign}>
            <h2>Sign Confirmation</h2>
            <span style={{fontSize: '12px'}}>Lorem ipsum dolor sit amet consectetur, adipisicing elit</span>
      </ModalHeader>
      <ModalBody id="documenttestApp.document.delete.question" style={{backgroundColor: '#fff', justifyContent: 'center', display: 'flex'}}>
        <div style={{backgroundColor: '#bdbdbd', width: 150, height: 100}}>
          <SignaturePad
          ref={signCanvas}
          canvasProps={{ className: "signature__canvas", width: 150, height: 100, backgroundColor: '#bdbdbd' }}
          />
        </div>
      </ModalBody>
      <ModalFooter>
    
       <Row className='w-100'>
         <Col lg={12}>
         {/* <FormGroup>
            <Input
              id="exampleText"
              name="text"
              type="password"
            />
          </FormGroup> */}
         </Col>
         <Col lg={12} className="text-right">
          <Button color="secondary" onClick={handleCloseSign} className="mr-2 w-100 my-2">
            <Translate contentKey="entity.action.cancel">Cancel</Translate>
          </Button>
          <Button color="secondary" onClick={clear} className="mr-2 w-100 my-2">
            Clear
          </Button>
          <Button  color="warning" onClick={() => submit(props.detaildocument)} className="w-100 my-2">
              Confirm Sign
          </Button>
         </Col>
       </Row>
      </ModalFooter>
    </Modal>

    <Modal isOpen={showManualApprove} toggle={handleClose} size="lg" style={{marginTop: '10%'}}>
      <ModalBody id="documenttestApp.document.delete.question" className=''>
          <div className="px-4 py-4">
            <div className='mb-3'>
                <h2>Manual Approve</h2>
                {/* <span style={{fontSize: '12px'}}>Choose one methode manual approve</span> */}
            </div>
    
           <div className='mt-4 mb-2'>
           <span className=''>Upload File </span>
           </div>
            <div>
            {
              urlImage ? <div style={{height: 200, background: '#D4D4D4', position: 'relative', borderRadius: '20px', display: 'flex', justifyContent: 'center', alignItems: 'center'}}> 
              <div className='d-block text-center'>
                <div>
                    <UploadFileIcon style={{height: 30}} />
                </div>
                <div style={{color: '#000', fontSize: 14}}>{files?.name}</div>
              </div>
              <CloseDeleteIcon style={{position: 'absolute', 
              top: -5, 
              right: -5, 
              cursor: 'pointer'}} onClick={() => {
                setUrlImage(null)
                setFiles(null)
              }}/>
               </div>: <></>
            }
          </div>
          {
            !urlImage ? <>
              <div style={{marginTop: 10}}></div>
          <label htmlFor={"file_picker"} id="drop_zone">
            <DragUploadIcon/>
            <div>Drag and drop a file here</div>
            <input
              id="file_picker"
              type="file"
              // accept="application/pdf"
              onChange={(ev) => {
                handlerChangeImage(ev.target.files[0])
              }}
              style={{display: "none"}}
            ></input>
          </label>
          <span style={{fontSize: '10px'}}> ( Upload .pdf file only, with max size 10MB)</span>
            </> : <></>
          }

        <div>
                <div className='mb-3 mt-4'>Notes</div>
                    <div>
                          <Input
                        rows={5}
                        style={{resize: 'none'}}
                        id="exampleText"
                        name="text"
                        type="textarea"
                        maxLength={255}
                        value={notesManual}
                        onChange={(e) => setNotesManual(e.target.value)}
                      />
                </div>
            </div>
            
          </div>
          
          <div>
            
        <Row className='px-4'>
         <Col lg={4}>
         <FormGroup>
          <div style={{border: '1px solid #C2C2C2'}}>
            <Input
              id="exampleText"
              name="text"
              value={pin}
              type={showPassword ? 'text' : 'password'}
              style={{paddingRight: '15%'}}
              onChange={(e) => {
                if(e.target.value.length < 7) {
                  setPin(e.target.value)
                } else {

                }
              }}
              disabled={progressManual}
            />
            {
              !showPassword ? <EyeSmallIconDark style={{position: 'absolute', top: '20%', right: '10%', cursor: 'pointer'}}  onClick={() => setShowPassword(!showPassword)}/> :  <EyeSmallIconDarkHide onClick={() => setShowPassword(!showPassword)} style={{position: 'absolute', top: '20%', right: '10%', cursor: 'pointer'}}/>
            }
            </div>
          </FormGroup>
         </Col>
         <Col lg={8} className="text-right">
         <Button color="light" style={{border: '1px solid #000'}} onClick={() => {setManualApprove(false); setFiles(null); setNotesManual(""); setUrlImage("")}} className="mr-2" disabled={progressManual}>
            <Translate contentKey="entity.action.cancel">Cancel</Translate>
          </Button>
          <Button  color="primary" onClick={() => submitManualApprove(props.detaildocument)} disabled={!notesManual || progressManual || !pin || !files || pin.length < 6}>
             { progressManual ? <Spinner size="sm" color="#fff" className='mr-1'/> : <></> }
              Confirm 
          </Button>
         </Col>
       </Row>
          </div>
      </ModalBody>
    
    </Modal>
    </div>
  );
   }
};

const mapStateToProps = ({ document }: IRootState) => ({
  documentList: document.entities,
  loading: document.loading,
  detaildocument: document.detaildocument,
  filePDFSign: document.filePDFsign,
  acknowledgelist: document.acknowledgelist,
  fileUrl: document.fileUrlBlob,
  blobPreview: document.fileBlobPreview
});

const mapDispatchToProps = {
  getSearchEntities,
  getEntities,
  getEntitiesByEmpId,
  getDocumentDetail,
  postDocument,
  signDocument,
  approveDocument,
  viewPDF,
  rejectDocument
};

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(mapStateToProps, mapDispatchToProps)(PreviewApproval);
