import React from 'react';
import { Switch } from 'react-router-dom';

import ErrorBoundaryRoute from 'app/shared/error/error-boundary-route';

import SignaturePage from './signature';
import SignaturePageAdd from './signatureadd';
import profile from './profile';
import { PinChange } from './pin';
import { PinSet } from './setPin';
const Routes = ({ match }) => (
  <>
    <Switch>
      <ErrorBoundaryRoute path={`${match.url}/me`} component={profile} />
      <ErrorBoundaryRoute path={`${match.url}/signature/view`} component={SignaturePage} />
      <ErrorBoundaryRoute path={`${match.url}/signature/add`} component={SignaturePageAdd} />
      <ErrorBoundaryRoute path={`${match.url}/pin/change`} component={PinChange} />
      <ErrorBoundaryRoute path={`${match.url}/pin/set`} component={PinSet} />
    </Switch>
    {/* <ErrorBoundaryRoute exact path={`${match.url}/:id/delete`} component={DocumentDeleteDialog} /> */}
  </>
);

export default Routes;
