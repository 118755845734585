import '../../../../../../scss/bootstrap.min.scss'
import '../../../../../../scss/app.min.scss'
import './search.scss';
import { renderToString } from "react-dom/server";
import { render } from "react-dom";
import tableAppr from 'app/modules/home/table'
import { Listbox, Transition } from '@headlessui/react'
// import { CheckIcon, SelectorIcon } from '@heroicons/react/solid'
import { PDFViewer } from '@react-pdf/renderer';
import Pagination from '@mui/material/Pagination';
const list = [
  { value: 2 },
  { value: 5 },
  { value: 10 },
  { value: 15 },
  { value: 20 },
  { value: 25 },
]
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from '@mui/material/Select';

import React, { useEffect, useState, Fragment } from 'react';
import { Link, RouteComponentProps } from 'react-router-dom';
import { Translate } from 'react-jhipster';
import { connect } from 'react-redux';
import { CardGroup, Row, Col, Alert, Tooltip, Modal, ModalHeader, ModalBody, FormGroup, Input, ModalFooter, Button } from 'reactstrap';

import { IRootState } from 'app/shared/reducers';


import SideBar from 'app/shared/layout/sidebar/sidebar';
import Approval from 'app/shared/layout/card/approval';
import HandshakeIcon from 'app/component/handshake-icon';
import EyeIcon from 'app/component/eye-icon';
import EnvelopeIcon from 'app/component/envelope-icon';
import PaperPlaneIcon from 'app/component/paperplane-icon';
import InfoIcon from 'app/component/InfoIcon';

import { getSearchEntities, getEntities, getEntitiesByEmpId, getDocumentApproval, getDocumentAcknowledge, getDocumentSuratKeluar, getDocumentSuratMasuk } from '../document/document.reducer';
import Axios from 'axios';
import Page from 'app/component/pagination/Page';
import { useHistory } from "react-router-dom";
import ArrowRightIcon from 'app/component/arrow-right-icon';
import moment from 'moment-timezone';
import axios from 'axios'
import { size } from 'lodash';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import BottomSort from 'app/component/bottom-sort';
import Illustration1 from 'app/component/illustration-1';

export interface IHeaderProps {
  isAuthenticated?: boolean;
  isAdmin?: boolean;
  ribbonEnv?: string;
  isInProduction?: boolean;
  isSwaggerEnabled?: boolean;
  currentLocale?: string;
  onLocaleChange?: Function;
  account?: any,
  logout?: any,
  location?: any
}


export const Search = (props: IHeaderProps) => {
  let history = useHistory();
  const [search, setSearch] = useState('')
  const [listSearch, setListSearch] = useState([])
  const [loadingList, setLoadingList] = useState(false)
  const [showList, setShowList] = useState(false)
  const [page, setPage] = useState(1)
  const [totalPage, setTotalPage] = useState(1)
  const [size, setSize] = useState<any>(10)
  const [sortType, setSortType] = useState('typeName.keyword,asc')
  const [sortPriority, setSortPriority] = useState('priority.keyword,asc')
  const [sortLastModified, setSortLastModified] = useState('lastApprovedByName.keyword,asc')
  const [sortDate, setSortDate] = useState('date,asc')


  const renderStatusFileHistories = (status, statusName) => {
    if (status == "CREATED" || status == "REVISE") {
      return <div style={{background: '#FFF4EB', border: '1px solid #F37F26',  borderRadius: '6px'}} className="px-2 py-1 font-weight-bold  mt-3 text-mirae w-50 text-center">{statusName}</div>
    } else if(status == 'APPROVE' || status == 'APPROVE_REVISED_VERSION' || status == 'WAITING_FOR_APPROVAL') {
       return <div style={{color: '#20573D', background: '#F7F7F7', border: '1px solid #B8DBCA',  borderRadius: '6px'}} className="px-2 py-1 font-weight-bold  mt-3 w-50 text-center">{statusName}</div>
    } else {
      return <div style={{color: '#CB3A31', background: '#FFF4F2', border: '1px solid #EEB4B0', borderRadius: '6px'}} className="px-2 py-1  font-weight-bold mt-3 w-50 text-center">{statusName}</div>
    }
}


  useEffect(() => {
    // alert('data search', props.location.state.search)
    // alert(props.location.state.search)
    if(props.location.state?.search == '') {
      setListSearch([])
      setDefault()
    } else {
      getSearch(props.location.state?.search)
    }
  
  }, [props.location.state?.search, sortType, sortPriority, sortLastModified, sortDate, page, size, totalPage]);



  const handleChange = (event: SelectChangeEvent) => {
    setSize(parseInt(event.target.value));
  };


  useEffect(() => {
    console.log('data search', props.location.state?.search)
    // alert(props.location.state.search)
    getSearch(props.location.state?.search)
  }, []);

  const setDefault = () => {
    setSortType('typeName.keyword,asc')
    setSortPriority('priority.keyword,asc')
    setSortLastModified('lastApprovedByName.keyword,asc')
    setSortDate('date,asc')
  }


  const delayTime = (ms) => {
    return new Promise(
      resolve => setTimeout(resolve, ms)
    )
  }

  
  const getSearch = async (e = '') => {
    if(e.length > 1 ) {
      setLoadingList(true)
      axios.get(`services/documentservice/api/custom-search/documents?keyword=${e}&page=${page}&size=${size}&sort=${sortType}&sort=${sortPriority}&sort=${sortLastModified}&sort=${sortDate}`).then( async (result) => {
        console.log(result.data)
        setListSearch(result.data.content)
        setTotalPage(result.data?.totalPages)
        await delayTime(2000)
        setLoadingList(false)
      }).catch(err => {
        setListSearch([])
        setLoadingList(false)
      })
    }
  }


  const changeSortType = () => {
    if(sortType == 'typeName.keyword,asc') {
      setSortType('typeName.keyword,desc')
    } else {
      setSortType('typeName.keyword,asc')
    }
  }

  const changeSortPriority = () => {
    if(sortPriority == 'priority.keyword,asc') {
      setSortPriority('priority.keyword,desc')
    } else {
      setSortPriority('priority.keyword,asc')
    }
  }

  const changeSortLastModified = () => {
    if(sortLastModified == 'lastApprovedByName.keyword,asc') {
      setSortLastModified('lastApprovedByName.keyword,desc')
    } else {
      setSortLastModified('lastApprovedByName.keyword,asc')
    }
  }

  const changeSortDate = () => {
    if(sortDate == 'date,asc') {
      setSortDate('date,desc')
    } else {
      setSortDate('date,asc')
    }
  }
  const goToPage = (id) => {
    setSearch('')
    history.push(`/preview/only/pdf/${id}`)
  }


  const handleChangePage = (event: React.ChangeEvent<unknown>, value: number) => {
    setPage(value);
  };

  


 
  return (
    <div className="px-5 pt-4" style={{height: '100%'}}>

    <div className="row">
      <div className="col-12">
        <div className="page-title-box d-flex align-items-center justify-content-between">
          <h1 className="mb-0 font-size-28">Search</h1>
        </div>
        <div className="page-title-box d-flex align-items-center justify-content-between">
          {/* <h1 className="mb-0 font-size-14">Lorem ipsum dolor sit amet, consectetur adipiscing elit</h1> */}
        </div>
      </div>
    </div>


    <div className="row">
      <div className="col-lg-12 col-sm-12 col-xs-12">
          <div className="row">
                <div className="col-xl-2 col-lg-4 col-sm-12 col-xs-12 my-2">
                  <div className="bg-white py-3 px-3 rounded font-weight-bold cursor-pointer" role="button" onClick={() => changeSortType()}>
                      Type File <span style={sortType == 'typeName.keyword,asc' ? {float: 'right'} : {float: 'right', transform: 'rotate(180deg)'}}><BottomSort/></span>
                  </div>
              </div>
              <div className="col-lg-2  col-sm-12 col-xs-12 my-2">
                  <div className="bg-white py-3 px-3 rounded font-weight-bold" role="button" onClick={() => changeSortPriority()}>
                      Priority <span style={sortPriority == 'priority.keyword,asc' ? {float: 'right'} : {float: 'right', transform: 'rotate(180deg)'}}><BottomSort/></span>
                  </div>
              </div>
              <div className="col-lg-2 col-sm-12 col-xs-12 my-2">
                  <div className="bg-white py-3 px-3 rounded font-weight-bold" role="button" onClick={() => changeSortLastModified()}>
                      Last Approved By <span style={sortLastModified == 'lastApprovedByName.keyword,asc' ? {float: 'right'} : {float: 'right', transform: 'rotate(180deg)'}}><BottomSort/></span>
                  </div>
                  </div>
              <div className="col-lg-2 col-sm-12 col-xs-12 my-2">
                  <div className="bg-white py-3 px-3 rounded font-weight-bold" role="button" onClick={() => changeSortDate()}>
                      Date <span style={sortDate == 'date,asc' ? {float: 'right'} : {float: 'right', transform: 'rotate(180deg)'}}><BottomSort/></span>
                  </div>
              </div>
          </div>
      </div>
    </div>

    <div className="mt-4">
              {
                !loadingList ? listSearch.map((item, index) => {
                  return (
                    <div onClick={() => goToPage(item.id)} style={{cursor: 'pointer'}}> 
                         <div className="card mt-2" style={{borderRadius: '10px'}}>
                              <div style={{background: '#FFF4EB', borderTopRightRadius: '10px', borderTopLeftRadius: '10px', fontSize: '12px'}} className="px-3 py-3">
                                <span className='mr-2'>{item.fileNumber ? item.fileNumber : "-"}</span>{'  '} • {'  '}<span className='mr-2 ml-2'>{item?.groupName}</span>{'  '} • {'  '}<span className='mr-2 ml-2'>{item?.createdDateFormat}</span>
                              </div>
                              <div className="card-body">
                                <div className="row">
                                  <div className="col-12 col-md-4 col-lg-4 my-2">
                                  <h4 className='font-weight-bold'>{item.regarding ? item.regarding : "-"}</h4>
                                  </div>
                                  <div className="col-12 col-md-3 col-lg-3 my-2">
                                  <h5 className='font-weight-bold'>Last Approval</h5>
                                  <h5 className='font-italic font-weight-light mt-3'>{item.lastApprovedByName}</h5>
                                  </div>
                                  <div className="col-12 col-md-2 col-lg-2 my-2">
                                  <h5 className='font-weight-bold'>Date</h5>
                                  <h5 className='font-italic font-weight-light mt-3'>{item.lastApprovedDateFormat}</h5>
                                  </div>
                                  <div className="col-12 col-md-3 col-lg-3 my-2">
                                  <h5 className='font-weight-bold'>Status</h5>
                                  {renderStatusFileHistories(item.fileStatus, item.fileStatusName)}
                                  </div>
                                </div>
                              </div>
                            </div>          
                    </div>
                  )
                }) : <>
                <div style={{height: '170px'}} className="my-3">
                <div className="shimmerBG"></div>
                </div>
                <div style={{height: '170px'}} className="my-3">
                <div className="shimmerBG"></div>
                </div>
                <div style={{height: '170px'}} className="my-3">
                <div className="shimmerBG"></div>
                </div>
                
                </>

              }
                { listSearch.length == 0 && !loadingList && props.location.state?.search && <div className="my-4" style={{height: '400px', display: 'flex', justifyContent: 'center', alignContent: 'center'}}>
                        <div style={{textAlign: 'center'}}>
                        <Illustration1 className='my-5'/>
                          <h3>We couldn't find any results for '{props.location.state?.search}'</h3>
                          <p style={{color: '#9E9E9E'}}>Check for spelling or try searching for another term</p>
                        </div>
                    </div>
                }
             </div>
              

           {!loadingList ? <div className="row">
                {listSearch.length > 0 ? <> <div className="col-lg-7 col-sm-12 col-xs-12 my-2">
                <div style={{display: 'flex'}}>
                  <span className="mt-3 mx-2">
                    Show
                  </span>
                  <span>
                  <FormControl variant="standard" sx={{ m: 1, minWidth: 50}}>
                
                <Select
                  labelId="demo-simple-select-standard-label"
                  id="demo-simple-select-standard"
                  value={size}
                  onChange={handleChange}
                  label="Limit"
                >
                  <MenuItem value={5}>5</MenuItem>
                  <MenuItem value={10}>10</MenuItem>
                  <MenuItem value={20}>20</MenuItem>
                  <MenuItem value={30}>30</MenuItem>
                </Select>
              </FormControl>
                  </span>
                  <span className="mt-3 mx-2">
                    Entries
                  </span>
                </div>
             </div>
            
             <div className="col-lg-5 col-sm-12 col-xs-12 my-2" style={{display: 'flex', justifyContent: 'end'}}>
                  <Pagination count={totalPage} color="primary" page={page} showFirstButton showLastButton onChange={handleChangePage}/>
             </div> </> : <> </>}
         </div> : <>
          <div className="row">
              <div className="col-4">
                  <div style={{height: '30px'}} className="my-3">
                <div className="shimmerBG"></div>
                </div>
              </div>
              <div className="col-4">

              </div>
              <div className="col-4">
                  <div style={{height: '30px'}} className="my-3">
                <div className="shimmerBG"></div>
                </div>
              </div>
          </div>
         </>}
    </div>
  );
};

const mapStateToProps = ({ document }: IRootState) => ({
 
});


const mapDispatchToProps = {
 
};
type StateProps = ReturnType<typeof mapStateToProps>
type DispatchProps = typeof mapDispatchToProps;;

export default connect(mapStateToProps, mapDispatchToProps)(Search);
