import axios from 'axios';
import { ICrudSearchAction, ICrudGetAction, ICrudGetAllAction, ICrudPutAction, ICrudDeleteAction } from 'react-jhipster';

import { cleanEntity } from 'app/shared/util/entity-utils';
import { REQUEST, SUCCESS, FAILURE } from 'app/shared/reducers/action-type.util';

import { IDocument, defaultValue } from 'app/shared/model/document.model';

export const ACTION_TYPES = {
  SEARCH_DOCUMENTS: 'document/SEARCH_DOCUMENTS',
  FETCH_DOCUMENT_LIST: 'document/FETCH_DOCUMENT_LIST',
  FETCH_DOCUMENT_LIST_APPROVAL: 'document/FETCH_DOCUMENT_LIST_APPROVAL',
  FETCH_DOCUMENT_LIST_ACKNOWLEDGE: 'document/FETCH_DOCUMENT_LIST_ACKNOWLEDGE',
  FETCH_DOCUMENT_LIST_SURAT_MASUK: 'document/FETCH_DOCUMENT_LIST_SURAT_MASUK',
  FETCH_DOCUMENT_LIST_SURAT_KELUAR: 'document/FETCH_DOCUMENT_LIST_SURAT_KELUAR',
  FETCH_DOCUMENT_LIST_SURAT_REJECTED: 'document/FETCH_DOCUMENT_LIST_SURAT_REJECTED',
  FETCH_DOCUMENT_DETAIL: 'document/FETCH_DOCUMENT_DETAIL',
  FETCH_DOCUMENT: 'document/FETCH_DOCUMENT',
  FETCH_DEPARTMENT: 'document/FETCH_DEPARTMENT',
  FETCH_USER_LIST: 'document/FETCH_USER_LIST',
  FETCH_APPROVAL_LIST: 'document/FETCH_APPROVAL_LIST',
  FETCH_ACKNOWLEDGE_LIST: 'document/FETCH_ACKNOWLEDGE_LIST',
  FETCH_DOCUMENT_CODE_LIST: 'document/FETCH_DOCUMENT_CODE_LIST',
  CREATE_DOCUMENT: 'document/CREATE_DOCUMENT',
  UPDATE_DOCUMENT: 'document/UPDATE_DOCUMENT',
  DELETE_DOCUMENT: 'document/DELETE_DOCUMENT',
  RESET: 'document/RESET',
  OPENPREVIEW: 'document/previewsign',
  APPROVE_DOCUMENT: 'document/approve',
  REJECT_DOCUMENT: 'document/reject',
  VIEW_DOCUMENT_SIGN: 'document/viewsign',
  SEND_DOCUMENT: 'document/SEND_DOCUMENT',
};

const initialState = {
  loading: false,
  errorMessage: null,
  entities: [] as ReadonlyArray<IDocument>,
  documentDashboardList: [] as ReadonlyArray<IDocument>,
  documentListAcknowledge: [] as ReadonlyArray<IDocument>,
  documentListSuratMasuk: [] as ReadonlyArray<IDocument>,
  documentListSuratKeluar: [] as ReadonlyArray<IDocument>,
  documentListSuratRejected: [] as ReadonlyArray<IDocument>,
  deptList: [],
  userList: [],
  approvalList: [],
  acknowledgeList: [],
  documentListCode: [], 
  entity: defaultValue,
  updating: false,
  updateSuccess: false,
  totalApproval: 0,
  totalAcknowledge: 0,
  totalSuratMasuk: 0,
  totalSuratKeluar: 0,
  totalSuratRejected: 0,
  totalPageApproval: 0,
  totalPageAcknowledge: 0,
  totalPageKotakMasuk: 0,
  totalPageKotakKeluar: 0,
  totalPageKotakRejected: 0,
  totalPage: 0,
  loadingdetail: false,
  loadingdepartement: false,
  detaildocument: [],
  loadingapprove: false,
  loadingreject: false,
  filePDFsign: null,
  acknowledgelist: [],
  fileUrlBlob: null,
  fileBlobPreview: null
};

export type DocumentState = Readonly<typeof initialState>;

// Reducer

export default (state: DocumentState = initialState, action): DocumentState => {
  switch (action.type) {
    case REQUEST(ACTION_TYPES.SEARCH_DOCUMENTS):
    case REQUEST(ACTION_TYPES.FETCH_DOCUMENT_LIST):
    case REQUEST(ACTION_TYPES.FETCH_DOCUMENT_LIST_APPROVAL):
    case REQUEST(ACTION_TYPES.FETCH_DOCUMENT_LIST_ACKNOWLEDGE):
    case REQUEST(ACTION_TYPES.FETCH_DOCUMENT_LIST_SURAT_KELUAR):
    case REQUEST(ACTION_TYPES.FETCH_DOCUMENT_LIST_SURAT_MASUK):
    case REQUEST(ACTION_TYPES.FETCH_DOCUMENT_LIST_SURAT_REJECTED):
    case REQUEST(ACTION_TYPES.FETCH_DEPARTMENT):
      return {
        ...state,
        errorMessage: null,
        updateSuccess: false,
        loadingdepartement: true,
      };
    case REQUEST(ACTION_TYPES.FETCH_USER_LIST):
      return {
        ...state,
        errorMessage: null,
        updateSuccess: false,
        loading: true,
      };
    case REQUEST(ACTION_TYPES.FETCH_APPROVAL_LIST):
      return {
        ...state,
        errorMessage: null,
        updateSuccess: false,
        loading: true,
      };
    case REQUEST(ACTION_TYPES.FETCH_ACKNOWLEDGE_LIST):
      return {
        ...state,
        errorMessage: null,
        updateSuccess: false,
        loading: true,
      };
    case REQUEST(ACTION_TYPES.FETCH_DOCUMENT_CODE_LIST):
      return {
        ...state,
        errorMessage: null,
        updateSuccess: false,
        loading: true,
      };
    
    case REQUEST(ACTION_TYPES.FETCH_DOCUMENT):
    case REQUEST(ACTION_TYPES.OPENPREVIEW):
    case REQUEST(ACTION_TYPES.VIEW_DOCUMENT_SIGN):
      return {
        ...state,
        errorMessage: null,
        updateSuccess: false,
        loading: true,
      };
    case SUCCESS(ACTION_TYPES.VIEW_DOCUMENT_SIGN):
    console.log('ini response', action.payload)
      const filepdf = new Blob([action.payload.data], { type: "application/pdf" });
      return {
        ...state,
        fileBlobPreview: filepdf,
      }
    case REQUEST(ACTION_TYPES.APPROVE_DOCUMENT):
      return {
        ...state,
        loadingapprove: true
    };
    case SUCCESS(ACTION_TYPES.APPROVE_DOCUMENT): 
      return {
          ...state,
          loadingapprove: false
      }
    case FAILURE(ACTION_TYPES.APPROVE_DOCUMENT):
      return {
        ...state,
        loadingapprove: false
      }
    case REQUEST(ACTION_TYPES.REJECT_DOCUMENT): 
      return {
        ...state,
        loadingreject: true
      }
    case SUCCESS(ACTION_TYPES.REJECT_DOCUMENT):
      return {
        ...state,
        loadingreject: false
      }
    case FAILURE(ACTION_TYPES.REJECT_DOCUMENT): 
      return {
        ...state,
        loadingreject: false
      }
    case REQUEST(ACTION_TYPES.FETCH_DOCUMENT_DETAIL):
      return {
          ...state,
          loadingdetail: true
    };
    case REQUEST(ACTION_TYPES.CREATE_DOCUMENT):
    case REQUEST(ACTION_TYPES.UPDATE_DOCUMENT):
    case REQUEST(ACTION_TYPES.DELETE_DOCUMENT):
      return {
        ...state,
        errorMessage: null,
        updateSuccess: false,
        updating: true,
      };
    case FAILURE(ACTION_TYPES.SEARCH_DOCUMENTS):
    case FAILURE(ACTION_TYPES.FETCH_DOCUMENT_LIST):
    case FAILURE(ACTION_TYPES.FETCH_DOCUMENT):
    case FAILURE(ACTION_TYPES.CREATE_DOCUMENT):
    case FAILURE(ACTION_TYPES.UPDATE_DOCUMENT):
    case FAILURE(ACTION_TYPES.DELETE_DOCUMENT):
      return {
        ...state,
        loading: false,
        updating: false,
        updateSuccess: false,
        errorMessage: action.payload,
      };
    case FAILURE(ACTION_TYPES.FETCH_DOCUMENT_DETAIL):
        return {
            ...state,
            loadingdetail: false
    };
    case SUCCESS(ACTION_TYPES.FETCH_DOCUMENT_DETAIL):
      console.log('data response', action.payload)
      return {
          ...state,
          detaildocument: action.payload.data.data,
          acknowledgelist: action.payload.data.data.acknowledge,
          loadingdetail: false
  };
  case SUCCESS(ACTION_TYPES.OPENPREVIEW):
    // console.log('daanya', action.payload.data)
    // window.open("data:application/pdf," + encodeURI(action.payload.data));
    // console.log(atob(action.payload.data))
    const file = new Blob([action.payload.data], { type: "application/pdf" });
    console.log('ini file tanda tangan', file)
        //Build a URL from the file
        const fileURL = URL.createObjectURL(file);
        //Open the URL on new Window
        console.log('iniurlpdf',fileURL)
         const pdfWindow = window.open();
         pdfWindow.location.href = fileURL;     
    return {
      ...state,
      filePDFsign: file
    }
    case SUCCESS(ACTION_TYPES.SEARCH_DOCUMENTS):
    case SUCCESS(ACTION_TYPES.FETCH_DOCUMENT_LIST):
      console.log(action.payload.data)
      return {
        ...state,
        loading: false,
        entities: action.payload.data,
      };
  case SUCCESS(ACTION_TYPES.FETCH_DOCUMENT_LIST_APPROVAL):
    return {
      ...state,
      loading: false,
      documentDashboardList: action.payload.data.data.content,
      totalApproval: action.payload.data.data.totalElements,
      totalPageApproval: action.payload.data.data.totalPages
    };
    case SUCCESS(ACTION_TYPES.FETCH_DOCUMENT_LIST_ACKNOWLEDGE):
      return {
        ...state,
        loading: false,
        documentListAcknowledge: action.payload.data.data.content,
        totalAcknowledge: action.payload.data.data.totalElements,
        totalPageAcknowledge: action.payload.data.data.totalPages
      };
      case SUCCESS(ACTION_TYPES.FETCH_DOCUMENT_LIST_SURAT_MASUK):
        return {
          ...state,
          loading: false,
          documentListSuratMasuk: action.payload.data.data.content,
          totalSuratMasuk: action.payload.data.data.totalElements,
          totalPageKotakMasuk: action.payload.data.data.totalPages
      };
      case SUCCESS(ACTION_TYPES.FETCH_DOCUMENT_LIST_SURAT_KELUAR):
        return {
          ...state,
          loading: false,
          documentListSuratKeluar: action.payload.data.data.content,
          totalSuratKeluar: action.payload.data.data.totalElements,
          totalPageKotakKeluar: action.payload.data.data.totalPages
      };
      case SUCCESS(ACTION_TYPES.FETCH_DOCUMENT_LIST_SURAT_REJECTED):
        return {
          ...state,
          loading: false,
          documentListSuratRejected: action.payload.data.data.content,
          totalSuratRejected: action.payload.data.data.totalElements,
          totalPageKotakRejected: action.payload.data.data.totalPages
      };
    case SUCCESS(ACTION_TYPES.FETCH_DOCUMENT):
      return {
        ...state,
        loading: false,
        entity: action.payload.data,
      };
    case SUCCESS(ACTION_TYPES.FETCH_DEPARTMENT):
      // console.log(action.payload.data)
        return {
          ...state,
          loadingdepartement: false,
          deptList: action.payload.data,
          // console.log(deptList)
        };
    case SUCCESS(ACTION_TYPES.FETCH_USER_LIST):
      // console.log(action.payload.data)
        return {
          ...state,
          loading: false,
          userList: action.payload.data,
          // console.log(deptList)
        };
    case SUCCESS(ACTION_TYPES.FETCH_APPROVAL_LIST):
      console.log('data approval',action.payload.data)
        return {
          ...state,
          loading: false,
          approvalList: action.payload.data,
          // console.log(deptList)
        };
    case SUCCESS(ACTION_TYPES.FETCH_ACKNOWLEDGE_LIST):
      // console.log(action.payload.data)
        return {
          ...state,
          loading: false,
          acknowledgeList: action.payload.data,
          // console.log(deptList)
        };
    case SUCCESS(ACTION_TYPES.FETCH_DOCUMENT_CODE_LIST):
      // console.log(action.payload.data)
        return {
          ...state,
          loading: false,
          documentListCode: action.payload.data,
          // console.log(deptList)
        };
    case SUCCESS(ACTION_TYPES.CREATE_DOCUMENT):
    case SUCCESS(ACTION_TYPES.UPDATE_DOCUMENT):
      return {
        ...state,
        updating: false,
        updateSuccess: true,
        entity: action.payload.data,
      };
    case SUCCESS(ACTION_TYPES.DELETE_DOCUMENT):
      return {
        ...state,
        updating: false,
        updateSuccess: true,
        entity: {},
      };
    case ACTION_TYPES.RESET:
      return {
        ...initialState,
      };
    default:
      return state;
  }
};

// const apiUrl = 'services/documentservice/api/documents';
const apiUrl = 'services/documentservice/api/documents';
// const apiUrl = 'services/documentservice/api/documents/send_document_new?id='
// const deptlisturl = 'services/uaadocservice/api/department/list'
const deptlisturl = 'services/uaadocservice/api/users/get-active-group'
// const userlisturl = 'services/uaadocservice/api/users/get-list-user-not-in-employee-no'
const userlisturl = 'services/uaadocservice/api/users/get-approver'
const approvalListUrl = 'services/uaadocservice/api/users/get-approver'
const acknowledgeListUrl = 'services/uaadocservice/api/users/get-acknowledge'

const documentListCodeUrl = 'services/uaadocservice/api/group/get-list-document-code'




const apiSearchUrl = 'api/_search/documents';

const streamToBase64 = (stream) => {
  const concat = require('concat-stream')
  const { Base64Encode } = require('base64-stream')

  return new Promise((resolve, reject) => {
    const base64 = new Base64Encode()

    const cbConcat = (base64) => {
      resolve(base64)
    }

    stream
      .pipe(base64)
      .pipe(concat(cbConcat))
      .on('error', (error) => {
        reject(error)
      })
  })
}

// http://10.195.0.111:8060/services/documentservice/api/documents/send_document_new?id=0
// Actions

export const getSearchEntities: ICrudSearchAction<IDocument> = (query, page, size, sort) => ({
  type: ACTION_TYPES.SEARCH_DOCUMENTS,
  payload: axios.get<IDocument>(`${apiSearchUrl}?query=${query}`),
});

export const getEntities: ICrudGetAllAction<IDocument> = (page, size, sort) => ({
  type: ACTION_TYPES.FETCH_DOCUMENT_LIST,
  payload: axios.get<IDocument>(`${apiUrl}?cacheBuster=${new Date().getTime()}`),
});

// export const getEntitiesByEmpId = (employeeId, page, size, sort) => ({
//   type: ACTION_TYPES.FETCH_DOCUMENT_LIST,
//   payload: axios.get<IDocument>(`${apiUrl}/dashboard_approval?employeeNo=${employeeId}`),
// });
export const getEntitiesByEmpId = (employeeId, page, size, sort) => ({
  type: ACTION_TYPES.FETCH_DOCUMENT_LIST,
   payload: axios.get<IDocument>(`${apiUrl}/draft?employeeNo=${employeeId}`),
});


export const getDocumentApproval = (employeeId, page, size, sort) => ({
  type: ACTION_TYPES.FETCH_DOCUMENT_LIST_APPROVAL,
  payload: axios.get<IDocument>(`${apiUrl}/dashboard_approval?employeeNo=${employeeId}&page=${page}&size=${size}`),
});

export const getDocumentAcknowledge = (employeeId, page, size, sort) => ({
  type: ACTION_TYPES.FETCH_DOCUMENT_LIST_ACKNOWLEDGE,
  payload: axios.get<IDocument>(`${apiUrl}/dashboard_acknowledge?employeeNo=${employeeId}&page=${page}&size=${size}`),
});

export const getDocumentSuratMasuk = (employeeId, page, size, sort) => ({
  type: ACTION_TYPES.FETCH_DOCUMENT_LIST_SURAT_MASUK,
  payload: axios.get<IDocument>(`${apiUrl}/dashboard_kotak_masuk?employeeNo=${employeeId}&page=${page}&size=${size}`),
});

export const getDocumentSuratKeluar = (employeeId, page, size, sort) => ({
  type: ACTION_TYPES.FETCH_DOCUMENT_LIST_SURAT_KELUAR,
  payload: axios.get<IDocument>(`${apiUrl}/dashboard_kotak_keluar?employeeNo=${employeeId}&page=${page}&size=${size}`),
});

export const getDocumentSuratRejected = (employeeId, page, size, sort) => ({
  type: ACTION_TYPES.FETCH_DOCUMENT_LIST_SURAT_REJECTED,
  payload: axios.get<IDocument>(`${apiUrl}/dashboard_rejected?employeeNo=${employeeId}&page=${page}&size=${size}`),
});


export const getDocumentDetail: ICrudGetAction<IDocument> = (id:string) => ({
  type: ACTION_TYPES.FETCH_DOCUMENT_DETAIL,
  payload: axios.get<IDocument>(`/services/documentservice/api/documents/${id}`),
});

export const postDocument = (data, id) => {
  const formData = new FormData();
  formData.append("file", data);
  return {
    type: ACTION_TYPES.FETCH_DOCUMENT_DETAIL,
    payload: axios.post<any>(`/services/documentservice/api/file-metadata/upload-to-gcp?fileId=${id}`, formData),
  }
}

export const viewPDF = (id) => {
  return {
    type: ACTION_TYPES.VIEW_DOCUMENT_SIGN,
    payload: axios.get<any>(`/services/documentservice/api/dms/get_document_from_gcp?fileId=${id}`, {responseType:'arraybuffer'}),
  }
}

export const approveDocument = (id, notes, file) => {
  return {
    type: ACTION_TYPES.APPROVE_DOCUMENT,
    payload: axios.get<any>(`/services/documentservice/api/dms/confirm-approve?id=${id}&notes=${notes}`),
  }
}

export const rejectDocument = (id, notes, file) => {
  const formData = new FormData();
  formData.append("pdfFile", file);
  return {
    type: ACTION_TYPES.REJECT_DOCUMENT,
    payload: axios.put<any>(`/services/documentservice/api/dms/reject?id=${id}&notes=${notes}`, formData),
  }
}

export const signDocument = (data, sign, id) => {
  const formData = new FormData();
  formData.append("pdfFile", data);
  formData.append("signatureImage", sign);
  return {
    type: ACTION_TYPES.OPENPREVIEW,
    payload: axios.post<any>(`/services/documentservice/api/dms/sign-file`, formData, {responseType: 'blob'}),
  }
}

export const getDeptList = (sort) => ({
  type: ACTION_TYPES.FETCH_DEPARTMENT,
  payload: axios.get<any>(`${deptlisturl}`),
});


export const getUserList = (sort) => ({
  
  type: ACTION_TYPES.FETCH_USER_LIST,
  payload: axios.get<IDocument>(`${userlisturl}`),
});

export const getApprovalList = (sort) => ({
  
  type: ACTION_TYPES.FETCH_APPROVAL_LIST,
  payload: axios.get<IDocument>(`${approvalListUrl}`),
});

export const getAcknowledgeList = (sort) => ({
  
  type: ACTION_TYPES.FETCH_ACKNOWLEDGE_LIST,
  payload: axios.get<IDocument>(`${acknowledgeListUrl}`),
});

export const getListDocumentCode = (sort) => ({
  
  type: ACTION_TYPES.FETCH_DOCUMENT_CODE_LIST,
  payload: axios.get<IDocument>(`${documentListCodeUrl}`),
});








export const getEntity: ICrudGetAction<IDocument> = id => {
  const requestUrl = `${apiUrl}/${id}`;
  return {
    type: ACTION_TYPES.FETCH_DOCUMENT,
    payload: axios.get<IDocument>(requestUrl),
  };
};

export const createEntity: ICrudPutAction<IDocument> = entity => async dispatch => {
  const result = await dispatch({
    type: ACTION_TYPES.CREATE_DOCUMENT,
    payload: axios.post(apiUrl, cleanEntity(entity)),
  });
  dispatch(getEntities());
  return result;
};

export const sendNewDocument: ICrudPutAction<IDocument> = entity => async dispatch => {
  const requestUrl = `/services/documentservice/api/documents/send_document_new?id=`;
  const result = await dispatch({
    type: ACTION_TYPES.CREATE_DOCUMENT,
    payload: axios.put(requestUrl, cleanEntity(entity)),
  });
  dispatch(getEntities());
  return result;
};

export const saveDocumentToDraft: ICrudPutAction<IDocument> = entity => async dispatch => {
  // const requestUrl = `/services/documentservice/api/documents/save_as_draft`;
  const requestUrl = `/services/documentservice/api/documents/save_as_draft?id=${entity.id}`;
  const result = await dispatch({
    type: ACTION_TYPES.CREATE_DOCUMENT,
    payload: axios.put(requestUrl, cleanEntity(entity)),
  });
  dispatch(getEntities());
  return result;
};


export const updateEntity: ICrudPutAction<IDocument> = entity => async dispatch => {
  const result = await dispatch({
    type: ACTION_TYPES.UPDATE_DOCUMENT,
    payload: axios.put(apiUrl, cleanEntity(entity)),
  });
  return result;
};

export const deleteEntity: ICrudDeleteAction<IDocument> = id => async dispatch => {
  const requestUrl = `${apiUrl}/${id}`;
  const result = await dispatch({
    type: ACTION_TYPES.DELETE_DOCUMENT,
    payload: axios.delete(requestUrl),
  });
  dispatch(getEntities());
  return result;
};

export const reset = () => ({
  type: ACTION_TYPES.RESET,
});
